import { Component, OnInit } from '@angular/core'
import { MessageService } from 'primeng/api'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { MemberServiceService } from 'src/app/services/member/member-service.service'
import { SportService } from 'src/app/services/sport/sport.service'
import { environment } from '../../../../environments/environment.prod'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-sport-list',
  templateUrl: './sport-list.component.html',
  styleUrls: ['./sport-list.component.scss']
})
export class SportListComponent implements OnInit {
  sports: any
  fileUrl = `${environment.filesUrl}`
  members: any[] = []
  sportsData: any = []
  sportsIds: any = []
  constructor(private commomService: CommonServices, private messageService: MessageService,
    private sportService: SportService, private memberInfo: MemberServiceService,     private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.memberInfo.getMemberInfo().subscribe((members: any) => {
      console.log('members', members)

      this.sportService.getSportList().subscribe((data: any) => {
        console.log('data', data)
        this.sports = data
        this.members = members.sports
        for (const member of this.members) {
          this.sportsIds.push(member.id)
        }
        for (const sport of this.sports) {
          if (this.sportsIds.includes(sport.id)) {
            this.sportsData.push({
              id: sport.id,
              name: sport.name,
              picture: sport.picture,
              isFavorite: true
            })
          }
          else {
            this.sportsData.push({
              id: sport.id,
              name: sport.name,
              picture: sport.picture,
              isFavorite: false
            })
          }
        }
      },
        err => {
          this.commomService.handleResponse(err)
        })
    },
      err => {
        this.commomService.handleResponse(err)
      })
  }

  onChange(id: number) {
    for (const sport of this.sportsData) {
      if (sport.id === id) {
        sport.isFavorite = !sport.isFavorite
        if (sport.isFavorite) {
          this.sportsIds.push(id)
        }
        else {
          const index = this.sportsIds.indexOf(id)
          this.sportsIds.splice(index, 1)
        }
      }
    }
  }

  updateSports() {
    this.sportService.postSportList(this.sportsIds).subscribe((data: any) => {
      Swal.fire(this.translate.instant('TANKYOU'),
      this.translate.instant('Votre liste de sports a été modifiée.'), 'success')
    },
      err => {
        this.commomService.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'),
        this.translate.instant('profile.PROFILENOTUPDATED'), 'error')
      })
  }
}


