import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private uri = `${environment.apiUrl}`
  private commonUrl = `/public-lists/`
  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getAllSessions(type: any, data: any): Observable<any> {

    switch (type) {
      case 'all':
        return this.http.get(this.uri + this.commonUrl + `fitness-session`, this.httpOptions)
      case 'per-sport':
        return this.http.get(this.uri + this.commonUrl + `fitness-sessions-per-sport/` + data, this.httpOptions)
      case 'per-fitness-class':
        return this.http.get(this.uri + this.commonUrl + `fitness-sessions-per-fitness-class/` + data, this.httpOptions)
      case 'per-date':
        return this.http.get(this.uri + this.commonUrl + `fitness-sessions-per-date/` + data, this.httpOptions)
      case 'per-periode':
        return this.http.get(this.uri + this.commonUrl + `fitness-sessions-per-period/` + data, this.httpOptions)
      default:
        return this.http.get(this.uri + this.commonUrl + `fitness-session`, this.httpOptions)

    }
  }


}
