import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  private uri = `${environment.apiUrl}`
  private controller = `/public-coach-actions/start-streaming`
  constructor(private http: HttpClient) { }

  getStreamingList() {
    return this.http.get(this.uri + this.controller)
  }

  postStreamingList(id: number): Observable<any> {
    return this.http.post(this.uri + this.controller + '/' + id, httpOptions)
  }

}
