import { Component, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MenuItem, Message, ConfirmationService } from 'primeng/api'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { MemberServiceService } from 'src/app/services/member/member-service.service'
import { UserService } from 'src/app/services/user/user.service'
import { User } from '../../../interfaces/user.interface'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import { CoachService } from 'src/app/services/coach/coach.service'
import { environment } from '../../../../environments/environment.prod'
import { DataStorageService } from '../../../services/data-storage.service'
import { Coach } from '../../../interfaces/coach.interface'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { SessionService } from '../../../services/sessions-member/session.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ReservationService } from '../../../services/reservation/reservation.service'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from 'src/app/services/auth/auth.service'
import { Router } from '@angular/router'
@Component({
  selector: 'app-account-info-view',
  templateUrl: './account-info-view.component.html',
  styleUrls: ['./account-info-view.component.scss'],
  providers: [ConfirmationService],
})

export class AccountInfoViewComponent implements OnInit {
  public getFitnessSessionFav: any = []
  public getVideoFav: any = []
  public items!: MenuItem[]
  public itemsMember!: MenuItem[]
  public itemsCoach!: MenuItem[]
  public option = []
  public activeTab: any
  public activeItem: any
  public totalRatings = 3
  public isLoggedIn = false
  public userClaims: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
    job: '',
    zipCode: '',
    address: '',
  }
  public userBmi: any = {
    height: 0,
    weight: 0,


 
  };
  public result :any 
  public meaning: any
  public message: Message[] = []
  public accountUpdated = false
  public selectedCountry: any
  public selectedCity: any
  public selectedLanguage: any
  public selectedGender: any = null
  public countries: any[] = []
  public cities: any[] = []
  public languages: any[] = []
  public member: any[] = []
  public fileToUpload!: File
  public images: any = null
  public appUrl = environment.filesUrl
  public genders = ['Male', 'Female']
  public imageMessage: Message[] = []
  public filename: any
  public userRole: any = null
  public coach: any
  public profile: any = null
  public coachFav: any
  public fileUrl = `${environment.filesUrl}`
  public p = 0
  public coachs: Coach[] = []
  public coachsId: any = []
  public filterCoach: any
  public fitnessClass: any = []
  public fitnessData: any
  public fitnessSessionReservation: any = []
  public entity = 'Coach'
  public info = this.translate.instant('profile.navBar.INFO')
  public planning = this.translate.instant('profile.navBar.PLANING')
  public cours = this.translate.instant('profile.navBar.COURS')
  public sport = this.translate.instant('profile.navBar.SPORT')
  public objectif = this.translate.instant('profile.navBar.OBJETCIF')
  public coachMenu = this.translate.instant('profile.navBar.COACH')
  public reservation = this.translate.instant('profile.navBar.RESERVATION')

  constructor(
    private confirmationService: ConfirmationService,
    private profileService: UserService,
    private commomService: CommonServices,
    private memberInfo: MemberServiceService,
    private tokenStorage: TokenStorageService,
    private coachService: CoachService,
    private dataStorage: DataStorageService,
    private favoriteService: FavoritesService,
    private fitnessSession: SessionService,
    private reservationServcie: ReservationService,
    private translate: TranslateService,
    public authService: AuthService,
    public router: Router
  ) {
   
  
  }

  calculateBMI() {
    console.log('Please enter your ',this.userBmi.height , this.userBmi.weight  )

    if (this.userBmi.height && this.userBmi.weight ) {
      console.log('Please enter your ',this.userBmi.height , this.userBmi.weight  )
      const num = +this.userBmi.weight / (Math.round(+this.userBmi.height * +this.userBmi.height) * 0.0001);
      this.result = num.toFixed(2);
      console.log('Please enter your input',num )

      if (+this.userBmi.height.value === 0 || +this.userBmi.weight.value === 0) {
        this.userBmi.innerText = "Please enter your input";
      } else if (num < 18.4) {
        this.meaning = "Insuffisance pondérale";
      } else if (num >= 18.5 && num <= 24.9) {
        this.meaning = "Normal";
      } else if (num >= 25 && num <= 30) {
        this.meaning = "Surpoids";
      } else if (num >= 30 && num <= 35)   {
        this.meaning = "Obèse";
      } else if (num >= 35 && num <= 40)    {
        this.meaning = "Obèse1";
      } else if (num > 40)   {
        this.meaning = "Obèse2";
      }
    }
  }
  ngOnInit(): void {
    

    console.log("this.reservation", this.reservation)
    // this.getvideoFav();
    this.userRole = this.tokenStorage.getUser().roles[1] 

    if (this.userRole === 'ROLE_MEMBER') {
      this.getFavoriteFitnessSession()
      this.getReservation()
    
      this.getCoachFav();
      this.fitnessData = JSON.parse(this.dataStorage.getData('fitness-class-data'))
      const FitnessClassLocalStorage = JSON.parse(this.dataStorage.getData('fitness-class-data'))
      this.favoriteService.getFavoritesByEntity('FitnessClass').subscribe(
        (data: any) => {
          const fitnessClass = data
          for (const i in fitnessClass) {
            this.fitnessClass.push(FitnessClassLocalStorage.filter((x: any) => x.id === fitnessClass[i].foreignKey))
          }
        })
      this.memberInfo.getMemberInfo().subscribe(
        (data: any) => {
          this.profile = data
        },
        (err) => {
          this.commomService.handleResponse(err)
        }
      )
    } else if (this.userRole === 'ROLE_COACH') {
      this.coachService.getCoachInfo().subscribe(
        (data: any) => {
          console.log('data', data)
          this.profile = data
          if (data.photo != null) {
            this.images = this.appUrl + data.photo
          }
        },
        (err) => {
          this.commomService.handleResponse(err)
        }
      )
    }
    this.profileService.getUserClaims().subscribe(
      (data: any) => {
        this.userClaims = data
        this.selectedCountry = data.countryId
        this.selectedCity = data.cityId
        this.selectedLanguage = data.languageId

        if (data.picture != null) {
          this.images = this.appUrl + data.picture
        } else {
          this.images =
            '../../../../assets/images/e-fit-default-image/user-default.png'
        }
        if (this.userClaims.gender === 'Male') {
          this.selectedGender = this.genders[1]
        } else {
          this.selectedGender = this.genders[0]
        }
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
    this.profileService.getCountry().subscribe(
      (data: any) => {

        this.countries = data
        console.log('countries',this.countries)
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
    this.profileService.getCity().subscribe(
      (data: any) => {
        this.cities = data
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
    this.profileService.getLanguage().subscribe(
      (data: any) => {
        this.languages = data
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
    this.itemsMember = [
      { label: 'Mes informations', icon: 'pi pi-user', id: 'ROLE_MEMBER' },
      // { label: this.planning, icon: 'pi pi-fw pi-calendar', id: 'ROLE_MEMBER' },
      { label: 'Mes cours', icon: 'pi pi-fw pi-calendar', id: 'ROLE_MEMBER' },
      { label: 'Mes sports', icon: 'pi pi-users', id: 'ROLE_MEMBER' },
      { label: 'Mes objectifs', icon: 'pi pi-fw pi-calendar', id: 'ROLE_MEMBER' },
      { label: 'BMI Calculator', icon: 'pi pi-fw pi-calendar', id: 'ROLE_MEMBER' },

      // { label: this.coachMenu, icon: 'pi pi-star', id: 'ROLE_MEMBER' },
      // { label: this.reservation, icon: 'pi pi-calendar', id: 'ROLE_MEMBER' },
    ]
    this.itemsCoach = [
      { label: 'Mes informations', icon: 'pi pi-user', id: 'ROLE_COACH' },
     // { label: 'Mon planning', icon: 'pi pi-fw pi-calendar', id: 'ROLE_COACH' },
      //  { label: 'Les avis', icon: 'pi pi-fw pi-comment', id: 'ROLE_COACH' },
    ]
    if (this.userRole === 'ROLE_MEMBER') {
      this.activeItem = this.itemsMember[0]
      this.activeTab = this.activeItem['label']
    } else {
      this.activeItem = this.itemsCoach[0]
      this.activeTab = this.activeItem['label']
    }
  }
  itemClik(item:any) {
    if(item.id === 'ROLE_MEMBER') {
      this.activeItem = item
      this.activeTab = item['label']
      console.log("item", this.activeTab,this.activeItem );

    } else {
      this.activeItem = item
      this.activeTab = item['label']
      console.log("item", this.activeTab,this.activeItem );
    }

  }
  getCoachFav() {
    const coachLocalStorage = JSON.parse(this.dataStorage.getData('coach-data'))
    this.coachFav = coachLocalStorage.filter((x: any) => x.favorite === true)
  }
  sendData(fitnessData: any) {
    const data = [fitnessData]
    console.log('data', data)
    return data
  }
  getActiveItem(activeItem: MenuItem): string {
    this.activeTab = activeItem['activeItem'].label
    return this.activeTab
  }
  getReservation() {
    this.reservationServcie.getReservations().subscribe(
      (data: any) => {
        const reservations = data
        for (const i in reservations) {
          this.fitnessSessionReservation.push(
            reservations[i].fitnessSession
          )
        }

      })
  }

  onUpdatePerInfo() {
    delete this.userClaims.cityName
    delete this.userClaims.countryCode
    delete this.userClaims.countryName
    delete this.userClaims.languageCode
    delete this.userClaims.languageLocale
    delete this.userClaims.languageName
    delete this.userClaims.username
    delete this.userClaims.id
    delete this.userClaims.companyName
    this.userClaims.country = this.userClaims.countryId
    delete this.userClaims.countryId
    this.userClaims.city = this.userClaims.cityId
    delete this.userClaims.cityId
    this.userClaims.language = this.userClaims.languageId
    delete this.userClaims.languageId
    this.userClaims.company = this.userClaims.companyId
    delete this.userClaims.companyId
    this.profileService.patchProfile(this.userClaims).subscribe(async (data) => {
      this.profileService.getUserClaims().subscribe(
        (data: any) => {
          this.userClaims = data
          this.accountUpdated = true
          Swal.fire(this.translate.instant('TANKYOU'),
            this.translate.instant('profile.PROFILEUPDATED'), 'success')

        },
        (err) => {
          this.commomService.handleResponse(err)
          Swal.fire(this.translate.instant('SORRY'),
            this.translate.instant('profile.PROFILENOTUPDATED'), 'error')
        }
      )
    })
  }

  getFavoriteFitnessSession() {
    this.favoriteService.getFavoritesByEntity('FitnessSession').subscribe((favData: any) => {
      const favFitnessSession = favData
      this.fitnessSession.getAllSessions('all', null).subscribe((data: any) => {
        const allFitnessSession = data
        for (const i in favFitnessSession) {
          if (allFitnessSession.filter((x: any) => x.id === favFitnessSession[i].foreignKey).length === 0) {
          }
          else {
            this.getFitnessSessionFav.push(allFitnessSession.filter((x: any) => x.id === favFitnessSession[i].foreignKey)[0])
          }
        }
      },
        (err) => {
          this.commomService.handleResponse(err)
        }
      )

    })
  }

  onUpdatePerFitnessClub(fitnessForm: NgForm) {
    this.onUpdatePerInfo()
    if (this.userRole === 'ROLE_MEMBER') {
      this.memberInfo.putMemberInfo(fitnessForm.value).subscribe(async (data) => {
        this.memberInfo.getMemberInfo().subscribe(
          (data: any) => {
            this.profile = data
            this.accountUpdated = true
            Swal.fire(this.translate.instant('TANKYOU'),
              this.translate.instant('profile.PROFILEUPDATED'), 'success')
          },
          (err) => {
            this.commomService.handleResponse(err)
            Swal.fire(this.translate.instant('SORRY'),
              this.translate.instant('profile.PROFILENOTUPDATED'), 'error')
          }
        )
      })
    } else {
      this.coachService.putCoachInfo(fitnessForm.value).subscribe(async (data) => {

        this.coachService.getCoachInfo().subscribe(
          (data: any) => {
            this.profile = data
            this.accountUpdated = true
            Swal.fire(this.translate.instant('TANKYOU'),
              this.translate.instant('profile.PROFILEUPDATED'), 'success')

          },
          (err) => {
            this.commomService.handleResponse(err)
            Swal.fire(this.translate.instant('SORRY'),
              this.translate.instant('profile.PROFILENOTUPDATED'), 'error')
          }
        )
      })
    }
  }

  getCours(cours: any) {
    const data = [cours, null, true]
    return data
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0)!;
    const reader = new FileReader()
    reader.onload = (event: any) => {
      this.images = event.target.result
    }
    reader.readAsDataURL(this.fileToUpload)
    this.confirmationService.confirm({
      message: this.translate.instant('profile.SAVEPICTURE'),
      header: this.translate.instant('profile.UPDATEPICTURE'),
      icon: 'pi pi-image',
      accept: () => {
        this.profileService.postFile(this.fileToUpload).subscribe(
          (data: any) => {
            this.filename = '/uploads/users/' + data.filename
            this.userClaims.picture = this.filename
            this.profileService
              .patchPicture(this.userClaims)
              .subscribe(async (data) => {
                this.profileService.getUserClaims().subscribe((data: any) => {
                  this.userClaims = data
                })
              })
          },
          (err) => {
            this.commomService.handleResponse(err)
          }
        )
      },
      reject: () => {
        this.imageMessage = [
          {
            severity: 'info',
            summary: 'Rejected',
            detail: this.translate.instant('profile.PICTURENOTUPDATED'),
          },
        ]
      },
    })
  }


  logout() {
   
    window.localStorage.clear()
    window.location.href = 'https://payment.eklectic.tn/API/oauth/user/authorize?client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&response_type=code&scope=FITNESSBYTT&redirect_uri=https://www.clubfitness.tn/#/home';

  
  }
}
