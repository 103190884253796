import { Component, OnInit } from '@angular/core'
import { HostListener } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { timer } from 'rxjs'
import { DataStorageService } from '../../../services/data-storage.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import * as  moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'moment/locale/fr'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { SessionService } from '../../../services/sessions-member/session.service'

declare function playM3u8(url: any): any

@Component({
  selector: 'app-live-channel-view',
  templateUrl: './live-channel-view.component.html',
  styleUrls: ['./live-channel-view.component.scss']
})

export class LiveChannelViewComponent implements OnInit {

  public secondsToDday: any
  public minutesToDday: any
  public hoursToDday: any
  public daysToDday: any
  public sessions: any = []
  public picture = null
  public loading = false
  public type = 'all'
  public data = null
  public responsiveOptions: any
  public displayDateTimeFormat = ''
  public displayDateFormat = ''
  public displayTimeFormat = ''
  public counter = 0
  public tick = 1000
  public countDown: any
  public currentSession: any
  public currentTime: any
  public isLive = false
  public filterdClass: any
  public classDurationName: any
  public coachdata: any;
  public addTimer: any;
  fileUrl = `${environment.filesUrl}`
  screenHeight = 0
  screenWidth = 0
  entity = "FitnessSession"
  nbSlide = 0
  liveStart = false
  interval: any;
  public stopTimer = false
  public currentTimestamp: any
  slides = [
    { img: 'https://www.panattasport.com/resources/home/homefitness-home.jpg' },
    { img: 'https://www.panattasport.com/resources/home/homefitness-home.jpg' },
    { img: 'https://www.panattasport.com/resources/home/homefitness-home.jpg' },
    { img: 'https://www.panattasport.com/resources/home/homefitness-home.jpg' },
    { img: 'https://www.panattasport.com/resources/home/homefitness-home.jpg' }
  ]
  slideConfig = { slidesToShow: this.getScreenSize(), slidesToScroll: this.getScreenSize() }

  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1
  }

  constructor(
    public sessionsService: SessionService,
    public translate: TranslateService,
    public dataStorage: DataStorageService,
    public router: Router,
    public commomService: CommonServices,

  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ]
    this.getScreenSize()
  }

  ngOnInit(): void {
    this.displayDateTimeFormat = this.translate.instant('formats.DATETIME')
    this.displayDateFormat = this.translate.instant('formats.DATE')
    this.displayTimeFormat = this.translate.instant('formats.TIME')
    this.addTimer = 3600000
    this.getSessions()
    setInterval(() => {
      if (this.classDurationName !== undefined) {
        var minutesToAdd = this.classDurationName * 60000;
        var calculeDif = moment(Math.floor(new Date().getTime())).diff((moment(Date.parse(this.sessions[0].startTime))), 'milliseconds')
        if (minutesToAdd > calculeDif) {
          this.currentSession.fromNow = moment(this.currentSession.startTime).fromNow()
        }
        else {
          this.getSessions()
        }
      }
    }, 60000);

  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): number {
    if (window.innerWidth < 1000) {
      return 1
    }
    else {
      return 4
    }
  }

  coachProfileNav(name: any, id: any) {
    this.router.navigate(['/coach/' + name.replaceAll(' ', '-').toLowerCase() + '&' + id])
  }

  goLive(isLive: any, session: any) {

    if (isLive && session.publicStreamUrl !== undefined) {
      const coachData = JSON.parse(this.dataStorage.getData('coach-data'))
      this.coachdata = coachData.filter((x: any) => x.id === session.coachId)
      var video = document.getElementById('video');
      this.getSessions()
      playM3u8(session.publicStreamUrl)
      if (playM3u8(session.publicStreamUrl) !== false) {
        this.liveStart = true
      }
    }
    else if (session.publicStreamUrl === undefined) {
      Swal.fire(this.translate.instant('STREAMCOACH'))
    }
    else {
      this.liveStart = false
      this.isLive = false
      Swal.fire(this.translate.instant('STREAM') + moment(session.startTime).fromNow().replace("dans", ""))
    }

  }

  toTimestamp(strDate: any) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  getSessions() {
    const tomorrow = new Date().getTime() + 172800000
    this.currentTimestamp = Math.floor(new Date().getTime() - this.addTimer)
    this.currentTime = this.currentTimestamp
    this.loading = true
    this.sessionsService.getAllSessions('all', null).subscribe((data: any) => {
        const futurSessions = data.filter(
          (session: any) => Date.parse(session.startTime) > this.currentTimestamp
        )
        this.sessions = futurSessions

        if (this.sessions !== []) {
          this.sessions.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
          for (let i = 0; i < futurSessions.length; i++) {
            futurSessions[i].picture =
              environment.filesUrl +
              futurSessions[i].picture
            if (futurSessions[i].content !== undefined) {
              futurSessions[i].content = futurSessions[
                i
              ].content.replaceAll(
                /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
                ''
              )
            }
            this.sessions[i] = futurSessions[i]
            this.sessions[i]['fromNow'] = moment(futurSessions[i].startTime).fromNow()
            this.sessions[i]['start'] = futurSessions[i].startTime
            this.sessions[i]['time'] = moment(futurSessions[i].startTime).format('LT')
            this.sessions[i]['backgroundColor'] = futurSessions[i].color
            this.sessions[i]['title'] = futurSessions[i].name
            this.sessions[i]['borderColor'] = futurSessions[i].color
            this.sessions[i]['className'] = 'orange'
            this.sessions[i]['imageUrl'] = futurSessions[i].picture
            this.sessions[i]['description'] = futurSessions[i].content

            const filtredSessions = this.sessions.filter(
              (session: any) => (
                (new Date(session.startTime).getTime()) < tomorrow
              )
            )
            this.sessions = filtredSessions
          }
          this.currentSession = this.sessions[0]
          if (this.currentSession !== undefined) {
            this.calcDateDiff()
            this.countDown = timer(0, this.tick).subscribe(() => this.calcDateDiff())

            this.loading = false
            const FitnessClassLocalStorage = JSON.parse(
              this.dataStorage.getData('fitness-class-data')
            )
            this.filterdClass = FitnessClassLocalStorage.filter(
              (x: any) => x.id === this.currentSession.fitnessClassId
            )
            this.classDurationName = this.filterdClass[0].classDurationName.split('\"')[0]

            if (Date.parse(this.currentSession.startTime) >= Math.floor(new Date().getTime() - this.classDurationName * 60000)) {
              this.addTimer = this.classDurationName * 60000
              return
            }
            else {
              this.addTimer = this.classDurationName * 60000
              this.getSessions()
            }
          }
        }

      },
      (err) => {
        this.commomService.handleResponse(err)
      })
  }

  addMinutes(date: Date, minutes: any) {
    return new Date(date.getTime() + minutes * 60000)
  }

  calcDateDiff() {
    //add duration session to date
    if (this.sessions[0] !== undefined) {
      var minutesToAdd = this.classDurationName;
      var currentDate = new Date(moment(this.sessions[0].startTime).format());
      var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

      const startDay = new Date(moment(this.sessions[0].startTime).format())
      const dDay = startDay.valueOf()
      // console.log('futureDate', futureDate, this.classDurationName, new Date(new Date(futureDate)).getTime(), dDay)

      // const endDate = (new Date(this.addHoursToDate(new Date(this.sessions[0].startTime), 1)).getTime())
      const endDate = new Date(new Date(futureDate)).getTime()
      const milliSecondsInASecond = 1000
      const hoursInADay = 24
      const minutesInAnHour = 60
      const secondsInAMinute = 60
      const timeDifference = dDay - Date.now()
      this.daysToDday = Math.floor(
        timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
      )
      this.hoursToDday = Math.floor(
        (timeDifference /
          (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
      )
      this.minutesToDday = Math.floor(
        (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
      )
      this.secondsToDday =
        Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute

      if (this.daysToDday === 0 && this.hoursToDday === 0 && this.minutesToDday === 0 && this.secondsToDday === 0) {
        this.isLive = true
      } else if (dDay < Date.now() && endDate > Date.now()) {
        this.isLive = true
      }
      else if (Date.now() > endDate) {
        this.isLive = false
      }
      else if (dDay > Date.now()) {
        this.isLive = false
      } else if (dDay === Date.now()) {
        this.isLive = true
      }
    }
  }

  addHoursToDate(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours))
  }
}
