import { Component, OnInit, Input } from '@angular/core'
import { DataStorageService } from 'src/app/services/data-storage.service'
import { FavoritesService } from '../../../../services/favorites/favorites.service'
import { CommonServices } from '../../../../services/common/common-service.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { LikesService } from '../../../../services/likes/likes.service'
import { ModalRatingComponent } from '../../modal-rating/modal-rating.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-public-reaction',
  templateUrl: './public-reaction.component.html',
  styleUrls: ['./public-reaction.component.scss']
})
export class PublicReactionComponent implements OnInit {
  @Input() likeAction: any
  @Input() disLikeAction: any
  @Input() id: any
  @Input() entity: any
  @Input() name: any
  favData: any
  selectedLike = false
  selectedDisLike = false
  selectedComment = false
  filterData: any

  constructor(
    public dataStorage: DataStorageService,
    public favoriteService: FavoritesService,
    public commonServices: CommonServices,
    public likeService: LikesService,
    public modalService: NgbModal,
    private translate: TranslateService

  ) { }

  ngOnInit(): void {
    // initialize the values with @input action to cam toggle it on the future
    this.selectedLike = this.likeAction
    this.selectedDisLike = this.disLikeAction
  }

  toggleSelectedLike() {
    this.selectedLike = !this.selectedLike
    if (this.selectedLike) {
      this.addLike()
    }
    else {
      this.removeLike()
    }
  }

  toggleSelectedUnlike() {
    this.selectedDisLike = !this.selectedDisLike
    if (this.selectedDisLike) {
      // not yet prepared on the backend
      // this.addUnLike();
    }
    else {
      // not yet prepared on the backend
      // this.removeUnLike();
    }
  }

  toggleSelectedComment() {
    console.log('name', this.name, this.entity)
    // show modal component for rating and comment secion
    const modalRef = this.modalService.open(ModalRatingComponent)
    modalRef.componentInstance.data = {
      entity: this.entity,
      id: this.id,
      name: this.name
    }
  }

  addLike() {
    this.likeService.setLikebyentity(this.entity, this.id).subscribe(
      (data: any) => {
        Swal.fire(this.translate.instant('TANKYOU'), this.translate.instant('LIKECREATED'), 'success')
      },
      (err) => {
        this.commonServices.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'), this.translate.instant('LIKENOTCREATED'), 'error')

      }
    )
  }

  removeLike() {
    this.likeService.unsetLikebyentity(this.entity, this.id).subscribe(
      (data: any) => {
        Swal.fire(this.translate.instant('TANKYOU'), this.translate.instant('LIKEDELETED'), 'success')
      },
      (err) => {
        this.commonServices.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'), this.translate.instant('LIKENOTDELETED'), 'error')
      }
    )
  }
}
