<!-- <div class="support-grid"></div>

<div class="band">
  <div class="item-1" *ngFor="let post of posts | slice: 0:1">
    <a (click)="showPost(post)" class="card">
      <div class="thumb" style="background-image: url('{{ post.picture }}');"></div>
      <article>
        <h1>{{ post.title }}</h1>
        <span>{{ 'READMORE' | translate }}</span>
      </article>
    </a>
  </div>
  <div class="item-2" *ngFor="let post of posts | slice: 1:3">
    <a (click)="showPost(post)" class="card">
      <div class="thumb" style="background-image: url('{{ post.picture }}');"></div>
      <article>
        <h1>{{ post.title }}</h1>
        <p>{{ post.content | slice:0:250}}</p>
        <span>{{ 'READMORE' | translate }}</span>
      </article>
    </a>
  </div>

  <div class="item-4" *ngFor="let post of posts | slice: 3">
    <a (click)="showPost(post)" class="card">
      <div class="thumb" style="background-image: url('{{ post.picture }}');"></div>
      <article>
        <h1>{{ post.title }}</h1>
        <p>{{ post.content | slice:0:150}}</p>
        <span>{{ 'READMORE' | translate }}</span>
      </article>
    </a>
  </div>

</div> -->

<main>
  <!--? Hero Start -->
  <div class="slider-area2">
    <div class="slider-height2 d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="hero-cap hero-cap2 pt-70">
              <h2>Blog</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Hero End -->
  <!--? Blog Area Start-->
  <section class="blog_area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mb-5 mb-lg-0">
          <div class="blog_left_sidebar">
            <article class="blog_item" *ngFor="let post of posts |  paginate: { itemsPerPage:4 , currentPage: p}" (click)="showPost(post)">
              <div class="blog_item_img" >
                <img class="card-img rounded-0" src="{{ post.picture }}" alt="">
                <a (click)="showPost(post)" class="blog_item_date">
                  <h3>{{ post.createdAt | date: displayDateFormat }}</h3>
                  <p>{{ post.createdAt | date: displayTimeFormat }}</p>
                </a>
              </div>
              <div class="blog_details">
                <a class="d-inline-block" href="blog_details.html">
                  <h2 class="blog-head" style="color: #efeeee;">{{ post.title }}</h2>
                </a>
                <p style="color: #ffffff;">{{ post.content | slice:0:150}}</p>
                <ul class="blog-info-link">
                  <li><a ><i class="fa fa-user"></i>{{ post.postType.name }}
                  </a></li>
                </ul>
              </div>
            </article>
          
            <nav class="blog-pagination justify-content-center d-flex">

              <ul class="pagination">
                <pagination-controls (pageChange)="p = $event"previousLabel="<<" nextLabel=">>" style="font-size: large;" ></pagination-controls>
             
              
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="blog_right_sidebar">
           
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title" style="color: #2d2d2d;">Categories</h4>
              <ul class="list cat-list">
             
                <li>
                  <a  class="d-flex">
                    <p>Healthy</p>
                  </a>
                </li>
              </ul>
            </aside>
            <aside class="single_sidebar_widget popular_post_widget">
              <h3 class="widget_title" style="color: #2d2d2d;">Recent Post</h3>
              <div class="media post_item" *ngFor="let post of posts | slice: 0:4">
                <img src="{{ post.picture }}" alt="post" style="
                width: 100px;
                height: 100px;">
                <div class="media-body">
                  <a (click)="showPost(post)">
                    <h3 style="color: #2d2d2d;">{{ post.title }}</h3>
                  </a>
                  <p>{{ post.createdAt | date: displayDateTimeFormat }}</p>
                </div>
              </div>
            
            </aside>
            <aside class="single_sidebar_widget tag_cloud_widget">
              <h4 class="widget_title" style="color: #2d2d2d;">Tag Clouds</h4>
              <ul class="list">
                <li *ngFor="let fitness of fitnessData">
                  <a routerLink="/cours/{{fitness.url}}">{{ fitness.name }}</a>
                </li>
               
              </ul>
            </aside>

          
          
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog Area End -->

</main>