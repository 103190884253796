import { Component, OnInit, OnDestroy } from '@angular/core'
import { SessionsService } from '../../../services/sessions-coach/sessions.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { environment } from '../../../../environments/environment'
import { StreamingService } from '../../../services/streaming/streaming.service'
import { DataStorageService } from '../../../services/data-storage.service'
import { Router } from '@angular/router'
import { timer } from 'rxjs'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ClipboardService } from 'ngx-clipboard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import * as moment from 'moment'
import 'moment/locale/fr'
declare var $: any

@Component({
  selector: 'app-current-sessions-view',
  templateUrl: './current-sessions-view.component.html',
  styleUrls: ['./current-sessions-view.component.scss'],
  providers: [TranslatePipe],
})
export class CurrentSessionsViewComponent implements OnInit {
  public secondsToDday: any
  public minutesToDday: any
  public hoursToDday: any
  public daysToDday: any
  public responsiveOptions: any
  public picture = null
  public currentSession: any = null
  public sessions: any = []
  public displayDateTimeFormat = ''
  public displayDateFormat = ''
  public displayTimeFormat = ''
  public disabled = false
  public displayMaximizable = false
  public displayMaximizableWeb = false
  public displayMaximizableIos = false
  public displayModal = false
  public liveStart = false
  public filterdClass: any
  public classDurationName: any
  public addTimer: any
  public isLive = false
  baseHef = document.getElementsByTagName('base')[0].href;
  public helpsForMobile = [
    { picture: '../../../../assets/images/images-larix/lienlarix.PNG' },
    // { picture: '../../../../assets/images/images-larix/larix1.JPEG' },
    { picture: '../../../../assets/images/images-larix/larix2.JPEG' },
    { picture: '../../../../assets/images/images-larix/larix3.JPEG' },
    { picture: '../../../../assets/images/images-larix/larix4.JPEG' },
    { picture: '../../../../assets/images/images-larix/7.jpg' },
  ]
  public helpsForMobiles = [
    { picture: '../../../../assets/images/images-larix/qrCode.PNG' },
    { picture: '../../../../assets/images/images-larix/qrCode1.JPEG' },
    { picture: '../../../../assets/images/images-larix/qrCode2.JPEG' },
    { picture: '../../../../assets/images/images-larix/qrCode3.JPEG' },
    { picture: '../../../../assets/images/images-larix/7.jpg' },
  ]
  public helpsForWeb = [
    { picture: '../../../../assets/images/images-obs/1.PNG' },
    { picture: '../../../../assets/images/images-obs/2.PNG' },
    { picture: '../../../../assets/images/images-obs/3.PNG' },
    { picture: '../../../../assets/images/images-obs/4.PNG' },

  ]
  public counter = 0
  public tick = 1000
  public countDown: any
  public isWeb = false
  public isAndroid = false
  public isIos = false
  public interval: any
  public value: any
  public streamingData: any
  public showModal = false
  constructor(
    public sessionsService: SessionsService,
    public commomService: CommonServices,
    public translate: TranslateService,
    public streamingService: StreamingService,
    public dataStorage: DataStorageService,
    public router: Router,
    public clipboardApi: ClipboardService,
    public modalService: NgbModal,
  ) {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ]
  }

  ngOnInit(): void {
    this.displayDateTimeFormat = this.translate.instant('formats.DATETIME')
    this.displayDateFormat = this.translate.instant('formats.DATE')
    this.displayTimeFormat = this.translate.instant('formats.TIME')
    this.addTimer = 3600000
    this.getAllSessions()
    this.interval = setInterval(() => {
      if (this.classDurationName !== undefined) {
        var minutesToAdd = this.classDurationName * 60000;
        var calculeDif = moment(Math.floor(new Date().getTime())).diff((moment(Date.parse(this.currentSession.startTime))), 'milliseconds')
        if (minutesToAdd > calculeDif) {
          this.currentSession.fromNow = moment(this.currentSession.startTime).fromNow()
        }
        else {
          this.getAllSessions()
        }
      }
    }, 60000);
  }

  getAllSessions() {

    this.sessionsService.getAll().subscribe(
      (data: any) => {
        this.sessions = data
        const currentTimestamp = Math.floor(new Date().getTime() - this.addTimer)

        const futurSessions = data.filter(
          (session: any) =>
            Date.parse(session.startTime) > currentTimestamp

        )
        this.sessions = futurSessions
        this.sessions.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
        for (let i = 0; i < this.sessions.length; i++) {
          this.sessions[i].fitnessClass.picture =
            environment.filesUrl +
            '/' +
            this.sessions[i].fitnessClass.picture
          moment.locale('fr')

          this.sessions[i]['fromNow'] = moment(this.sessions[i].startTime).fromNow()
          // this.sessions[i]['fromNow'] = Math.floor((((Math.floor(new Date().getTime())) - Date.parse(this.sessions[i].startTime)) / 1000 / 60) << 0)
          // console.log('test', ((Math.floor(new Date().getTime())) - Date.parse(this.sessions[i].startTime)))
          if (this.sessions[i].fitnessClass.content !== undefined) {
            this.sessions[i].fitnessClass.content = this.sessions[i].fitnessClass.content.replaceAll(
              /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '')
          }
          this.sessions[i] = this.sessions[i]
        }

        this.currentSession = this.sessions[0]
        console.log("this.currentSession", this.currentSession)
        if (this.currentSession !== undefined) {
          this.calcDateDiff()
          this.countDown = timer(0, this.tick).subscribe(() => this.calcDateDiff())
          this.classDurationName = this.currentSession.fitnessClass.classDuration.name.split('\"')[0]
          if (Date.parse(this.currentSession.startTime) >= Math.floor(new Date().getTime() - this.classDurationName * 60000)) {
            // check if start timer with the class duration is still progress 
            this.isLive = true
          }
          else {
            this.addTimer = this.classDurationName * 60000
            this.getAllSessions()
          }
          if (this.secondsToDday === 0) {
            this.disabled = true
          }


        }
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
  }

  millisToMinutesAndSeconds(millis: any) {
    var minutes = Math.floor(millis / 60000);
    return minutes;
  }

  calcDateDiff() {
    if (this.sessions[0] !== undefined) {
      const endDay: Date = new Date(moment(this.sessions[0].startTime).format())
      const dDay = endDay.valueOf()

      const milliSecondsInASecond = 1000
      const hoursInADay = 24
      const minutesInAnHour = 60
      const secondsInAMinute = 60

      const timeDifference = dDay - Date.now()

      this.daysToDday = Math.floor(
        timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
      )
      this.hoursToDday = Math.floor(
        (timeDifference /
          (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
      )
      this.minutesToDday = Math.floor(
        (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
      )
      this.secondsToDday =
        Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute
    }
  }


  copyText(content: any) {
    this.clipboardApi.copyFromContent(content)
  }

  startStreaming() {

    const currentTimestamp = Math.floor(new Date().getTime() - 3600000)
    console.log('Date.parse(this.sessions.startTime)', Date.parse(this.currentSession.startTime), currentTimestamp)
    if (Date.parse(this.currentSession.startTime) > currentTimestamp) {
      this.streamingService.postStreamingList(this.currentSession.id).subscribe(
        (data: any) => {
          console.log('streamingData', data)
          this.streamingData = data
          // this.displayModal = true
          this.showModal = true

          this.value = 'larix://set/v1?conn[][url]=rtmp%3A%2F%2Fmedia.clubfitness.tn%2Flive%2F'
            + data.fitnessSession.pushStreamName + '&conn[][name]=' + data.fitnessSession.pushStreamName + '&conn[][user]=' + data.user.username +
            '&conn[][pass]=' + data.user.password + '&conn[][target]=rtmp'

          // Swal.fire({
          //   // title: '<strong>' + this.translate.instant('STARTSTREAM') + '</strong>',
          //   // icon: 'info',
          //   width: 800,
          //   heightAuto: true,
          //   html:
          //     '<div class="row"><div class="col-4 text-left">' + this.translate.instant('USERNAME') + '' + ' </div> <div class="col-6 text-left h5"> ' + data.user.username + '</div><div class="col-2 text-left h5"> <i class="fa fa-copy"></i></div></div>' + '<br>' +
          //     '<div class="row"><div class="col-4 text-left">' + this.translate.instant('login.PASSWORD') + '' + ' </div> <div class="col-6  text-left h5"> ' + data.user.password + '</div><div class="col-2 text-left h5"> <i class="fa fa-copy"></i></div></div>' + '<br>' +
          //     '<div class="row"><div class="col-4 text-left">' + this.translate.instant('STREAMNAME') + '' + ' </div> <div class="col-6  text-left h5"> ' + data.fitnessSession.pushStreamName + '</div><div class="col-2 text-left h5"> <i class="fa fa-copy"></i></div></div>' + '<br>' +
          //     '<div class="row"><div class="col-4 text-left">' + this.translate.instant('PUBLICSTREAMURL') + '' + ' </div> <div class="col-6  text-left h5"> ' + data.fitnessSession.publicStreamUrl + '</div><div class="col-2 text-left h5"> <i class="fa fa-copy"></i></div></div>' +
          //     '<ngx-qrcode [elementType]="elementType" [value]=' + + 'larix://set/v1?conn[][url]=rtmp%3A%2F%2Fmedia.clubfitness.tn%2Flive%2F'
          //     + data.fitnessSession.pushStreamName + '&conn[][name]=' + data.fitnessSession.pushStreamName + '&conn[][user]=' + data.user.username +
          //     +' cssClass="aclass" errorCorrectionLevel="L"></ngx-qrcode>' + '<br>',
          //   showCloseButton: true,
          //   showCancelButton: true,
          //   focusConfirm: false,
          //   confirmButtonText:
          //     ' <i (click)=' + this.clipboardApi.copyFromContent(
          //       '' + 'larix://set/v1?conn[][url]=rtmp%3A%2F%2Fmedia.clubfitness.tn%2Flive%2F'
          //       + data.fitnessSession.pushStreamName + '&conn[][name]=' + data.fitnessSession.pushStreamName + '&conn[][user]=' + data.user.username +
          //       '&conn[][pass]=' + data.user.password + '&conn[][target]=rtmp' + '') + '</i>' + this.translate.instant('COPYLINK') + '</button>',

          //   // '<i class="fa fa-thumbs-up"></i> Great!',
          //   confirmButtonAriaLabel: 'Thumbs up, great!'
          // })
        },
        (err) => {
          this.commomService.handleResponse(err)
        }
      )
    }
    else {
      Swal.fire(this.translate.instant('STARTCLASSLIVE') + this.currentSession.fromNow + '<br>' + this.translate.instant('STARTSTREAM'))
    }

  }

  help() {
    this.displayMaximizable = true;
    this.isWeb = true;
    this.isAndroid = false;
    this.isIos = false;
  }

  helpForAndroid() {
    this.displayMaximizable = true;
    this.isWeb = false;
    this.isAndroid = true;
    this.isIos = false;

  }

  helpForIos() {
    this.displayMaximizable = true;
    this.isWeb = false;
    this.isAndroid = false;
    this.isIos = true;
  }

  showSession(cours: any) {
    console.log('cours', cours.id, cours)
    this.router.navigate(['/session/' + cours.id])
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  hide() {
    this.showModal = false
    $('#successModal').modal('hide')
  }
}
