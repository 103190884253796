<header style="background-color: white;">
  <div class="header-navbar">
    <div class="container">
      <div class="row">
        <a routerLink="/auth/member/login">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-dark">
              <div class="center">
                <div class="login-brand mb-4"></div>
              </div>
            </nav>
          </div>
          <div class="margin-header">
            <div class="float-left">
              <i class="fas fa-arrow-left"></i>
            </div><br>
          </div>
        </a>
      </div>
    </div>
  </div>
</header>
<section class="welcome" style="background-color: white;">


  <div class="paragraph-position">
    <h2 class="text-center">Conditions générales d’utilisation du service « Club Fitness »</h2>
  
    <p> Date : les présentes conditions générales de vente et d’utilisation sont applicables à partir de la date de lancement du service Club Fitness.</p>
  
    <ol start="2">
      <li><strong>Article 1. Présentation générale du service « Club Fitness »</strong></li>
    </ol>
    <p>Club Fitness est un service dédié à la diffusion de contenus de fitness en proposant des séances d'entraînement avec des entraîneurs spécialisés !</p>
    <p>Le service Club Fitness est ouvert à toute personne physique majeure. La société éditrice du service Club Fitness se réserve le droit de procéder à toute vérification utile et de demander l’autorisation écrite d’un représentant légal du mineur. A défaut de justifier d’une telle autorisation, les commandes du mineur ne pourront pas être honorées.</p>
    <p>Pour accéder au service Club Fitness, il est nécessaire de disposer de :</p>
    <lu>
      <li>Un accès internet.</li>
      <li>Un abonnement à Tunisie Télécom.</li>
  
      <li>Un compte sur le Service Club Fitness.</li>
    </lu>
  

    <p>L’accès au service Club Fitness est payant. Les frais liés à la connexion au service Club Fitness sont facturés par l’opérateur téléphonique et restent à la charge exclusive des Utilisateurs.</p>
    
    
    <ol start="3">
      <li><strong>
        Article 2. Objet des conditions générales d’utilisation du Service Club Fitness</strong></li>
    </ol>
    <p>Le présent document a pour objet de déterminer les conditions générales de vente et d’utilisation du Service Club Fitness. Il définit les droits et obligations de Tunisie Telecom et des Utilisateurs dans le cadre de l’utilisation du Service Club Fitness.</p>
    <p>Les CGU sont proposées en langue française exclusivement.
      Les CGU sont portées à la connaissance des Utilisateurs à tout moment au niveau des applications mobiles en cliquant sur le bouton « J’ai lu et j’accepte les conditions d’utilisation » disponible dans l’Application.</p>

    <ol start="4">
      <li><strong>
        Article 3. Acceptation et modification des conditions générales d’utilisation du Service Club Fitness</strong></li>
    </ol>
    <p>L’utilisation du Service Club Fitness suppose l’acceptation pleine et entière par l’Utilisateur des CGU.
      Tunisie Telecom se réserve la possibilité de modifier à tout moment le Service Club Fitness ainsi que le contenu des CGU.
      Les clients accédant au Service, dans le cadre d’un Abonnement ou d’un Forfait, se verront notifier de telles modifications dans un délai minimum de trente jours précédant leur entrée en vigueur, à moins qu’elles ne soient dictées par une règle impérative ou décision judiciaire auquel cas leur entrée en vigueur sera immédiate. Tout client qui refuserait les nouvelles conditions devra dès lors résilier son accès au Service dans les conditions prévues aux articles des présentes.
      Le client n’ayant pas procédé à la résiliation de son abonnement ou du forfait après l’expiration du délai précité sera irrévocablement réputé avoir accepté les modifications en question.</p>
 
    <ol start="5">
      <li><strong>Article 4. Inscription et Accès au Service Club Fitness</strong></li>
      <li><strong>4.1 Inscription au Service Club Fitness et création d’un Compte Utilisateur</strong></li>
    </ol>
    <p>L’accès et l’utilisation du Service Club Fitness suppose que l’Utilisateur ait préalablement lu et accepté les présentes Conditions d’utilisation sans réserve. Lorsque l’utilisateur souscrit un abonnement, Club Fitness crée un compte utilisateur automatiquement en utilisant son numéro de téléphone. L’Utilisateur, au cours du processus d’inscription et de création de son compte Utilisateur, recevra un SMS afin d’activer son compte utilisateur et il reconnaît autoriser l’utilisation de ces données par Tunisie Telecom pour les besoins de la création et de l’administration du compte utilisateur.</p>
    <p>n tout état de cause et quel que soit le moyen par lequel l’utilisateur s’inscrit au Service Club Fitness, l’utilisateur garantit que les informations qu’il fournit, directement ou non, sont exactes, à jour et complètes. L’utilisateur s’engage également à mettre à jour les informations communiquées sur son compte utilisateur, dès que l’une d’entre elles est modifiée et ce, afin de maintenir l’exactitude des informations.</p>
    
    <p>Dans tous les cas d’inscription, le compte utilisateur est strictement personnel et ne pourra pas être utilisé par un tiers directement ou indirectement.</p>
    <p>L’utilisateur reconnaît que Tunisie Telecom se réserve le droit de suspendre ou de lui retirer la possibilité d’accéder au service, dès lors que l’utilisateur ne respecterait pas les présentes modalités d’inscription et particulièrement s’il fournit des données fausses ou fantaisistes, inexactes, incomplètes ou non mises à jour et si le compte est utilisé par un tiers. En aucun cas, la responsabilité de Tunisie Telecom ne pourra être engagée en cas d’erreur, d’omission ou d’imprécision dans les informations communiquées par l’utilisateur sous sa responsabilité.</p>
    <p>L’identifiant et le mot de passe de l’utilisateur lui permettant d’accéder au compte utilisateur et à ses données sont strictement confidentiels et personnels.</p>
    
    <p>L’utilisateur est seul responsable de l’utilisation qui pourrait être faite de son compte utilisateur. Il s’engage à informer immédiatement Tunisie Telecom de toute utilisation qu’il n’aurait pas autorisée et de toute atteinte à la confidentialité ou à la sécurité de ses données d’identification, en ligne à l’adresse suivante :
      https://www.tunisietelecom.tn/Fr/Particulier/Assistance.</p>
    <ol start="6">
      <li>
        <strong>4.2 Accès au service Club Fitness</strong>
      </li>
    </ol>
    <p>Pour accéder au Service CLUB FITNESS depuis son Terminal, l’Utilisateur doit :</p>
    <p>Se rendre à l’adresse suivante https://ClubFitness.tn s’il souhaite accéder au service CLUB FITNESS depuis un Navigateur Web.</p>
    <p>Le Service Club Fitness est accessible sans interruption, sous réserve des aléas liés à la disponibilité du réseau Internet. L’Utilisateur est en outre informé que le Service Club Fitness pourra être temporairement indisponible pour maintenance ou réparation. L’accès au compte Club Fitness est limité à un seul écran.
      Tunisie Telecom se réserve néanmoins le droit, en cas de nécessité impérieuse, sans préavis ni indemnité, d'en fermer temporairement l'accès et ne sera pas responsable des dommages de toute nature pouvant survenir de ce fait. Les Crédits d'achats qui n’auraient pu être utilisés de ce fait seront alors remboursés à leur titulaire.</p>
    <ol start="7">
      <li><strong>Article 5. Propriété Intellectuelle</strong>
      </li>
    </ol>
    <p>Club Fitness vous accorde une licence limitée, non exclusive, non transférable et non sous-licenciable pour (1) accéder au contenu Club Fitness et le consulter, et (2) accéder et utiliser le logiciel et les applications mobiles fournies par le service Club Fitness.</p>
    <p>Cette licence est fournie uniquement pour votre utilisation personnelle non-commerciale et la jouissance du service Club Fitness, tel que permis dans ces conditions.</p>
    <p>Vous ne pouvez pas utiliser, copier, adapter, modifier, préparer des œuvres dérivées, distribuer, licencier, vendre, transférer, afficher publiquement, transmettre, diffuser ou exploiter autrement le Contenu de Club Fitness, le Service Club Fitness ou toute partie de celui-ci, sauf si cela est expressément autorisé dans les présentes Conditions.
      Aucune licence ou droit ne vous est accordé par implication ou autrement en vertu de tout droit de propriété intellectuelle détenu ou contrôlé par Club Fitness ou ses concédants, à l'exception des licences et des droits expressément accordés dans ces Conditions.</p>
    
    <p>À l'exception de votre contenu, le contenu de Club Fitness, le Service Club Fitness et sa technologie sous-jacente sont protégés par des droits d'auteur, marque, brevets, droits de propriété intellectuelle et autres droits de la loi internationale.</p>
    <p>Vous ne pouvez pas faire les choses suivantes durant votre accès ou utilisation du Service Club Fitness:</p>
    <p>1. Utiliser, afficher, copier ou réutiliser le Service Club Fitness, le nom Club Fitness, toute autre marque Club Fitness, logo ou autre information ou la mise en page et la conception de toute page ou formulaire contenu sur une page, sans le consentement écrit des auteurs titulaires des droits.</p>
    <p>2. Trafiquer ou modifier les zones non publiques du service Club Fitness, les systèmes informatiques de Club Fitness ou les systèmes de livraison techniques des fournisseurs de Club Fitness.</p>
    <p>
  3. Tester la vulnérabilité de tout système Club Fitness ou enfreindre toute mesure de sécurité ou d’authentification.
    </p>
    <p>4. Contourner toute mesure technique mise en œuvre par Club Fitness ou l'un des fournisseurs de Club Fitness ou tout autre tiers (y compris un autre utilisateur) pour protéger le service Club Fitness ou le contenu Club Fitness.</p>
    <p>5. Accéder au Service Club Fitness ou au contenu Club Fitness grâce à l'utilisation de tout mécanisme autre que le Service Club Fitness ou l'API Club Fitness.</p>
    <p>6. Modifier, décompiler, désassembler, désosser, altérer ou tenter de dériver le code source de tout logiciel fourni par Club Fitness à vous ou à toute autre partie du service Club Fitness.</p>
    
    <ol start="8">
      <li><strong>Article 6. Responsabilité</strong></li>
    </ol>
    <p>Tunisie Telecom met tout en œuvre pour offrir aux utilisateurs du Service Club Fitness des outils disponibles.
      Tunisie Telecom ne pourra en aucun cas être tenue responsable des vitesses d'accès au Service Club Fitness, d'accès aux données du Service Club Fitness, de téléchargement voire de suspension ou d'impossibilité temporaire d'accès.
      L’utilisateur est seul responsable de ses équipements techniques et de leur compatibilité avec le Service Club Fitness. Il doit prévenir les éventuelles intrusions ou virus par les moyens habituels (Firewall, anti-virus, ...) qui ne sont pas de la responsabilité de Tunisie Telecom.
      La responsabilité de Tunisie Telecom ne saurait être engagée dans le cas où le contenu, ainsi que d'éventuels liens hypertextes pointant sur d'autres sites, contreviendraient aux droits de tiers et plus généralement aux dispositions légales ou règlementaires en vigueur.</p>
   <p>Sauf preuve contraire, les données enregistrées par le Service Club Fitness constituent la preuve de l'ensemble des transactions passées entre le Service Club Fitness et son utilisateur.</p>
      <ol start="9">
      <li><strong>Article 7. Modification et cessation du Service Club Fitness</strong></li>
    </ol>
    <p>OTunisie Telecom se réserve le droit de modifier les conditions d’utilisation du Service Club Fitness et/ou des offres. Toute modification concernant les modalités de fourniture du Service et/ou les Offres, en particulier les tarifs, sera portée à la connaissance des utilisateurs avec un préavis de 60 jours.
      La cessation éventuelle du Service Club Fitness sera annoncée aux utilisateurs avec un préavis de trente jours (30 jours).
      Toute modification et/ou cessation du Service Club Fitness ou des offres dans les conditions susvisées ne saurait engager la responsabilité de Tunisie Telecom à quelque titre que ce soit.</p>
    <ol start="10">
      <li><strong>Article 8. Loi applicable</strong></li>
    </ol>
    <p>Les présentes CGU sont soumises au droit Tunisien.</p>
  
  </div>
</section>
