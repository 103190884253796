<app-loader *ngIf="loading === true"></app-loader>


<div class="back-arrow" (click)="goBack()">⬅️</div> 


<div style="text-align: center;">
 <!--  <div class="back-arrow" (click)="goBack()"><i class="fa fa-arrow-left" aria-hidden="true"></i>-
   </div>-->
   <div *ngFor="let video of videosFiltretedbyId  | slice:0:show; let i=index">
    <video class="video" controls poster="{{video?.picture}}" controlsList="nodownload"
    disableDownload>
             <source src="{{video?.file}}" >
           </video>
   </div>


 
   <h1 class="mt-3 text-center" style="color: white;">
       <!-- <span
           *ngIf="video?.price != undefined &&video?.price.name ==='Free'">({{'videos.FREEVIDEOS' | translate
           }})</span> -->
           <span>{{video?.name}}</span> | <span>
           <!-- <h2> {{ 'videos.DURATION' | translate }} {{ video?.duration }} Min</h2> -->
           <h2>« {{video?.description}} »</h2>
       </span></h1>

    <div style="padding: 40px;">
      <p-carousel [value]="videosFiltreted" [numVisible]="4" [numScroll]="4" [circular]="false"
      [responsiveOptions]="responsiveOptions">
  
      <ng-template let-video pTemplate="item">
        <div *ngIf="video.picture !== undefined " class="main-container_tab" (click)="showVideo(video)"
          style="background-image: url('{{ video.picture }}'); ">
      
          <div class="product-item-content">
  
            <p class="main-container_tab_author" *ngIf="video.name !== undefined ">{{ video.name }}</p>
          </div>
        </div>
      </ng-template>
    </p-carousel>

    </div>
</div>
