import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Message } from 'primeng/api'
import { Credentials } from 'src/app/interfaces/credentials.interface'
import { AuthService } from 'src/app/services/auth/auth.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import * as moment from 'moment'
import { DataStorageService } from '../../../../services/data-storage.service';
import { Inscription } from 'src/app/interfaces/inscription.interface'
import { LanguageService } from 'src/app/services/languages/language.service'
import { TranslateService } from '@ngx-translate/core'
import { SportService } from 'src/app/services/sport/sport.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register-view.component.scss']
})
export class RegisterViewComponent implements OnInit {
  public credentials: Credentials = {
    username: '',
  }
  public inscription: Inscription = {
    username: '',
    password: '',
  }
  public message: Message[] = []
  public isLoggedIn = false
  public isLoginFailed = false
  public errorMessage = ''

  constructor(
    private readonly commomService: CommonServices,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private dataStorage: DataStorageService,
    private languageService: LanguageService,
    private translate: TranslateService, 
    private sportService: SportService,
  ) { }

  ngOnInit(): void {
    // check if user is have token into local storage to enter into home page view

  }


  getLanguage(): void {
    this.languageService.getLanguage().subscribe(
      (languages: any) => {
        var lang = languages.results;
        var languagaList = []
        for (let i in lang) {
          languagaList.push({
            name: lang[i].name,
            code: lang[i].code.toLowerCase()
          })
        }
        console.log("lang data", lang)
        localStorage.setItem("language-data", JSON.stringify(languagaList));

      },
      (err) => {

      }
    );
  }
  // when press into login button to call auth service
  login() {
    let userdata = {
      username: this.inscription.username,
      password: this.inscription.password.toString(),
    }
    // test the input is not empty else nothing happen
    if (this.inscription.password === '') {
      this.isLoginFailed = true
      this.message = [
        {
          severity: 'warn',
          detail: this.translate.instant('login.REQUIRED'),
        },
      ]
      return
    }
    if (this.dataStorage.getData('language')?.length === 0) {
      localStorage.setItem('language', 'fr')
    }
    // else call authService to verify the credentials of user
    this.authService.login(userdata).subscribe((data: any) => {
      // credentials are successfully checked
      console.log('maroua', data)
      this.isLoginFailed = false
      this.isLoggedIn = true
      this.tokenStorage.saveToken(data.token)
      this.tokenStorage.saveUser(data.data)
      this.tokenStorage.saveCredentials(this.inscription)
      localStorage.setItem('hasLoggedIn', 'true')
      localStorage.setItem('auth-token', data.token)
      // this.getLanguage();
      // this.getAllSports();
      window.location.reload()

      this.router.navigate(['/home/coach'])



    },
      (err) => {
        // in the case of error on the server response login is failed
        console.log("🚀 ~ file: login-view.component.ts:128 ~ LoginViewComponent ~ login ~ err:", err)
        Swal.fire(this.translate.instant('OPPS'),
        this.translate.instant('error.CHECKDATA'), 'error')
        this.errorMessage = err.error.message
        this.isLoggedIn = false
        this.isLoginFailed = true
        this.message = [
          { severity: 'error', detail: this.commomService.handleResponse(err) },
        ]
      }
    )
  }


  getAllSports(): void {
    this.sportService.getSportList().subscribe((data: any) => {
      this.dataStorage.saveData(data, 'sport-data')
      // window.location.reload();
      // this.router.navigateByUrl('/home/coach')
    },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
  }



}
