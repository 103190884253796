import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { SportService } from '../../../services/sport/sport.service'
import { environment } from '../../../../environments/environment.prod'
import { MessageService } from 'primeng/api'
import { Sport } from '../../../interfaces/sport.interface'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { CommonServices } from '../../../services/common/common-service.service'
import { SharedServiceService } from '../../../services/shared-service.service'
import { RatesService } from 'src/app/services/rates/rates.service'
import { StaticDataService } from '../../../services/static-data/static-data.service'
import { DataStorageService } from '../../../services/data-storage.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LikesService } from '../../../services/likes/likes.service'
import { ActivatedRoute } from '@angular/router'
import { VideosService } from '../../../services/videos/videos.service'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import { TranslateService } from '@ngx-translate/core'
import Swal from 'sweetalert2/dist/sweetalert2.js'

interface Video {
  link: string;
  image: string;
}

interface ScheduleItem {
  day: number;
  videos: Video[];
}

@Component({
  selector: 'app-sport-view',
  templateUrl: './sport-view.component.html',
  styleUrls: ['./sport-view.component.scss'],
  providers: [MessageService]
})

export class SportViewComponent implements OnInit {
  fileUrl = `${environment.filesUrl}`
  entity = 'FitnessClass'
  storageName = 'fitness-class-data'
  contentLoaded = false
  sportName: any
  banner = false
  fitnessClassData: any = []
  filterSport: any
  fitnessData: any = []
  selectedLike = false
  likeData: any = []
  sports: Sport = {
    id: 0,
    name: '',
    picture: '',
    color: '',
    content: '',
    icon: '',
  };
  public state: any = null


  isslide = false
  imageSource1: string = '';
  imageSource2: string = '';

  imageSource3: string = '';

  description1: string = '';
  description2: string = '';
  description3: string = '';
  public videos: any = []
  public videosFiltreted: any = []
  public loading = false
  public video: any
  public displayMaximizable = false
  public show = 8
  public favorite: any = 0

  playlist = [
    // {
    //   title: 'Agent 327!',
    //   src: 'https://media.vimejs.com/720p.mp4',
    //   type: 'video/mp4'
    // },
    // {
    //   title: 'Big Buck Bunny',
    //   src: 'http://static.videogular.com/assets/videos/big_buck_bunny_720p_h264.mov',
    //   type: 'video/mp4'
    // },
    // {
    //   title: 'Messi Goal',
    //   src: 'http://static.videogular.com/assets/videos/goal-2.mp4',
    //   type: 'video/mp4'
    // }
  ];

  currentIndex = 0;
  activeVideo:any;
  api!: { getDefaultMedia: () => { (): any; new(): any; subscriptions: { (): any; new(): any; loadedMetadata: { (): any; new(): any; subscribe: { (arg0: () => void): void; new(): any; }; }; ended: { (): any; new(): any; subscribe: { (arg0: () => void): void; new(): any; }; }; }; }; play: () => void; };

  constructor(
    private router: Router,
    public sportService: SportService,
    public favoriteService: FavoritesService,
    public sharedService: SharedServiceService,
    public ratingService: RatesService,
    public commonService: CommonServices,
    public staticDataService: StaticDataService,
    public dataStorage: DataStorageService,
    public modalService: NgbModal,
    public likeService: LikesService,
    private route: ActivatedRoute,
    public videosService: VideosService,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService, 


    ) {


  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      console.log('params', params)
      // if (typeof params['video-id'] !== 'undefined') {
      //   this.id = params['video-id']
      //   this.getVideo(parseInt(params['video-id'], 10))
      //   this.loading = true
      // }
    })
    this.sportName = this.sharedService.getsportName()
    console.log('this.sportName', this.sportName)
    this.getVideos();
    this.state = this.tokenStorage.getUser().state
    console.log('this.state', this.state)
    // this.likeService.getLikebyEntity(this.entity).subscribe(
    //   (likeResponse: any) => {
    //     const fitnessClassLike = likeResponse
    //     for (const i in fitnessClassLike) {
    //       this.likeData.push(fitnessClassLike[i].foreignKey)
    //     }
        this.sportName = this.sharedService.getsportName()
        // const sportLocalStorage = JSON.parse(this.dataStorage.getData('sport-data'))
        // this.filterSport = sportLocalStorage.filter((x: any) => x.name === this.sportName)
        // const FitnessLocalStorage = JSON.parse(this.dataStorage.getData('fitness-class-data'))
        // this.fitnessClassData = FitnessLocalStorage.filter((x: any) => x.sportId === this.filterSport[0].id)
        // setTimeout(() => {
        //   console.log("fitnessClassData", this.fitnessClassData)
        //   this.contentLoaded = true;
        // }, 6000);
    //   },
    //   (error: any) => {
    //   }
    // )


  }
  coursNavigation(name: any) {
    this.router.navigate(['/cours/' + name.replaceAll(' ', '-')])
  }

  getSport(id: any) {
    const data = [id, 'per-sport', false]
    return data
  }
  getData(fitnessData: any) {
    const data = [fitnessData]
    return data

  }

  setIsTrue() {
    this.isslide = false;
  }

  getVideos() {
    this.loading = true
    const data = {
      
    }
    this.videosService.getCoachVideo(data).subscribe(
      async (videos: any) => {
        this.videos = videos.results
        this.playlist = this.videos;
        console.log('this.videos', this.videos)
        this.loading = false
        this.videosFiltreted = []
        for (let i = 0; i < this.videos.length; i++) {
          this.activeVideo = this.playlist[i];
          for (let j = 0; j < this.videos[i].videoCategories.length; j++) {
            console.log('this.videos[i].videoCategories', this.videos[i].videoCategories)

          if(this.videos[i].videoCategories[j].name === this.sportName){
            this.videosFiltreted.push(this.videos[i]);
            console.log('this.videos[i].videoCategories', this.videos[i].videoCategories[j].name)

          }
          }
          this.videos[i].file = environment.filesUrl + '/' + this.videos[i].file
          this.videos[i].picture = environment.filesUrl + '/' + this.videos[i].picture
          this.videos[i].duration = this.commonService.convertTime(this.videos[i].duration, undefined)
          if (this.videos[i].totalBookmarks === undefined) {
            this.videos[i]['totalBookmarks'] = 0
          }
          if (this.videos[i].totalComments === undefined) {
            this.videos[i]['totalComments'] = 0
          }
          if (this.videos[i].totalBookmarks === undefined) {
            this.videos[i]['totalBookmarks'] = 0
          }
          if (this.videos[i].totalDislikes === undefined) {
            this.videos[i]['totalDislikes'] = 0
          }
          if (this.videos[i].totalDownloads === undefined) {
            this.videos[i]['totalDownloads'] = 0
          }
          if (this.videos[i].totalHits === undefined) {
            this.videos[i]['totalHits'] = 0
          }
          if (this.videos[i].totalLikes === undefined) {
            this.videos[i]['totalLikes'] = 0
          }
          if (this.videos[i].totalPreviewed === undefined) {
            this.videos[i]['totalPreviewed'] = 0
          }
         
        }

        console.log('this.videosFiltreted', this.videosFiltreted)

        setTimeout(() => {
          this.contentLoaded = true;
        }, 4000);
      },
      (err:any) => {
        this.loading = false
        console.log('error',err)

        this.commonService.handleResponse(err);
      }
    )

  }
  getAllVideos() {
    // this.loading = true
    const data = {
      
    }
    this.videosService.getCoachVideo(data).subscribe(
      async (videos: any) => {
        this.videos = videos.results
        this.playlist = this.videos;
        console.log('this.videos', this.videos)

        // this.loading = false
        for (let i = 0; i < this.videos.length; i++) {
          this.activeVideo = this.playlist[i];
          this.videos[i].file = environment.filesUrl + '/' + this.videos[i].file
          this.videos[i].picture = environment.filesUrl + '/' + this.videos[i].picture
          this.videos[i].duration = this.commonService.convertTime(this.videos[i].duration, undefined)
          if (this.videos[i].totalBookmarks === undefined) {
            this.videos[i]['totalBookmarks'] = 0
          }
          if (this.videos[i].totalComments === undefined) {
            this.videos[i]['totalComments'] = 0
          }
          if (this.videos[i].totalBookmarks === undefined) {
            this.videos[i]['totalBookmarks'] = 0
          }
          if (this.videos[i].totalDislikes === undefined) {
            this.videos[i]['totalDislikes'] = 0
          }
          if (this.videos[i].totalDownloads === undefined) {
            this.videos[i]['totalDownloads'] = 0
          }
          if (this.videos[i].totalHits === undefined) {
            this.videos[i]['totalHits'] = 0
          }
          if (this.videos[i].totalLikes === undefined) {
            this.videos[i]['totalLikes'] = 0
          }
          if (this.videos[i].totalPreviewed === undefined) {
            this.videos[i]['totalPreviewed'] = 0
          }
         
        }
        setTimeout(() => {
          this.contentLoaded = true;
        }, 4000);
      },
      (err:any) => {
        // this.loading = false
        this.commonService.handleResponse(err)

      }
    )

  }
  showVideo(video: any) {
    this.video = video
      // this.router.navigate(['/video/' + video.id])
    //   let currentUrl = this.router.url;
    //   let sport = this.sportName
    //   console.log('currentUrl', currentUrl)
    // this.router.navigate(['/video/' + video.id, { currentUrl , sport}])

    
    if (this.state === 'ACTIVE') {
      this.video = video
      // this.router.navigate(['/video/' + video.id])
      let currentUrl = this.router.url;
      let sport = this.sportName
      console.log('currentUrl', currentUrl)
      
      this.router.navigate(['/video/' + video.id, { currentUrl , sport}])
    } else {
    

      Swal.fire(this.translate.instant('OPPS'),
      this.translate.instant('ACCOUNTRECHARGE'), 'info')
    }
  }





  openslider(id: any, description: string) {
    console.log("id", id)
    this.isslide = true;
    switch (id) {
      case 'div1':
        this.imageSource1 = 'assets/imgch/ciseau_costal_8490.gif';
        this.imageSource2 = 'assets/imgch/rotation_buste___quilibre_2150.gif';
        this.imageSource3 = 'assets/imgch/gainage_dorsal_5670.gif';
        this.description1 = ' La bonne position : S’allonger sur le côté, un bras tendu à 90°, l’autre en-dessous de la tête. Le mouvement: Soulever la jambe tendue et le haut du corps en même temps. Expirer lorsque vous êtes en hauteur. La cible: Le côté de la ceinture abdominale'
        this.description2 = '  La bonne position : "Assis sur le fessier, lever la jambe et le haut du corps et tenir en équilibre." Le mouvement : "Balancer d’un côté à l’autre avec le torse, tout en essayant de maintenir une rythmique de respiration." La cible : "Les abdos inférieurs et les obliques. '
        this.description3 = 'La bonne position : "En équilibre sur une jambe, le haut du corps face au plafond, regarder du côté de la jambe tendue." Le mouvement : "Fléchir les bras en maintenant le fessier en hauteur, en position diagonale. Répéter 20 fois pour chaque jambe."'
        break;
      case 'div2':
        this.imageSource1 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_4_6863.gif';
        this.imageSource2 = 'assets/imgch/pompes_poirier_4031.gif';
        this.imageSource3 = 'assets/imgch/9_gif_8065_gif_3118.gif';
        this.description1 = ' La posture de yoga du danseur (Natarajasana) La bonne position : "Debout sur une jambe, lever l’autre jambe parallèlement au sol et l’agripper avec la main au niveau de la cheville." Le mouvement : "Graviter le haut du corps vers l’avant (et non sur le côté), en gainant toutes les parties du corps, en ouvrant la poitrine, et, le plus important, en gardant le bras et la jambe 100% toniques, jusqu’aux extrémités. Trouver l’équilibre avec le bras." La cible : "Pectoraux, abdominaux, et renforcement des jambes et du haut du corps.'
        this.description2 = 'Le handstand push up La bonne position : "Trouver une surface plane (un mur ou un arbre) pour rester en position de poirier." Le mouvement : "Faire des pompes. Lorsque l’on débute, l’idéal est de commencer par une pompe, avant de monter crescendo en intensité au fur et à mesure de la pratique." La cible : "La résistance et le renforcement de tout le corps. '
        this.description3 = 'Le gainage avec battement des bras La bonne position : "Etendu sur le sol, étirer la nuque, le dos à plat sur le sol et les bras le long du corps." Le mouvement : "Soulever et rabaisser légèrement les bras, jusqu’à sentir le bas des abdos qui brûle. Gainer l’ensemble du corps et ne surtout pas oublier de respirer." La cible : "Les abdos inférieurs'
        break;
      case 'div3':
        this.imageSource1 = 'assets/imgch/10_gif_6258_gif_6367.gif';
        this.imageSource2 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_6_387.gif';
        this.imageSource3 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_7_830.gif';
        this.description1 = 'Le sit-up progressif La bonne position : "Allongé sur le sol, les jambes relevées et les genoux pliés." Le mouvement : "Regarder et orienter les bras vers différentes positions : à 90°, 60°, 30° puis 10°. Expirer lorsque vous êtes en haut. A recommencer 20 fois à chaque amplitude." La cible : "Toute la ceinture abdominale. Rien de tel pour se tailler un "8 packs'
        this.description2 = 'Stretching : les abdos La bonne position : "Assis sur le sol, genoux croisés, mettre le bras droit sur le genou gauche. Garder le buste droit et maintenir la position avec le bras derrière avant d alterner de l autre côté." Le mouvement : "En inspirant, étirer la nuque et en expirant, pivoter avec les muscles abdominaux (et non avec la nuque).'
        this.description3 = 'Stretching : les jambes La bonne position : “Se mettre debout sur une jambe et lever l’autre avec les mains, en laissant le genou au même niveau." Le mouvement : "Lever une jambe du sol entre les mains, courber le dos au dessus du genou, sans monter la jambe. Le but est de se maintenir en équilibre le plus longtemps possible, sans jamais forcer sur la jambe maintenue en l’air. Changer ensuite de jambe." La cible : "Les jambes."'
        break;
      case 'div4':
        this.imageSource1 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_8_8176.gif';
        this.imageSource2 = 'assets/imgch/Hyperextensions.gif';
        this.imageSource3 = 'assets/imgch/Situps-Giannis.gif';
        this.description1 = '"Ecarter les jambes de la même distance que les épaules, avant de se mettre en position assise." Le mouvement : "Descendre le fessier à 90°, respirer en maintenant le haut du dos bien droit et courber légèrement le bas du dos."'
        this.description2 = 'La bonne position : Relève ton dos lentement et de façon contrôlée, en regardant au sol.	Tends les bras vers l’avant afin d’augmenter l’angle et la difficulté.'
        this.description3 = 'La bonne position : Le dos reste bien droit à la montée.'
        break;
      case 'div5':
        this.imageSource1 = 'assets/imgch/Side-Plank-Hip-Lift-1.gif';
        this.imageSource2 = 'assets/imgch/Leg-raises-Giannis.gif';
        this.imageSource3 = 'assets/imgch/Superman-Plank-Giannis.gif';
        this.description1 = 'Place ton coude au niveau de ton épaule. Abaisse tes hanches jusqu’à frôler le sol sans pour autant le toucher'
        this.description2 = 'Veille à toujours contracter suffisamment ton buste pour éviter de creuser le dos.'
        this.description3 = '	Soulève les jambes en alternance et garde appui sur les avant-bras.'
        break;

      case 'div6':
        this.imageSource1 = 'assets/imgch/Russian-Twist-Giannis.gif';
        this.imageSource2 = 'assets/imgch/Walking-Lunge-Kurzhantel-Nada.gif';
        this.imageSource3 = 'assets/imgch/Butterfly-Reverse.gif';
        this.description1 = 'Les pieds doivent rester aussi immobiles que possible, le haut du corps reste droit.'
        this.description2 = 'Lorsque tu poses le pied par terre, ton genou et ta cheville doivent être à peu près alignés. Évite les mouvements latéraux de la cuisse et du genou. L’ensemble du mouvement est stable et bien coordonné. Dans le doute, commence par t’entraîner au poids du corps.'
        this.description3 = 'Les paumes sont orientées vers le bas, les pouces reposent sur les autres doigts. Les coudes sont très légèrement fléchis, les haltères sont alignés avec les épaules. Le poids est réparti sur l’ensemble du pied, les genoux sont alignés avec les chevilles.'
        break;

      case 'div7':
        this.imageSource1 = 'assets/imgch/Wadenheben-Nada.gif';
        this.imageSource2 = 'assets/imgch/752a1458f6e51663bd63d4293dc9b428.gif';
        this.imageSource3 = 'assets/imgch/giphy.gif';
        this.description1 = 'Tes muscles ne se développent que si tu leur laisses suffisamment de temps pour récupérer après un bon entraînement. Dans notre article sur la surcompensation, découvre comment il convient de t’entraîner pour obtenir les meilleurs résultats possible en matière de forme physique, de perte de poids, de course à pied et de musculation.'
        this.description2 = 'Placez vos mains sur le sol entre vos pieds. Déplacez votre poids sur vos mains.Sauter sur vos pieds pour retomber doucement sur la plante de vos pieds en position planche. Votre corps doit former une ligne droite de la tête aux talons. Faites attention à ne pas laisser votre dos s’affaisser ou vos fesses se soulever en l’air, car les deux peuvent vous empêcher de travailler efficacement votre tronc.'
        this.description3 = 'Posez les deux mains et les genoux sur le sol.Placez votre pied droit près de votre main droite et étendez votre jambe gauche derrière vous.D’un seul mouvement, changez vos jambes en gardant vos bras dans la même position.Changez de jambe deux fois d’avant en arrière, de sorte que votre jambe droite soit à nouveau près de votre main droite.'
        break;

      case 'div8':
        this.imageSource1 = 'assets/imgch/EXercice-abdos-1.gif';
        this.imageSource2 = 'assets/imgch/exercice-abdos-gif-2.gif';
        this.imageSource3 = 'assets/imgch/exercice-abdos-gif-3.gif';
        this.description1 = 'On s’allonge au sol sur le dos et on relève les jambes droites.On relève les fesses en gardant les jambes en l’air.'
        this.description2 = 'On s’allonge sur le dos, on relève et on pli les jambes.On ramène les genoux à la poitrine.'
        this.description3 = 'On s’allonge sur le dos en redressant légèrement notre buste et nos jambes.On maintient la position de départ en faisant un ciseau avec nos jambes tout en gainant nos abdominaux.'
        break;

      case 'div9':
        this.imageSource1 = 'assets/imgch/20170112-5.gif';
        this.imageSource2 = 'assets/imgch/20170112-7.gif';
        this.imageSource3 = 'assets/imgch/20170112-8.gif';
        this.description1 = 'Tes muscles ne se développent que si tu leur laisses suffisamment de temps pour récupérer après un bon entraînement. Dans notre article sur la surcompensation, découvre comment il convient de t’entraîner pour obtenir les meilleurs résultats possible en matière de forme physique, de perte de poids, de course à pied et de musculation.'
        this.description2 = 'Mettez-vous en position planche, en tenant des haltères dans chaque main. Votre corps fait une ligne droite de la tête aux pieds. Levez chaque haltère vers son épaule correspondante, puis levez-vous et sautez en l’air (un peu comme pour le burpee). Levez immédiatement vos haltères au-dessus de votre tête. Rabaissez-les de part et d ’autre de votre buste. '
        this.description3 = 'Debout avec les pieds largement écartés, vos orteils sont tournés vers l’extérieur à 45°. Avec vos mains réunies devant vous pour plus d’équilibre, contractez vos abdos et descendez votre corps jusqu’à ce que vous arriviez en position assise. Faites une pause et poussez doucement sur vos fessiers et cuisses intérieures pour revenir en position initiale. '
        break;

      case 'div10':
        this.imageSource1 = 'assets/imgch/exercice-abdos-gif-4.gif';
        this.imageSource2 = 'assets/imgch/exercice-abdos-gif-5.gif';
        this.imageSource3 = 'assets/imgch/exercice-abdos-gif-6.gif';
        this.description1 = 'On s’allonge sur un côté en repliant la jambe de derrière et en plaçant la main (qui est du même côté que la jambe pliée) derrière la tête.On contracte les obliques en ramenant le coude vers la jambe. '
        this.description2 = 'On s’allonge sur l’autre côté en repliant l’autre jambe et en plaçant l’autre main derrière la tête.On contracte les obliques en ramenant le coude vers la jambe.'
        this.description3 = 'On s’allonge sur le dos et on relève nos jambes droites.On remonte le buste avec les bras tendus vers les jambes.'

        break;

      case 'div11':
        this.imageSource1 = 'assets/imgch/exercice-abdos-gif-7.gif';
        this.imageSource2 = 'assets/imgch/tuck-crunch-2.gif';
        this.imageSource3 = 'assets/imgch/tuck-crunch-1-2.gif';
        this.description1 = 'On s’assoit le dos droit avec les jambes pliées, surélevées et croisées devant nous.On descend le buste et les jambes et on remonte. On garde l’équilibre sans forcer avec notre dos.'
        this.description2 = 'Tu t’assois sur un banc ou une chaise, les bras tendus et les pieds à plat, puis tu soulèves les jambes jusqu’à ce que la pointe de tes pieds dépassentta tête. Ensuite, maintien cette position quelques secondes pour réaliser ce que l’on appel un V-Sit Static Hold.'
        this.description3 = 'Une autre façon d’entraîner ces muscles en même temps est de réaliser le Seated Russian Twist ou en français, l’exercice des torsions russes assises. C’est-à-dire de faire des torsions de chaque côté, tout en restant assis, bien droit, les pieds en appui sur le sol si besoin au départ, de sorte qu’il n’y ai pas risque de basculer.'
        break;

      case 'div12':
        this.imageSource1 = 'assets/imgch/giphy1.gif';
        this.imageSource2 = 'assets/imgch/karenatiptoesumo-876f5df825bed32d28e91a742ec03756.gif';
        this.imageSource3 = 'assets/imgch/76457fa8abceadb776b13940c4473b7d.gif';
        this.description1 = 'La fente est un exercice complémentaire aux squats. Elle travaille à la fois les cuisses et les fessiers. Debout, jambes écartées de la largeur du bassin, avancez votre jambe en la pliant jusqu’à obtenir un angle de 90° au niveau du genou. Les mains doivent être posées sur la taille et le dos droit. Réitérez l’exercice en changeant de jambe. Pour obtenir des gambettes bien galbées, pratiquez 2 séries de 20 fentes (10 fentes pour chaque jambe, enfin vous comprenez quoi…).'
        this.description2 = 'Le squat pointe est un exercice de fitness au poids de corps. Il s’agit d’une variante du air squat  qui permet un travail des fessiers et des jambes. Mené à haute intensité cet exercice est très sollicitant pour l’appareil cardio-vasculaire.'
        this.description3 = 'Nous connaissons tous les difficultés de la planche : allongez-vous sur le ventre, maintenez votre corps parallèle au sol, puis soulevez le poids de votre corps pour qu’il repose sur vos avant-bras et vos pieds. L’astuce consiste à faire travailler vos muscles abdominaux (en ramenant votre nombril vers votre colonne vertébrale) et à maintenir votre dos droit.'
        break;

      case 'div13':
        this.imageSource1 = 'assets/imgch/_ankleJumpingJacksSelf2_1_1.gif';
        this.imageSource2 = 'assets/imgch/Jeanette_Lateral.gif';
        this.imageSource3 = 'assets/imgch/_StandingGluteKickBackSelf2_1_4.gif';
        this.description1 = 'Essayez de ne pas laisser votre corps s’élever trop haut et atterrir avec votre poids principalement sur vos talons, pas sur vos orteils.'
        this.description2 = 'Essayez de garder votre poids au centre, de garder votre tronc engagé et de maintenir une tension constante dans le groupe'
        this.description3 = 'Ce n’est pas un grand mouvement". Si vous sentez le bas de votre dos se cambrer lorsque vous donnez un coup de pied, réduisez le mouvement. Essayez de ne pas mettre de poids sur la jambe qui donne des coups de pied lorsqu’elle redescend au so'
        break;

      case 'div14':
        this.imageSource1 = 'assets/imgch/montee_bassin_7435.gif';
        this.imageSource2 = 'assets/imgch/flexion_9507.gif';
        this.imageSource3 = 'assets/imgch/extension_9185.gif';
        this.description1 = 'Que ce soit sur un meuble bas ou le rebord de votre canapé, l’idée ici est de s’allonger sur le dos, les épaules plaquées au sol, et de soulever le poids du corps sur une jambe, tandis que l’autre reste tendue à l’horizontal. Le bon rythme : 3 séries de 15 répétitions !'
        this.description2 = 'Direction la cuisine ou la salle à manger : le buste bien droit, mettre un pied sur une table ou autre support solide et descendre en flexion sur le genou. Répéter cet exercice 3 fois avec des séries de 10 et ne pas oublier de changer de jambe. Pour aller encore plus loin, s’emparer d’un ballon et faire travailler les bras de concert.'
        this.description3 = 'S’appuyer sur une table ou un autre support solide, tendre les bras pour mettre le haut du corps bien à l’horizontale et déplier la jambe afin de la tendre. Répéter ce mouvement 20 fois pour 3 séries en alternant la jambe à chaque fois au milieu de la série. Il est important de garder le dos bien à l’horizontal et ne pas le cambrer.'
        break;

      case 'div15':
        this.imageSource1 = 'assets/imgch/JUMPING_JACKS.gif';
        this.imageSource2 = 'assets/imgch/FAST_FEET.gif';
        this.imageSource3 = 'assets/imgch/57e3cd3b2bc0e655981000.gif';
        this.description1 = 'La position de départ : debout, les bras le long du corps et les pieds joints. On saute en écartant les jambes et en levant les bras. Commencer par les jumping jacks accélère le rythme cardiaque. '
        this.description2 = 'L’exercice de fast feet consiste à courir sur place sans trop lever les jambes. Là encore on mise sur le cardio. Idéal pour réveiller son corps encore assoupi.'
        this.description3 = 'Ce mouvement pratiqué en Pilates aide à réveiller tout le corps. On démarre allongé sur le sol, les bras derrière la tête. Puis ont vient s’asseoir les bras droit devant. Et on répète l’exercice pendant 30 secondes.'
        break;

        case 'div16':
          this.imageSource1 = 'assets/imgch/ciseau_costal_8490.gif';
          this.imageSource2 = 'assets/imgch/rotation_buste___quilibre_2150.gif';
          this.imageSource3 = 'assets/imgch/gainage_dorsal_5670.gif';
          this.description1 = ' La bonne position : S’allonger sur le côté, un bras tendu à 90°, l’autre en-dessous de la tête. Le mouvement: Soulever la jambe tendue et le haut du corps en même temps. Expirer lorsque vous êtes en hauteur. La cible: Le côté de la ceinture abdominale'
          this.description2 = '  La bonne position : "Assis sur le fessier, lever la jambe et le haut du corps et tenir en équilibre." Le mouvement : "Balancer d’un côté à l’autre avec le torse, tout en essayant de maintenir une rythmique de respiration." La cible : "Les abdos inférieurs et les obliques. '
          this.description3 = 'La bonne position : "En équilibre sur une jambe, le haut du corps face au plafond, regarder du côté de la jambe tendue." Le mouvement : "Fléchir les bras en maintenant le fessier en hauteur, en position diagonale. Répéter 20 fois pour chaque jambe."'
          break;
        case 'div17':
          this.imageSource1 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_4_6863.gif';
          this.imageSource2 = 'assets/imgch/pompes_poirier_4031.gif';
          this.imageSource3 = 'assets/imgch/9_gif_8065_gif_3118.gif';
          this.description1 = ' La posture de yoga du danseur (Natarajasana) La bonne position : "Debout sur une jambe, lever l’autre jambe parallèlement au sol et l’agripper avec la main au niveau de la cheville." Le mouvement : "Graviter le haut du corps vers l’avant (et non sur le côté), en gainant toutes les parties du corps, en ouvrant la poitrine, et, le plus important, en gardant le bras et la jambe 100% toniques, jusqu’aux extrémités. Trouver l’équilibre avec le bras." La cible : "Pectoraux, abdominaux, et renforcement des jambes et du haut du corps.'
          this.description2 = 'Le handstand push up La bonne position : "Trouver une surface plane (un mur ou un arbre) pour rester en position de poirier." Le mouvement : "Faire des pompes. Lorsque l’on débute, l’idéal est de commencer par une pompe, avant de monter crescendo en intensité au fur et à mesure de la pratique." La cible : "La résistance et le renforcement de tout le corps. '
          this.description3 = 'Le gainage avec battement des bras La bonne position : "Etendu sur le sol, étirer la nuque, le dos à plat sur le sol et les bras le long du corps." Le mouvement : "Soulever et rabaisser légèrement les bras, jusqu’à sentir le bas des abdos qui brûle. Gainer l’ensemble du corps et ne surtout pas oublier de respirer." La cible : "Les abdos inférieurs'
          break;
        case 'div18':
          this.imageSource1 = 'assets/imgch/10_gif_6258_gif_6367.gif';
          this.imageSource2 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_6_387.gif';
          this.imageSource3 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_7_830.gif';
          this.description1 = 'Le sit-up progressif La bonne position : "Allongé sur le sol, les jambes relevées et les genoux pliés." Le mouvement : "Regarder et orienter les bras vers différentes positions : à 90°, 60°, 30° puis 10°. Expirer lorsque vous êtes en haut. A recommencer 20 fois à chaque amplitude." La cible : "Toute la ceinture abdominale. Rien de tel pour se tailler un "8 packs'
          this.description2 = 'Stretching : les abdos La bonne position : "Assis sur le sol, genoux croisés, mettre le bras droit sur le genou gauche. Garder le buste droit et maintenir la position avec le bras derrière avant d alterner de l autre côté." Le mouvement : "En inspirant, étirer la nuque et en expirant, pivoter avec les muscles abdominaux (et non avec la nuque).'
          this.description3 = 'Stretching : les jambes La bonne position : “Se mettre debout sur une jambe et lever l’autre avec les mains, en laissant le genou au même niveau." Le mouvement : "Lever une jambe du sol entre les mains, courber le dos au dessus du genou, sans monter la jambe. Le but est de se maintenir en équilibre le plus longtemps possible, sans jamais forcer sur la jambe maintenue en l’air. Changer ensuite de jambe." La cible : "Les jambes."'
          break;
        case 'div19':
          this.imageSource1 = 'assets/imgch/tomas_skoloudik_routine_sportive_gifs_mannequin_8_8176.gif';
          this.imageSource2 = 'assets/imgch/Hyperextensions.gif';
          this.imageSource3 = 'assets/imgch/Situps-Giannis.gif';
          this.description1 = '"Ecarter les jambes de la même distance que les épaules, avant de se mettre en position assise." Le mouvement : "Descendre le fessier à 90°, respirer en maintenant le haut du dos bien droit et courber légèrement le bas du dos."'
          this.description2 = 'La bonne position : Relève ton dos lentement et de façon contrôlée, en regardant au sol.	Tends les bras vers l’avant afin d’augmenter l’angle et la difficulté.'
          this.description3 = 'La bonne position : Le dos reste bien droit à la montée.'
          break;
        case 'div20':
          this.imageSource1 = 'assets/imgch/Side-Plank-Hip-Lift-1.gif';
          this.imageSource2 = 'assets/imgch/rLeg-raises-Giannis.gif';
          this.imageSource3 = 'assets/imgch/Superman-Plank-Giannis.gif';
          this.description1 = 'Place ton coude au niveau de ton épaule. Abaisse tes hanches jusqu’à frôler le sol sans pour autant le toucher'
          this.description2 = 'Veille à toujours contracter suffisamment ton buste pour éviter de creuser le dos.'
          this.description3 = '	Soulève les jambes en alternance et garde appui sur les avant-bras.'
          break;
  
        case 'div21':
          this.imageSource1 = 'assets/imgch/Russian-Twist-Giannis.gif';
          this.imageSource2 = 'assets/imgch/Walking-Lunge-Kurzhantel-Nada.gif';
          this.imageSource3 = 'assets/imgch/Butterfly-Reverse.gif';
          this.description1 = 'Les pieds doivent rester aussi immobiles que possible, le haut du corps reste droit.'
          this.description2 = 'Lorsque tu poses le pied par terre, ton genou et ta cheville doivent être à peu près alignés. Évite les mouvements latéraux de la cuisse et du genou. L’ensemble du mouvement est stable et bien coordonné. Dans le doute, commence par t’entraîner au poids du corps.'
          this.description3 = 'Les paumes sont orientées vers le bas, les pouces reposent sur les autres doigts. Les coudes sont très légèrement fléchis, les haltères sont alignés avec les épaules. Le poids est réparti sur l’ensemble du pied, les genoux sont alignés avec les chevilles.'
          break;
  
        case 'div22':
          this.imageSource1 = 'assets/imgch/Wadenheben-Nada.gif';
          this.imageSource2 = 'assets/imgch/752a1458f6e51663bd63d4293dc9b428.gif';
          this.imageSource3 = 'assets/imgch/giphy.gif';
          this.description1 = 'Tes muscles ne se développent que si tu leur laisses suffisamment de temps pour récupérer après un bon entraînement. Dans notre article sur la surcompensation, découvre comment il convient de t’entraîner pour obtenir les meilleurs résultats possible en matière de forme physique, de perte de poids, de course à pied et de musculation.'
          this.description2 = 'Placez vos mains sur le sol entre vos pieds. Déplacez votre poids sur vos mains.Sauter sur vos pieds pour retomber doucement sur la plante de vos pieds en position planche. Votre corps doit former une ligne droite de la tête aux talons. Faites attention à ne pas laisser votre dos s’affaisser ou vos fesses se soulever en l’air, car les deux peuvent vous empêcher de travailler efficacement votre tronc.'
          this.description3 = 'Posez les deux mains et les genoux sur le sol.Placez votre pied droit près de votre main droite et étendez votre jambe gauche derrière vous.D’un seul mouvement, changez vos jambes en gardant vos bras dans la même position.Changez de jambe deux fois d’avant en arrière, de sorte que votre jambe droite soit à nouveau près de votre main droite.'
          break;
  
        case 'div23':
          this.imageSource1 = 'assets/imgch/EXercice-abdos-1.gif';
          this.imageSource2 = 'assets/imgch/exercice-abdos-gif-2.gif';
          this.imageSource3 = 'assets/imgch/exercice-abdos-gif-3.gif';
          this.description1 = 'On s’allonge au sol sur le dos et on relève les jambes droites.On relève les fesses en gardant les jambes en l’air.'
          this.description2 = 'On s’allonge sur le dos, on relève et on pli les jambes.On ramène les genoux à la poitrine.'
          this.description3 = 'On s’allonge sur le dos en redressant légèrement notre buste et nos jambes.On maintient la position de départ en faisant un ciseau avec nos jambes tout en gainant nos abdominaux.'
          break;
  
        case 'div24':
          this.imageSource1 = 'assets/imgch/20170112-5.gif';
          this.imageSource2 = 'assets/imgch/20170112-7.gif';
          this.imageSource3 = 'assets/imgch/20170112-8.gif';
          this.description1 = 'Tes muscles ne se développent que si tu leur laisses suffisamment de temps pour récupérer après un bon entraînement. Dans notre article sur la surcompensation, découvre comment il convient de t’entraîner pour obtenir les meilleurs résultats possible en matière de forme physique, de perte de poids, de course à pied et de musculation.'
          this.description2 = 'Mettez-vous en position planche, en tenant des haltères dans chaque main. Votre corps fait une ligne droite de la tête aux pieds. Levez chaque haltère vers son épaule correspondante, puis levez-vous et sautez en l’air (un peu comme pour le burpee). Levez immédiatement vos haltères au-dessus de votre tête. Rabaissez-les de part et d ’autre de votre buste. '
          this.description3 = 'Debout avec les pieds largement écartés, vos orteils sont tournés vers l’extérieur à 45°. Avec vos mains réunies devant vous pour plus d’équilibre, contractez vos abdos et descendez votre corps jusqu’à ce que vous arriviez en position assise. Faites une pause et poussez doucement sur vos fessiers et cuisses intérieures pour revenir en position initiale. '
          break;
  
        case 'div25':
          this.imageSource1 = 'assets/imgch/exercice-abdos-gif-4.gif';
          this.imageSource2 = 'assets/imgch/exercice-abdos-gif-5.gif';
          this.imageSource3 = 'assets/imgch/exercice-abdos-gif-6.gif';
          this.description1 = 'On s’allonge sur un côté en repliant la jambe de derrière et en plaçant la main (qui est du même côté que la jambe pliée) derrière la tête.On contracte les obliques en ramenant le coude vers la jambe. '
          this.description2 = 'On s’allonge sur l’autre côté en repliant l’autre jambe et en plaçant l’autre main derrière la tête.On contracte les obliques en ramenant le coude vers la jambe.'
          this.description3 = 'On s’allonge sur le dos et on relève nos jambes droites.On remonte le buste avec les bras tendus vers les jambes.'
  
          break;
  
        case 'div26':
          this.imageSource1 = 'assets/imgch/exercice-abdos-gif-7.gif';
          this.imageSource2 = 'assets/imgch/tuck-crunch-2.gif';
          this.imageSource3 = 'assets/imgch/tuck-crunch-1-2.gif';
          this.description1 = 'On s’assoit le dos droit avec les jambes pliées, surélevées et croisées devant nous.On descend le buste et les jambes et on remonte. On garde l’équilibre sans forcer avec notre dos.'
          this.description2 = 'Tu t’assois sur un banc ou une chaise, les bras tendus et les pieds à plat, puis tu soulèves les jambes jusqu’à ce que la pointe de tes pieds dépassentta tête. Ensuite, maintien cette position quelques secondes pour réaliser ce que l’on appel un V-Sit Static Hold.'
          this.description3 = 'Une autre façon d’entraîner ces muscles en même temps est de réaliser le Seated Russian Twist ou en français, l’exercice des torsions russes assises. C’est-à-dire de faire des torsions de chaque côté, tout en restant assis, bien droit, les pieds en appui sur le sol si besoin au départ, de sorte qu’il n’y ai pas risque de basculer.'
          break;
  
        case 'div27':
          this.imageSource1 = 'assets/imgch/giphy1.gif';
          this.imageSource2 = 'assets/imgch/karenatiptoesumo-876f5df825bed32d28e91a742ec03756.gif';
          this.imageSource3 = 'assets/imgch/76457fa8abceadb776b13940c4473b7d.gif';
          this.description1 = 'La fente est un exercice complémentaire aux squats. Elle travaille à la fois les cuisses et les fessiers. Debout, jambes écartées de la largeur du bassin, avancez votre jambe en la pliant jusqu’à obtenir un angle de 90° au niveau du genou. Les mains doivent être posées sur la taille et le dos droit. Réitérez l’exercice en changeant de jambe. Pour obtenir des gambettes bien galbées, pratiquez 2 séries de 20 fentes (10 fentes pour chaque jambe, enfin vous comprenez quoi…).'
          this.description2 = 'Le squat pointe est un exercice de fitness au poids de corps. Il s’agit d’une variante du air squat  qui permet un travail des fessiers et des jambes. Mené à haute intensité cet exercice est très sollicitant pour l’appareil cardio-vasculaire.'
          this.description3 = 'Nous connaissons tous les difficultés de la planche : allongez-vous sur le ventre, maintenez votre corps parallèle au sol, puis soulevez le poids de votre corps pour qu’il repose sur vos avant-bras et vos pieds. L’astuce consiste à faire travailler vos muscles abdominaux (en ramenant votre nombril vers votre colonne vertébrale) et à maintenir votre dos droit.'
          break;
  
        case 'div28':
          this.imageSource1 = 'assets/imgch/_ankleJumpingJacksSelf2_1_1.gif';
          this.imageSource2 = 'assets/imgch/Jeanette_Lateral%20Band%20Walk.gif';
          this.imageSource3 = 'assets/imgch/_StandingGluteKickBackSelf2_1_4.gif';
          this.description1 = 'Essayez de ne pas laisser votre corps s’élever trop haut et atterrir avec votre poids principalement sur vos talons, pas sur vos orteils.'
          this.description2 = 'Essayez de garder votre poids au centre, de garder votre tronc engagé et de maintenir une tension constante dans le groupe'
          this.description3 = 'Ce n’est pas un grand mouvement". Si vous sentez le bas de votre dos se cambrer lorsque vous donnez un coup de pied, réduisez le mouvement. Essayez de ne pas mettre de poids sur la jambe qui donne des coups de pied lorsqu’elle redescend au so'
          break;
  
        case 'div29':
          this.imageSource1 = 'assets/imgch/montee_bassin_7435.gif';
          this.imageSource2 = 'assets/imgch/flexion_9507.gif';
          this.imageSource3 = 'assets/imgch/extension_9185.gif';
          this.description1 = 'Que ce soit sur un meuble bas ou le rebord de votre canapé, l’idée ici est de s’allonger sur le dos, les épaules plaquées au sol, et de soulever le poids du corps sur une jambe, tandis que l’autre reste tendue à l’horizontal. Le bon rythme : 3 séries de 15 répétitions !'
          this.description2 = 'Direction la cuisine ou la salle à manger : le buste bien droit, mettre un pied sur une table ou autre support solide et descendre en flexion sur le genou. Répéter cet exercice 3 fois avec des séries de 10 et ne pas oublier de changer de jambe. Pour aller encore plus loin, s’emparer d’un ballon et faire travailler les bras de concert.'
          this.description3 = 'S’appuyer sur une table ou un autre support solide, tendre les bras pour mettre le haut du corps bien à l’horizontale et déplier la jambe afin de la tendre. Répéter ce mouvement 20 fois pour 3 séries en alternant la jambe à chaque fois au milieu de la série. Il est important de garder le dos bien à l’horizontal et ne pas le cambrer.'
          break;
  
        case 'div30':
          this.imageSource1 = 'assets/imgch/JUMPING_JACKS.gif';
          this.imageSource2 = 'assets/imgch/FAST_FEET.gif';
          this.imageSource3 = 'assets/imgch/57e3cd3b2bc0e655981000.gif';
          this.description1 = 'La position de départ : debout, les bras le long du corps et les pieds joints. On saute en écartant les jambes et en levant les bras. Commencer par les jumping jacks accélère le rythme cardiaque. '
          this.description2 = 'L’exercice de fast feet consiste à courir sur place sans trop lever les jambes. Là encore on mise sur le cardio. Idéal pour réveiller son corps encore assoupi.'
          this.description3 = 'Ce mouvement pratiqué en Pilates aide à réveiller tout le corps. On démarre allongé sur le sol, les bras derrière la tête. Puis ont vient s’asseoir les bras droit devant. Et on répète l’exercice pendant 30 secondes.'
          break;
      default:
        this.imageSource1 = ''; // Aucune image par défaut
        break;
    }
  }
 
  goBack() {
    this.isslide = false;
  }

}
