import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { PostsService } from '../../../services/posts/posts.service'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
})
export class PostViewComponent implements OnInit {
  public post: any
  public loading = false
  public postId: any
  constructor(
    private commonService: CommonServices,
    private postsService: PostsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.route.params.subscribe((params: any) => {
      console.log('id post', params, params['post-id'])

      if (typeof params['post-id'] !== 'undefined') {
        this.loading = false
        this.getPost(parseInt(params['post-id'], 10))
      }
    })

  }

  getPost(id: number): void {
    this.loading = true
    this.postsService.getPost(id).subscribe((response: any) => {
      if (response !== null) {
        this.post = response
        this.post.picture = environment.filesUrl + this.post.picture
   

        // if (this.post.content !== undefined) {
        //   this.post.content = this.post.content.replaceAll(
        //     /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
        //     ''
        //   )
        // }
        this.loading = false
      }
    },
      (err) => {
        console.log('Posts', err)
        this.loading = false
        this.commonService.handleResponse(err)
      })
  }


}
