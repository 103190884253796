import { Component, OnInit } from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { LanguageService } from 'src/app/services/languages/language.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { StaticDataService } from './services/static-data/static-data.service';
import { DataStorageService } from './services/data-storage.service'
import { SportService } from 'src/app/services/sport/sport.service'

import { AuthService } from 'src/app/services/auth/auth.service'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import * as moment from 'moment'
import { Message } from 'primeng/api'
import { Coach } from 'src/app/interfaces/coach.interface'
import { FitnessClassService } from 'src/app/services/fitness-class/fitness-class.service'
import { FavoritesService } from 'src/app/services/favorites/favorites.service'
import { VideosService } from 'src/app/services/videos/videos.service'
import { environment } from 'src/app/../environments/environment.prod'
import { SessionsService } from 'src/app/services/sessions-coach/sessions.service'
import { CoachService } from 'src/app/services/coach/coach.service'
import { RatesService } from 'src/app/services/rates/rates.service'
import { Inscription } from 'src/app/interfaces/inscription.interface'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [`
        :host ::ng-deep button {
            margin-right: .5em;
        }
    `],
  providers: [TranslatePipe]

})
export class AppComponent implements OnInit {
  loading = false
  showNavbar = false
  showFooter = false
  public languages: any
  public selectedLanguage: any = null
  public isLoggedIn = false
  public userRole: any = null
  public message: Message[] = []
  public isLoginFailed = false
  public errorMessage = ''
  public appPages: any = []
  public sessions: any = []
  public currentSession: any = null
  public coachs: Coach[] = []
  public language = []
  public getVideoFav: any = []
  public videoFav: any = []
  public fitnessClassData: any = []
  public favaDta: any = []
  public favArray: any = []
  public totalRaiting: any
  public favorites: any
  public CachRateIds: any = []
  public coachsId: any = []
  public coachData: any = []
  public sportsList: any = []
  public sportsData: any = []
  public localLanguage: any = []
  public isMember = false
    public isUserLoggedIn = false

  constructor(private primengConfig: PrimeNGConfig,
    private translate: TranslateService,
    private router: Router,
    private languageService: LanguageService,
    public commonService: CommonServices,
    public staticData: StaticDataService,
    public localData :DataStorageService,
    private readonly commomService: CommonServices,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private fitnessClass: FitnessClassService,
    private sessionsService: SessionsService,
    private favoriteService: FavoritesService,
    private videosService: VideosService,
    private dataStorage: DataStorageService,
    private coachService: CoachService,
    private ratingService: RatesService,
    private sportService: SportService,
  ) {


    this.selectedLanguage = localStorage.getItem('language')
    if (typeof this.selectedLanguage === 'undefined' || this.selectedLanguage === null) {
      // this.selectedLanguage = this.languages[1].code;

    }else{
     if (this.selectedLanguage === 'ar') {
      document.documentElement.dir = 'rtl';
     }
    }
    this.translate.use(this.selectedLanguage)
    this.commonService.languageChanged$.subscribe(langCode => this.onLanguageChanged(langCode))
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('fr')
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    router.events.forEach((event:any) => {
      if (event instanceof NavigationStart) {
        console.log('event', event['url'], this.showNavbar, this.showFooter);
    
        if (event['url'] === '/auth/coach/login' || event['url'] === '/terms-and-conditions' || event['url'] === '/error-404') {
          this.showNavbar = false;
          this.showFooter = false;
        } else {
          this.showNavbar = true;
          this.showFooter = true;
        }
      }
      console.log('event', event['url'], this.showNavbar, this.showFooter);
    });
  }
  title = 'ClubFitness'
  ngOnInit(): void {
      this.checkIsLoginOrNot();

      console.log('test url ngOnInit', window.location.href, this.isUserLoggedIn)
      let url = window.location.href
     if (!this.isUserLoggedIn && !(url.includes('?&code=')) ) {
        window.location.href = 'https://payment.eklectic.tn/API/oauth/user/authorize?client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&response_type=code&scope=FITNESSBYTT&redirect_uri=https://www.clubfitness.tn/#/home';
      } 
      else if (!this.isUserLoggedIn) {
        if (url.includes('code=') && url.includes('offre_id=142')) {
            this.handleUrlParameters(url, 'code', 'offre_id=142');
        } else if (url.includes('token=') && url.includes('offre_id=142')) {
          this.handleUrlParameters(url, 'token', 'offre_id=142');
        } else if (url.includes('token=')) {
          this.handleUrlParameters(url, 'token');
        } else if (url.includes('code=')) {
          this.handleUrlParameters(url, 'code');
        }
    }
       
  
  
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return
      }
      const options: ScrollToOptions = {
        top: 0,
        behavior: 'smooth'
      };
  
      window.scrollTo(options);    })
  }
   handleUrlParameters(url:any, paramKey:any, additionalCondition = '') {
    if (url.includes(paramKey) && (!additionalCondition || url.includes(additionalCondition))) {
        console.log('true', window.location.href);

        let urlParts = window.location.href.split('&');
        console.log("urlParts ", urlParts);

        const params: any = {};
        urlParts.forEach(param => {
            console.log('param', param);

            const [key, value] = param.split('=');
            params[key] = value;
            if (param.includes(paramKey)) {
                params[paramKey] = value;
                console.log(paramKey, value);

                const authorizationCode = value;
                this.confirmOpenId(authorizationCode);
            }
        });
    }
}
  async confirmOpenId(code:any) {
    let codee = {
      'code': code 
    }
    await this.authService.confirmOpenId(codee)
      .subscribe(async (data: any) => {
        const responseObj = JSON.parse(data);

        console.log("result responseObj",responseObj, responseObj.id_token)
        let tokenId = {
          "id_token": responseObj.id_token
        }

        this.authService.createNewAccessUser(tokenId)
          .subscribe(async (data: any) => {
            console.log("result access token", data.token, data, data.status)
            // this.globalService.setKey("token", data.token.token);
            // this.globalService.setKey("user", data.token.user);
            // this.globalService.setKey("username", data.token.user.username);
            this.isLoggedIn = true
            this.tokenStorage.saveToken(data.token)
            this.tokenStorage.saveUser(data)
            this.tokenStorage.saveCredentials(data)
            localStorage.setItem('hasLoggedIn', 'true')
            localStorage.setItem('language', 'fr')
            localStorage.setItem('state', data.state)
            localStorage.setItem('status', data.status)

            // this.getAllSessions()
            // this.getAllFitnessClass()
            // this.getvideoFav()
            // this.getAllIntensity()
            // this.getAllAgeRange()
            // this.getAllDuration()
            // this.getAllGoals()
            this.getAllSports()
            this.getAllCoach()
            this.getLanguage()
            this.router.navigate(["/home"]);


          },
            (error) => {

              console.log('error', error);

            });
      },
        (error) => {
          console.log('error', error);
          window.location.href = 'https://payment.eklectic.tn/API/oauth/user/authorize?client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&response_type=code&scope=FITNESSBYTT&redirect_uri=https://www.clubfitness.tn/#/home';

        });
  }
  onLanguageChanged(langCode: any) {
    this.selectedLanguage = langCode
  }

 checkIsLoginOrNot(): void {
    if (this.tokenStorage.getUser().roles != undefined ) {
    this.userRole = this.tokenStorage.getUser().roles[1] || 'ROLE_MEMBER';
    console.log('maroua', this.userRole)
    if (this.tokenStorage.getToken()) {
      this.isUserLoggedIn = true;
      console.log('maroua isUserLoggedIn', this.userRole)

      // const expired = this.tokenStorage.getUser().data.expires
      const t = parseInt(moment().format('x'), 10)
      // if (t > expired) {
        if (this.userRole === 'ROLE_MEMBER') {
          this.router.navigate(['/home'])
        } else {
          this.router.navigate(['/home/coach'])

        }
        // this.router.navigate(['/'])
        this.isLoggedIn = true
      // }
    }
    } else {

      this.isUserLoggedIn = false;
      console.log('maroua isUserLoggedIn', this.isUserLoggedIn )
    }
  }


  async openUrl(url:any) {
   
    // var popup = window.open(url, '_blank', 'chrome=yes,centerscreen,width=600,height=800,top=400,left=600,resizeable');
    
    window.location.href = url;

}

openAFQ() {
  console.log("test", this.router.navigateByUrl('/AFQ'))
  this.router.navigateByUrl('/AFQ')
}

getAllSports(): void {
  this.sportService.getSportList().subscribe((data: any) => {
    this.dataStorage.saveData(data, 'sport-data')
  },
    (err:any) => {
      this.commonService.handleResponse(err)
    }
  )
}
getLanguage(): void {
  this.languageService.getLanguage().subscribe(
    (languages: any) => {
      var lang = languages.results;
      var languagaList = []
      for (let i in lang) {
        languagaList.push({
          name: lang[i].name,
          code: lang[i].code.toLowerCase()
        })
      }
      console.log("lang data", lang)
      localStorage.setItem("language-data", JSON.stringify(languagaList));

    },
    (err) => {
      this.commonService.handleResponse(err)

    }
  );
}

getAllCoach(): void {
  this.coachService.getCoachs().subscribe((data: any) => {
    this.coachs = data
    for (const coach of this.coachs) {
      this.coachData.push({
        id: coach.id,
        name: coach.name,
        description: coach.description,
        photo: coach.photo,
      })
    }
    this.dataStorage.saveData(this.coachData, 'coach-data')
    if (localStorage.getItem('coach-data')) {
      window.location.reload()
    }
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )

}

getAllFitnessClass(): void {
  this.fitnessClass.getAll().subscribe((data: any) => {
    const fitnessData = data
    this.favoriteService.getFavoritesByEntity('FitnessClass').subscribe((response: any) => {
      this.favaDta = response
      for (const fav in this.favaDta) {
        this.favArray.push(this.favaDta[fav].foreignKey)
      }
      for (const i in fitnessData) {
        if (this.favArray.includes(fitnessData[i].id)) {
          this.fitnessClassData.push({
            id: fitnessData[i].id,
            name: fitnessData[i].name,
            picture: fitnessData[i].picture,
            mobilePicture: fitnessData[i].mobilePicture,
            content: fitnessData[i].content,
            icon: fitnessData[i].icon,
            color: fitnessData[i].color,
            sportId: fitnessData[i].sportId,
            ageRangeId: fitnessData[i].ageRangeId,
            ageRangeName: fitnessData[i].ageRangeName,
            ageRangeIcon: fitnessData[i].ageRangeIcon,
            intensityId: fitnessData[i].intensityId,
            intensityName: fitnessData[i].intensityName,
            intensityIcon: fitnessData[i].intensityIcon,
            classDurationId: fitnessData[i].classDurationId,
            classDurationName: fitnessData[i].classDurationName,
            classDurationIcon: fitnessData[i].classDurationIcon,
            ratingCount: fitnessData[i].ratingCount,
            ratingAvg: Number(fitnessData[i].ratingAvg),
            fitnessGoals: fitnessData[i].fitnessGoals,
            url: fitnessData[i].name.replaceAll(' ', '-').toLowerCase(),
            favorite: true
          }
          )
        }
        else {
          this.fitnessClassData.push({
            id: fitnessData[i].id,
            name: fitnessData[i].name,
            picture: fitnessData[i].picture,
            mobilePicture: fitnessData[i].mobilePicture,
            content: fitnessData[i].content,
            icon: fitnessData[i].icon,
            color: fitnessData[i].color,
            sportId: fitnessData[i].sportId,
            ageRangeId: fitnessData[i].ageRangeId,
            ageRangeName: fitnessData[i].ageRangeName,
            ageRangeIcon: fitnessData[i].ageRangeIcon,
            intensityId: fitnessData[i].intensityId,
            intensityName: fitnessData[i].intensityName,
            intensityIcon: fitnessData[i].intensityIcon,
            classDurationId: fitnessData[i].classDurationId,
            classDurationName: fitnessData[i].classDurationName,
            classDurationIcon: fitnessData[i].classDurationIcon,
            ratingCount: fitnessData[i].ratingCount,
            ratingAvg: Number(fitnessData[i].ratingAvg),
            fitnessGoals: fitnessData[i].fitnessGoals,
            url: fitnessData[i].name.replaceAll(' ', '-').toLowerCase(),
            favorite: false
          }
          )
        }
      }
      this.dataStorage.saveData(this.fitnessClassData, 'fitness-class-data')
    })
  },

    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getAllIntensity(): void {
  this.fitnessClass.getIntensity().subscribe((data: any) => {
    this.dataStorage.saveData(data, 'intensity-data')
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getAllAgeRange(): void {
  this.fitnessClass.getAgeRange().subscribe((data: any) => {
    this.dataStorage.saveData(data, 'ageRange-data')
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getAllDuration(): void {
  this.fitnessClass.getDuration().subscribe((data: any) => {
    this.dataStorage.saveData(data, 'duration-data')
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getAllGoals(): void {
  this.fitnessClass.getGoals().subscribe((data: any) => {
    this.dataStorage.saveData(data, 'goals-data')
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getvideoFav(): void {
  this.favoriteService.getFavoritesByEntity('Video').subscribe((favData: any) => {
    const favVideo = favData
    for (const i in favVideo) {
      this.getVideoFav.push(favVideo[i].foreignKey)
    }
    const data = {
      ids: this.getVideoFav
    }
    this.videosService.getCoachVideo(data).subscribe(
      async (videos: any) => {
        this.getVideoFav = []
        this.getVideoFav = videos.results
        for (const i in this.getVideoFav) {
          this.videoFav.push(this.getVideoFav[i].id)
        }
        const fav = [this.videoFav]
        fav.push(this.getVideoFav)
        localStorage.setItem('video-fav', JSON.stringify(fav))
      },
      (err) => {
        this.commomService.handleResponse(err)
      }
    )
  },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

getAllSessions(): void {
  this.sessionsService.getAll().subscribe(
    (data: any) => {
      this.dataStorage.saveData(data, 'fitness-session-data')
      this.sessions = data
      this.sessions.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
      for (let i = 0; i < this.sessions.length; i++) {
        this.sessions[i].fitnessClass.picture =
          environment.filesUrl +
          '/' +
          this.sessions[i].fitnessClass.picture
        if (this.sessions[i].fitnessClass.content !== undefined) {
          this.sessions[i].fitnessClass.content = this.sessions[
            i
          ].fitnessClass.content.replaceAll(
            /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
            ''
          )
        }
        this.sessions[i] = this.sessions[i]
        this.currentSession = this.sessions[0]
        const currentDateTime = moment(this.addHoursToDate(new Date(), 1)).format()
        const sessionDateTime = moment(this.sessions[0].startTime).format()
        if (currentDateTime === sessionDateTime) {
          for (let j = 0; j < this.appPages.length; j++) {
            if (this.appPages[j].Name === 'SESSION EN COURS') {
              this.appPages[j].enabled = true
            }
          }
        }
      }
    },
    (err) => {
      this.commomService.handleResponse(err)
    }
  )
}

addHoursToDate(date: Date, hours: number): Date {
  return new Date(new Date(date).setHours(date.getHours() + hours))
}

scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
}
