<div class="home-screen container-fluid" style="background-image: url(assets/bgg.jpg);">
  <div class="home-cover">
      
        <div class="container">
           <div class="row home-detail">
            
              <div class="col-md-7 mx-auto homexp">
                   <h2> WELCOME COACH {{ user?.username }}</h2>

               </div>
           </div>

        </div>
  </div>
</div>



<!-- ######################### About Us Starts Here #################### -->

<div id="about_us" class="about-sestion container-fluid">
 <div class="container">
     <div class="session-title row">
         <h2 style="color: #eefc00;">{{ 'ABOUT' | translate }}</h2>
     </div>
     <div class="about-row row">
         <div class="col-md-5">
             <img src="{{ images }}" style="
             width: 70%;padding: 10%;
         " alt="">
         </div>
         <div class="col-md-7">
          <p style="color: #fff;padding-top: 10%;">{{ profile?.description }}</p>
        </div>
     </div>
 </div>
</div>


<!--*************** My Services Starts Here ***************-->
<section id="services" class="features container-fluid">
<div class="container">
   <div class="row session-title">
       <h2>Mes Vidéos</h2>
   </div>
 

      <p-carousel [value]="videos" [numVisible]="4" [numScroll]="4" [circular]="false"
        [responsiveOptions]="responsiveOptions">
    
        <ng-template let-video pTemplate="item">
          <div *ngIf="video.picture !== undefined " class="main-container_tab" (click)="showVideo(video)"
            style="background-image: url('{{ video.picture }}'); ">
            <div class="duration">
              <i class="fa fa-clock text-light" aria-hidden="true"></i>
              {{ video.Time }}
            </div>
            <div class="product-item-content">
    
              <p class="main-container_tab_author" *ngIf="video.name !== undefined ">{{ video.name }}</p>
            </div>
          </div>
        </ng-template>
      </p-carousel>
</div>
</section>



