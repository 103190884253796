import { Component, OnInit } from '@angular/core'
import { SessionsService } from '../../../services/sessions-coach/sessions.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import * as  moment from 'moment'
import { Calendar } from '@fullcalendar/core' // include this line
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { Favorite, FavoritesResult } from '../../../interfaces/favorite.interface'
import { Like, LikesResult } from '../../../interfaces/like.interface'
import { LikesService } from '../../../services/likes/likes.service'
import { RatesService } from '../../../services/rates/rates.service'
import { ViewsService } from '../../../services/views/views.service'
import { environment } from '../../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router'
import 'moment/locale/fr'

@Component({
  selector: 'app-past-sessions-view',
  templateUrl: './past-sessions-view.component.html',
  styleUrls: ['./past-sessions-view.component.scss'],
})
export class PastSessionsViewComponent implements OnInit {
  public sessions: any = []
  public picture = null
  public loading = false
  public favorites: Favorite[] = []
  public favorite: any = 0
  public likes: Like[] = []
  public like: any = 0
  public rate: any = 0
  public view: any = 0
  public comments: any = 0
  public show = 8
  public contentLoaded = false

  public displayMaximizable = false
  public cours: any
  banner = false
  starList: boolean[] = [true, true, true, true, true]
  rating: any
  constructor(
    public sessionsService: SessionsService,
    public commomService: CommonServices,
    public favoritesService: FavoritesService,
    private likesService: LikesService,
    public viewsService: ViewsService,
    public ratesService: RatesService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getAllSessions()
  }


  getAllSessions() {
    // this.loading = true

    this.sessionsService.getAll().subscribe(
      (data: any) => {
        console.log('data', data)
        const currentTimestamp = Math.floor(new Date().getTime())
        const pastSessions = data.filter(
          (session: any) => Date.parse(session.startTime) < currentTimestamp
        )
        this.sessions = pastSessions
        this.sessions.sort((a: any, b: any) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())

        for (let i = 0; i < pastSessions.length; i++) {
          pastSessions[i].fitnessClass.picture =
            environment.filesUrl +
            '/' +
            pastSessions[i].fitnessClass.picture
          if (pastSessions[i].fitnessClass.content !== undefined) {
            pastSessions[i].fitnessClass.content = pastSessions[
              i
            ].fitnessClass.content.replaceAll(
              /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
              ''
            )
          }
          // .replaceAll("\\<(/?[^\\>]+)\\>", "\\ ").replaceAll("\\s+", " ").trim();
          this.sessions[i] = pastSessions[i]
          moment.locale('fr')
          this.sessions[i]['fromNow'] = moment(pastSessions[i].startTime).fromNow()
        }

        // this.loading = false
        setTimeout(() => {
          this.contentLoaded = true;
        }, 4000);
      },
      (err) => {
        // this.loading = false
        this.commomService.handleResponse(err)
      }
    )
  }

  showSession(cours: any) {
    console.log('cours', cours.id, cours)
    this.router.navigate(['/session/' + cours.id])
  }

}
