import { Injectable } from '@angular/core'
import {
    CalendarSchedulerEvent,
    CalendarSchedulerEventStatus,
    CalendarSchedulerEventAction
} from 'angular-calendar-scheduler'


@Injectable()
export class AppService {
    getEvents(actions: CalendarSchedulerEventAction[]): Promise<CalendarSchedulerEvent[]> {
        const events: any = [ ]

        return new Promise(resolve => setTimeout(() => resolve(events), 3000))
    }
}
