import { Component, OnInit, Input } from '@angular/core'
import { RatesService } from 'src/app/services/rates/rates.service'
import { UserService } from 'src/app/services/user/user.service'
import { User } from 'src/app/interfaces/user.interface'

@Component({
  selector: 'app-review-view',
  templateUrl: './review-view.component.html',
  styleUrls: ['./review-view.component.scss']
})
export class ReviewViewComponent implements OnInit {
  @Input() id: any
  @Input() entity: any
  comments: any = []
  avg = 0
  show = 8
  user: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
  }
  constructor(
    public ratesService: RatesService,
    public userService: UserService

  ) { }

  ngOnInit(): void {
    console.log('id', this.id, this.entity)
    this.getRating()
  }
  getRating(): void {
    this.ratesService.getRatesByEntityforeignKey(this.entity, this.id).subscribe((response: any) => {
      console.log('response', response.avg)

      this.avg = Math.round(response.avg * 10) / 10
      this.comments = response.list
      for (let i = 0; i < this.comments.length; i++) {

        this.userService.getUserById(this.comments[i].userId).subscribe((response: any) => {

          this.user = response

          this.comments[i]['user'] = this.user
        }, (err: any) => {
          console.log('err', err)
        })
      }
    },
      err => {
        console.log('err', err)
      })

  }
}
