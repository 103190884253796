import { Component, OnInit } from '@angular/core'
import { VideosService } from '../../../services/videos/videos.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { RatesService } from 'src/app/services/rates/rates.service'
import { UserService } from 'src/app/services/user/user.service'
import * as  moment from 'moment'
import 'moment/locale/fr'
import { User } from 'src/app/interfaces/user.interface'
import { Router } from '@angular/router'
import { SharedServiceService } from '../../../services/shared-service.service'

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss']
})
export class VideoViewComponent implements OnInit {
  public video: any
  public currentTime = 0
  public displayDateTimeFormat = ''
  user: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
  }
  public show = 8
  public avg = 0
  public loading = false
  public comments: any[] = []
  public entity = 'Video'
  public id: any
  public url: any;
  responsiveOptions: any
  contentLoaded = false
  sportName: any
  public videos: any = []
  public videosFiltreted: any = []
  public categoryVideo: any;
  public videosFiltretedbyId: any = []

  videoSource :any;
  constructor(
    public videosService: VideosService,
    private commonService: CommonServices,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public ratesService: RatesService,
    public userService: UserService,
    public router: Router,
    public sharedService: SharedServiceService,

  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
   }

  ngOnInit(): void {
    moment.locale('fr')
    this.getVideos();
    this.displayDateTimeFormat = this.translate.instant('formats.DATETIME')
    this.loading = true
    this.route.params.subscribe((params: any) => {
      console.log('params', params)
      if (typeof params['video-id'] !== 'undefined') {
        this.id = params['video-id']
        this.url = params['currentUrl']
        this.sportName  = params['sport']
        this.getVideo(parseInt(params['video-id'], 10))
        this.getVideos();

        this.loading = true
      }
      console.log('params', params, params['sport'])

    })

  }

  getVideo(id: number): void {
    this.loading = true
    this.videosService.getVideoById(id).subscribe((response: any) => {
      console.log('response', response)

      if (response !== null) {
        response['fromNow'] = moment(response.createdAt).fromNow()
        response.file = environment.filesUrl + response.file
        response.picture = environment.filesUrl + response.picture
        response.duration = this.commonService.convertTime(response.duration, undefined)
        this.video = response
        this.loading = false
  
      }
    },
      err => {
        this.loading = false
        this.commonService.handleResponse(err)

      })
  }

  goBack() {
    console.log('currentUrl', this.url)

    this.router.navigate([this.url])

}

getVideos() {
  this.loading = true
  const data = {
    
  }
  this.videosService.getCoachVideo(data).subscribe(
    async (videos: any) => {
      this.videos = videos.results
      console.log('this.videos', this.videos)
      this.loading = false
      this.videosFiltreted = []
      this.videosFiltretedbyId = []
      for (let i = 0; i < this.videos.length; i++) {
      
        for (let j = 0; j < this.videos[i].videoCategories.length; j++) {
       
        if(this.videos[i].videoCategories[j].name === this.sportName){
          this.categoryVideo = this.sportName
          this.videosFiltreted.push(this.videos[i]);

          if(this.videos[i].id === parseInt(this.id)){
            this.videosFiltretedbyId.push(this.videos[i]);
            console.log('videosFiltretedbyId', this.videos[i].id, parseInt(this.id), this.videosFiltretedbyId)
  
          }

        }
      
        }
        this.videos[i].file = environment.filesUrl + '/' + this.videos[i].file
        this.videos[i].picture = environment.filesUrl + '/' + this.videos[i].picture
        this.videos[i].duration = this.commonService.convertTime(this.videos[i].duration, undefined)
      
  
      }

      console.log('this.videosFiltreted', this.videosFiltreted, this.videosFiltretedbyId, this.id)

      setTimeout(() => {
        this.contentLoaded = true;
      }, 4000);
    },
    (err:any) => {
      this.loading = false
      this.commonService.handleResponse(err)

    }
  )

}

showVideo(video: any) {
  this.video = video
  console.log('test', this.video.videoCategories[0].name  )
  let sport = this.sportName
  this.router.navigate(['/video/' + video.id , { sport }])
  this.videos.forEach((video: any) => {
    if (this.video.videoCategories[0].name === this.sportName) {
      this.videosFiltreted.push(video)
      console.log('videosFiltreted',this.videosFiltreted)


    }
  })
}

}
