import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class FitnessClassService {

  private uri = `${environment.apiUrl}`
  private controller = `/public-lists/fitness-classes`
  private publicUrl = `/public-lists`
  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getAll(): Observable<any> {
    return this.http.get(this.uri + this.controller, this.httpOptions)
  }

  getFitnessClassById(id: string) {
    return this.http.get(this.uri + this.controller + '/' + id, this.httpOptions)
  }
  getDuration() {
    return this.http.get(this.uri + this.publicUrl + '/class-durations', this.httpOptions)
  }
  getIntensity() {
    return this.http.get(this.uri + this.publicUrl + '/intensities', this.httpOptions)
  }
  getAgeRange() {
    return this.http.get(this.uri + this.publicUrl + '/age-ranges', this.httpOptions)
  }
  getGoals() {
    return this.http.get(this.uri + this.publicUrl + '/fitness-goals', this.httpOptions)
  }


}
