<div class="row mt-5">
  <div *ngIf="!showFav" class="col-sm-3">
    <div class="ms-4">
      <p-panel styleClass="preferencesPanel mb-2" [toggleable]="true">
        <p-header [responsive]="true" >
          <div class="ui-panel-title">
            <label>
              <input type="checkbox" [(ngModel)]="isFitness" (change)="checkUncheckAllFitness()" />
              <span>{{ 'CLASSES' | translate }} ( {{ CoursList.length }} {{ 'SELECTED' | translate }} )</span>
            </label>
          </div>
        </p-header>
        <p-scrollPanel [style]="{height: '180px'}">
          <div class="form-check" *ngFor="let fitness of data[0]" style="padding: 2px;">
            <label>
              <input type="checkbox" [(ngModel)]="fitness.isSelected" value="{{fitness.name}}" id="{{fitness.name}}"
                (change)="isAllSelectedFitness()" name="{{ fitness.name }}" />
              {{fitness.name}}
            </label>
          </div>
        </p-scrollPanel>
      </p-panel>
      <p-panel styleClass="preferencesPanel mb-2" [toggleable]="true">
        <p-header>
          <div class="ui-panel-title">
            <label>
              <input type="checkbox" [(ngModel)]="isCoach" (change)="checkUncheckAllCoachs()" />
              <span> {{ 'COACHES' | translate }} ( {{ CoachList.length }} {{ 'SELECTED' | translate }} )</span>
            </label>
          </div>
        </p-header>
        <p-scrollPanel [style]="{height: '180px'}">
          <div class="form-check" *ngFor="let coach of coachDate">
            <label>
              <input type="checkbox" [(ngModel)]="coach.isSelected" value="{{coach.id}}" id="{{coach.id}}"
                (change)="isAllSelectedCocahs()" name="{{ coach.name }}" />
              {{coach.name }}
            </label>
          </div>
        </p-scrollPanel>
      </p-panel>
      <p-panel styleClass="preferencesPanel mb-2" [toggleable]="true">
        <p-header>
          <div class="ui-panel-title">
            <label>
              <input type="checkbox" [(ngModel)]="isGoal" (change)="checkUncheckAllGoals()" />
              <span>{{ 'GOALS' | translate }} ( {{ GoalList.length }} {{ 'SELECTED' | translate }} )</span>
            </label>
          </div>
        </p-header>
        <p-scrollPanel [style]="{height: '80px'}">
          <div class="form-check" *ngFor="let goal of goals">
            <label>
              <input type="checkbox" [(ngModel)]="goal.isSelected" value="{{goal.id}}" id="{{goal.id}}"
                (change)="isAllSelected()" name="{{ goal.name }}" />
              {{ goal.name }}
            </label>
          </div>
        </p-scrollPanel>
      </p-panel>
      <p-panel styleClass="preferencesPanel mb-2" [toggleable]="true">
        <p-header>
          <div class="ui-panel-title">
            <label>
              <input type="checkbox" [(ngModel)]="isIntensity" (change)="checkUncheckAllIntensitys()" />
              <span>{{ 'INTENSITY' | translate }} ( {{ IntensityList.length }} {{ 'SELECTED' | translate }} )</span>
            </label>
          </div>
        </p-header>
        <p-scrollPanel [style]="{height: '80px'}">
          <div class="form-check" *ngFor="let intensity of intensitys">
            <label>
              <input type="checkbox" [(ngModel)]="intensity.isSelected" value="{{intensity.id}}"
                (change)="isAllSelectedIntensitys()" name="{{ intensity.name }}" id="{{intensity.id}}" />

              {{intensity.name}}
            </label>
          </div>
        </p-scrollPanel>
      </p-panel>
      <p-panel styleClass="preferencesPanel mb-2" [toggleable]="true">
        <p-header>
          <div class="ui-panel-title">
            <label>
              <input type="checkbox" [(ngModel)]="isDuration" (change)="checkUncheckAllDurations()" />
              <span>{{'DURATION' | translate }} ( {{ DurationList.length }} {{ 'SELECTED' | translate }} )</span>
            </label>
          </div>
        </p-header>
        <p-scrollPanel [style]="{height: '170px'}">
          <div class="form-check" *ngFor="let duration of durations">
            <label>
              <input type="checkbox" [(ngModel)]="duration.isSelected" value="{{duration.id}} " id="{{duration.id}}"
                (change)="isAllSelectedDurations()" name="{{ duration.name }}" />
              {{duration.name}}
            </label>
          </div>
        </p-scrollPanel>
      </p-panel>
      <div class="row mt-4 pl-4 mb-4">
        <div class="col ml-2">
          <p-button type="submit" styleClass="p-button" (click)="getSelectedValue()"
            label="{{ 'content.common.APPLY' | translate }}" icon="pi pi-angle-right" iconPos="right"></p-button>
        </div>
        <div class="col ml-2">
          <p-button type="submit" styleClass="p-button" (click)="ResetSearch()"
            label="{{ 'content.common.CANCEL' | translate }}" icon="pi pi-angle-right" iconPos="right"></p-button>
        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-9">
    <div class="container">
      <h3>
        {{ 'CLASSESFITNESSCLUBLIVE' | translate }}
      </h3>
   
    <div class="row">
      <div class="example-1 card"  *ngFor="let video of videos |  paginate: { itemsPerPage:9 , currentPage: p}  ">
        <div class="wrapper"   style="background-image: url('{{fileUrl+video.picture }}'); background-size: cover; background-position: center; ">
      
          <div class="data">
            <div class="content">
              <span class="author">{{video.name}}</span>
              <h1 class="title"><a > {{ video.Time }}</a></h1>
              <!-- <p class="text">
                {{video.description}} 
              </p> -->
              <a (click)="showVideo(video)" class="read-more-btn">Voir cours</a>
              <label for="show-menu" class="menu-button">
                <span>
                  <app-favorite *ngIf="favId.includes(video.id)" [isFav]=true [id]="video.id" [entity]="entity">
                  </app-favorite>
                  <app-favorite *ngIf="!favId.includes(video.id)" [isFav]=false [id]="video.id" [entity]="entity">
                  </app-favorite>
                </span>
              </label>
            </div>
         
          </div>
        </div>
      </div>
   
    </div>






     