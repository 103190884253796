import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CalendarCommonModule } from 'angular-calendar'
import { AuthenticationGuard } from './guards/authentication.guard'
import { ResetPasswordViewComponent } from './views/authentification/reset-password-view/reset-password-view.component'
import { AbonnementViewComponent } from './views/inside-app/abonnement-view/abonnement-view.component'
import { AccountInfoViewComponent } from './views/inside-app/account-info-view/account-info-view.component'
import { CalendarViewComponent } from './views/inside-app/calendar-view/calendar-view.component'
import { CoursViewComponent } from './views/inside-app/cours-view/cours-view.component'
import { HomeViewComponent } from './views/inside-app/home-view/home-view.component'
import { LiveChannelViewComponent } from './views/inside-app/live-channel-view/live-channel-view.component'
import { ProgrammeViewComponent } from './views/inside-app/programme-view/programme-view.component'
import { SportViewComponent } from './views/inside-app/sport-view/sport-view.component'
import { TermsAndConditionsComponent } from './views/inside-app/terms-and-conditions/terms-and-conditions.component'
import { PastSessionsViewComponent } from './views/inside-app/past-sessions-view/past-sessions-view.component'
import { FuturSessionsViewComponent } from './views/inside-app/futur-sessions-view/futur-sessions-view.component'
import { CurrentSessionsViewComponent } from './views/inside-app/current-sessions-view/current-sessions-view.component'
import { NotFoundViewComponent } from './views/inside-app/not-found-view/not-found-view.component'
import { VideosViewComponent } from './views/inside-app/videos-view/videos-view.component'
import { OnlineCoursViewComponent } from './views/inside-app/online-cours-view/online-cours-view.component'
import { VideoViewComponent } from './views/inside-app/video-view/video-view.component'
import { SessionComponent } from './views/inside-app/session/session.component'
import { AFQViewComponent } from './views/inside-app/afq-view/afq-view.component'
import { ContactViewComponent } from './views/inside-app/contact-view/contact-view.component'
import { CoachViewComponent } from './views/inside-app/coach-view/coach-view.component'
import { RegisterViewComponent } from './views/authentification/create-account-view/register-view/register-view.component'
import { PostsViewComponent } from './views/inside-app/posts-view/posts-view.component'
import { PostViewComponent } from './views/inside-app/post-view/post-view.component'
import { VerifpinComponent } from './views/authentification/login-view/verifpin/verifpin.component'
import { CoachsViewComponent } from './views/inside-app/coachs-view/coachs-view.component'
import { ChallengeViewComponent } from './views/inside-app/challenge-view/challenge-view.component'

const routes: Routes = [
  { path: 'auth/member/login', component: HomeViewComponent },
  { path: 'auth/coach/login', component: RegisterViewComponent },
  // { path: 'auth/member/verif-pin', component: VerifpinComponent },
  { path: 'auth', loadChildren: () => import('./modules/primeNg-Implementation.module').then(m => m.PrimeNgImplementation) },
  { path: 'auth/reset-password', component: ResetPasswordViewComponent },
  { path: 'auth/account', component: RegisterViewComponent },
  { path: 'home', component: HomeViewComponent, redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeViewComponent},
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'account', component: AccountInfoViewComponent, canActivate: [AuthenticationGuard] },
  // { path: 'sport', component: SportViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'sport/:name', component: SportViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'live', component: LiveChannelViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'online-cours', component: OnlineCoursViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'cours/:name', component: CoursViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'cours/:name/:session', component: CoursViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'program', component: ProgrammeViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'home/coach', component: AbonnementViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'calendrier', component: CalendarViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'past-sessions', component: PastSessionsViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'futur-sessions', component: FuturSessionsViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'current-sessions', component: CurrentSessionsViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'videos', component: VideosViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'video/:video-id', component: VideoViewComponent,canActivate: [AuthenticationGuard] },
  { path: 'posts', component: PostsViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'post/:post-id', component: PostViewComponent , canActivate: [AuthenticationGuard]  },
  { path: 'session/:session-id', component: SessionComponent,canActivate: [AuthenticationGuard]  },
  { path: 'home', component: HomeViewComponent,canActivate: [AuthenticationGuard]  },
  { path: 'AFQ', component: AFQViewComponent },
  { path: 'contact', component: ContactViewComponent,canActivate: [AuthenticationGuard]  },
  { path: 'coach/:coach-name-:coach-id', component: CoachViewComponent,canActivate: [AuthenticationGuard]  },
  { path: 'coachs', component: CoachsViewComponent, canActivate: [AuthenticationGuard] },
  { path: 'Challenge', component: ChallengeViewComponent,canActivate: [AuthenticationGuard]  },

  // { path: 'error-404', component: NotFoundViewComponent },
  // { path: '**', redirectTo: '/error-404' },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
