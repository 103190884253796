import { Component, OnInit } from '@angular/core'
import { Coach } from 'src/app/interfaces/coach.interface'
import { environment } from 'src/environments/environment.prod'
import { SharedServiceService } from '../../../services/shared-service.service'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { Favorite } from 'src/app/interfaces/favorite.interface'
import { DataStorageService } from '../../../services/data-storage.service'
import { CommonServices } from '../../../services/common/common-service.service'
import { RatesService } from '../../../services/rates/rates.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LikesService } from 'src/app/services/likes/likes.service'
import { Router } from '@angular/router'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import { CoachService } from 'src/app/services/coach/coach.service'

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent implements OnInit {
  favorites: Favorite[] = [{
    id: 0,
    entity: '',
    foreignKey: 0,
  }]
  coachs: Coach[] = []
  coachsId: any = []
  profileImg = false
  responsiveOptions: any
  fileUrl = `${environment.filesUrl}`
  isLogin = false
  isMobile =false
  filterCoach: any
  entity = 'Coach'
  storageName = 'coach-data'
  likeData: any = []
  public userRole: any = null
  atlanticlight: HTMLVideoElement | null = null;
  playpause: HTMLElement | null = null;
  lefttoplay: HTMLElement | null = null;
  righttoplay: HTMLElement | null = null;
  lefttopause: HTMLElement | null = null;
  righttopause: HTMLElement | null = null;
  constructor(
    private router: Router,
    public sharedService: SharedServiceService,
    public favoriteService: FavoritesService,
    public dataStorage: DataStorageService,
    public commonServices: CommonServices,
    public ratingService: RatesService,
    public modalService: NgbModal,
    public likeService: LikesService,
    private tokenStorage: TokenStorageService,
    private coachService: CoachService,

  ) {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
  }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      console.log("mobile device");
      this.isMobile = true;
    }else{
      this.isMobile = false;
      // false for not mobile device
      console.log("not mobile device");
    }
    this.coachService.getCoachs().subscribe((data: any) => {
      this.coachs = data
    
      
    },
      (err:any) => {
        this.commonServices.handleResponse(err)
      }
    )

    if (this.tokenStorage.getUser().roles != undefined ) {
      this.userRole = this.tokenStorage.getUser().roles[1] || 'ROLE_MEMBER';
      console.log('this.userRole', this.userRole)
      this.isLogin = true
    }

  

  }
  // getLike(){
  //   this.likeData = this.sharedService.getLikeService(this.entity)
  // }

  coachProfileNav(name: any, id: any) {

    this.router.navigate(['/coach/' + name.replaceAll(' ', '-').toLowerCase() + '&' + id])
  }

}


