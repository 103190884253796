import { Component, OnInit } from '@angular/core'
import { VideosService } from '../../../services/videos/videos.service'
import { CoachService } from 'src/app/services/coach/coach.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { Router } from '@angular/router'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { LikesService } from '../../../services/likes/likes.service'
import { RatesService } from '../../../services/rates/rates.service'
import { ViewsService } from '../../../services/views/views.service'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-videos-view',
  templateUrl: './videos-view.component.html',
  styleUrls: ['./videos-view.component.scss']
})
export class VideosViewComponent implements OnInit {
  public profile: any
  public videos: any = []
  public loading = false
  public video: any
  public displayMaximizable = false
  public show = 8
  public favorite: any = 0
  public like: any = 0
  public rate: any = 0
  public view: any = 0
  public comments: any = 0
  public contentLoaded = false

  constructor(
    public videosService: VideosService,
    private coachService: CoachService,
    private commonService: CommonServices,
    public favoritesService: FavoritesService,
    private likesService: LikesService,
    public viewsService: ViewsService,
    public ratesService: RatesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log('ngOnInit', 'VideosViewComponent')
    // this.getVideos()
  }

  getVideos() {
    // this.loading = true
    this.coachService.getCoachInfo().subscribe(
      (info: any) => {
        this.profile = info.id
        const data = {
          coaches: [this.profile]
        }
        this.videosService.getCoachVideo(data).subscribe(
          async (videos: any) => {
            this.videos = videos.results
            console.log('this.videos', this.videos)

            // this.loading = false
            for (let i = 0; i < this.videos.length; i++) {
              this.videos[i].file = environment.filesUrl + '/' + this.videos[i].file
              this.videos[i].picture = environment.filesUrl + '/' + this.videos[i].picture
              this.videos[i].duration = this.commonService.convertTime(this.videos[i].duration, undefined)
              if (this.videos[i].totalBookmarks === undefined) {
                this.videos[i]['totalBookmarks'] = 0
              }
              if (this.videos[i].totalComments === undefined) {
                this.videos[i]['totalComments'] = 0
              }
              if (this.videos[i].totalBookmarks === undefined) {
                this.videos[i]['totalBookmarks'] = 0
              }
              if (this.videos[i].totalDislikes === undefined) {
                this.videos[i]['totalDislikes'] = 0
              }
              if (this.videos[i].totalDownloads === undefined) {
                this.videos[i]['totalDownloads'] = 0
              }
              if (this.videos[i].totalHits === undefined) {
                this.videos[i]['totalHits'] = 0
              }
              if (this.videos[i].totalLikes === undefined) {
                this.videos[i]['totalLikes'] = 0
              }
              if (this.videos[i].totalPreviewed === undefined) {
                this.videos[i]['totalPreviewed'] = 0
              }
            
            }
            setTimeout(() => {
              this.contentLoaded = true;
            }, 4000);
          },
          (err) => {
            // this.loading = false
            this.commonService.handleResponse(err)

          }
        )

      },
      (err) => {
        console.log('error', err)
        this.commonService.handleResponse(err)
      }
    )

  }

  showVideo(video: any) {
    this.video = video
    this.router.navigate(['/video/' + video.id])
  }

}
