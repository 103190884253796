import { Component, OnInit, Input } from '@angular/core'
import { DataStorageService } from '../../../../services/data-storage.service'
import { RatesService } from '../../../../services/rates/rates.service'
import { CommonServices } from '../../../../services/common/common-service.service'
import { ModalRatingComponent } from '../../modal-rating/modal-rating.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-public-rating',
  templateUrl: './public-rating.component.html',
  styleUrls: ['./public-rating.component.scss']
})
export class PublicRatingComponent implements OnInit {
  @Input() id: any
  @Input() entity: any
  @Input() rate: any
  @Input() name: any

  constructor(
    public dataStorage: DataStorageService,
    public ratesService: RatesService,
    public commonServices: CommonServices,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  getRatingValue() {
    // on the click of call the modal of rating that depend entity , id and the name of action event
    const modalRef = this.modalService.open(ModalRatingComponent)
    modalRef.componentInstance.data = { entity: this.entity, id: this.id, name: this.name }
  }
}
