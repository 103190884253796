import { Component, OnInit } from '@angular/core'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common'
import { AuthService } from '../../services/auth/auth.service'
import { StaticDataService } from '../../services/static-data/static-data.service'
import { DataStorageService } from 'src/app/services/data-storage.service'
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { Renderer2, ElementRef } from '@angular/core';
import { SharedServiceService } from '../../services/shared-service.service'
import { SportService } from 'src/app/services/sport/sport.service'
import { CommonServices } from 'src/app/services/common/common-service.service'

declare var $: any; // Déclaration de jQuery pour éviter des erreurs de type


@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
})

export class SharedComponent implements OnInit {

  public isLoggedIn = false
  public userRole: any = null
  public appPages: any = []
  public sportsList: any = []
  public baseHef = '/';
  public isToogled = false ;
  public subscription = true;
  public items: MenuItem[];
  public itemsDropdown: SelectItem[] = [];
  public lang = 'fr';
  public selectedItem: string ='' ;
  public isMobile = false
  public url: any = null
  sportActif: any; // Variable pour stocker le sport actif

  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    private authService: AuthService,
    private staticDataService: StaticDataService,
    private dataStorage: DataStorageService,
    private renderer: Renderer2, private el: ElementRef,
    public sharedServiceService: SharedServiceService,
    private sportService: SportService,
    public commonService: CommonServices,


  ) {
    this.items = [];
    for (let i = 0; i < 2; i++) {
        this.itemsDropdown.push({ label: 'Item ' + i, value: 'Item ' + i });
    }

  }
//Sa7booch_Fit2047
  ngOnInit(): void {
    // this.getAllSports();
    console.log('ngOnInit', window.location.href)
    if(this.url = 'http://localhost:4200/#/') {
      this.router.navigate(['/home'])
    }
    this.url = window.location.href
    if (this.url = 'http://localhost:4200/#/auth/member/login') {
      this.subscription = true;
    } else {
      this.subscription = false;
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      console.log("mobile device");
      this.isMobile = true;
    }else{
      // false for not mobile device
      this.isMobile = false;

      console.log("not mobile device");
    }
    this.lang = this.dataStorage.getData('language')
    this.baseHef = document.getElementsByTagName('base')[0].href;
    console.log("this.tokenStorage.getUser()", this.tokenStorage.getUser());

    if (this.tokenStorage.getToken()) {
      this.userRole = this.tokenStorage.getUser().roles[1] 
      console.log("this.tokenStorage.getUser()", this.tokenStorage.getUser());

      const t = parseInt(moment().format('x'), 10)
        if (this.userRole === 'ROLE_MEMBER') {
          this.router.navigate(['/auth/member/login'])
          this.isLoggedIn = true
          this.staticDataService.translateItems().then((items) => {
            console.log('Translated items:', items);
            this.navBarSportToSharedService(items)
            console.log('&this.appPages',this.appPages)
          });
        } else {
          this.router.navigate(['/home/coach'])
          this.isLoggedIn = true
          this.staticDataService.translateItems().then((items) => {
            console.log('Translated items:', items);
            this.navBarSportToSharedService(items)
            console.log('&this.appPages',this.appPages)
          });

        }
        // this.router.navigate(['/'])
     
     
    }

  }

  estActif(sport: string): boolean {
    console.log("sport",sport, sport['label']) 

    return this.sharedServiceService.sportName === sport['label'];
  }

  dropDownToggle(event:any){
    console.log("event",event) 
    this.isToogled = !this.isToogled
  }
  navBarSportToSharedService(items:any) {
    const re = / /gi
    this.sportService.getSportList().subscribe((data: any) => {
      console.log('sport-data',data)

      // this.dataStorage.saveData(data, 'sport-data');
      for (const i in data) {
        this.sportsList.push({ label: data[i].name, routerLink: '/sport/' + data[i].name.replaceAll(re, '-') ,replaceUrl:false})
      }
      items[1]['items'] = this.sportsList;
      console.log('items[1]',items)
      this.appPages = items
      console.log('test', this.appPages)
    },
      (err) => {
        this.commonService.handleResponse(err)
      });
  

  }

  addHoursToDate(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours))
  }

  navigateUrl(url: any, sport: any) {
    console.log('urlSport', sport)
    this.sportActif = sport; // Mettre à jour le sport actif lors de la navigation

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url])
    })
  }

  getAllSports(): void {
    this.sportService.getSportList().subscribe((data: any) => {
      this.dataStorage.saveData(data, 'sport-data');
      
    },
      (err) => {
        this.commonService.handleResponse(err)
      }
    )
  }

  logout() {
    var url = ''
    if (this.userRole === 'ROLE_MEMBER'){
      url = '/auth/member/login';
     
    }else{
      url = '/auth/coach/login';
    }
    const lang = this.dataStorage.getData('language');
    this.authService.logout()
    this.isLoggedIn = false
    localStorage.setItem('language',lang)
    localStorage.setItem("language-data", JSON.stringify([
      {
        "name": "العربية",
        "code": "ar"
      },
      {
        "name": "Français",
        "code": "fr"
      }
    ]));
    this.router.navigate([url])
  }

  async openUrl(url:any) {
   
      // var popup = window.open(url, '_blank', 'chrome=yes,centerscreen,width=600,height=800,top=400,left=600,resizeable');
      
      window.location.href = url;

  }

  openAFQ() {
    console.log("test", this.router.navigateByUrl('/AFQ'))
    this.router.navigateByUrl('/AFQ')
  }

  openRoute(route:any){
    console.log("route",route) 
    // this.router.navigate([route])
    this.router.navigate(['/sport/' + route.name])
  }

  
}
