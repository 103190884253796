<div class="hero" style="background-image: url('https://assets.freeletics.com/images/landing-page/hero-video.jpg')">
  <h1>{{ 'programm.DISCOVERHEALTHYTRAINING' | translate }}<br> {{'programm.NEWPROGRAMM' | translate }}
    <br><br>
    <p> {{ 'programm.TEST' | translate }}
    </p>
  </h1>
  <video autoplay="autoplay" loop="loop" muted="muted" controlsList="nodownload">
    <source src="../../../../assets/images/e-fit-video/hero-video.webm" type="video/webm" />
  </video>
</div>
<div class="div">
  <h2 class="text-center mt-4">
    {{ 'programm.PLANNING' | translate }}
  </h2>

  <app-calendar-view [dataReceiver]="getSport(null)"></app-calendar-view>
  <div class="divider-width">
    <p-divider></p-divider>
  </div>

  <h2 class="text-center mt-4">
    {{ 'programm.SPORT' | translate }}
  </h2>
  <app-sport-list></app-sport-list>
  <!--<div class="divider-width">
    <p-divider></p-divider>
  </div>-->
  <h2 class="text-center mt-4">
    {{ 'programm.DISPO' | translate }}
  </h2>

  <p class="mark-description">
    {{'programm.DESCRIPTION' | translate}}</p>
  <img src="../../../../assets/images/e-fit-marketing/mark5.png" class="img-fluid" alt="Responsive image">
</div>
<br>