import { Component, OnInit } from '@angular/core'
import { DataStorageService } from '../../../services/data-storage.service'

@Component({
  selector: 'app-online-cours-view',
  templateUrl: './online-cours-view.component.html',
  styleUrls: ['./online-cours-view.component.scss']
})
export class OnlineCoursViewComponent implements OnInit {
  fitnessData: any = []
  constructor(
    public dataStorage: DataStorageService
  ) { 
    
  }

  ngOnInit(): void {
    this.fitnessData = JSON.parse(this.dataStorage.getData('fitness-class-data'))

  }
  getData(fitnessData: any) {
    const data = [fitnessData]
    return data
  }
  getSport(id: any) {
    const data = [id, 'all']
    return data
  }
}
