import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'



@Injectable({
  providedIn: 'root'
})
export class UserService {
  private uri = `${environment.apiUrl}`

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }
  getUserClaims() {
    return this.http.get(this.uri + `/profile`,  this.httpOptions)
  }
  patchProfile(data: any): Observable<any> {
    return this.http.put(this.uri + '/profile', data, this.httpOptions)
  }
  getCountry() {
    return this.http.get(this.uri + `/public-lists/countries`, this.httpOptions)
  }
  getCity() {
    return this.http.get(this.uri + `/public-lists/cities`, this.httpOptions)
  }
  getLanguage() {
    return this.http.get(this.uri + `/public-lists/languages`, this.httpOptions)
  }
  postFile(fileToUpload: File) {
    const endpoint = this.uri + `/filer/upload`
    const formData: FormData = new FormData()
    formData.append('file', fileToUpload)
    formData.append('path', 'users')
    return this.http
      .post(endpoint, formData, this.httpOptions)
  }
  patchPicture(data: any): Observable<any> {
    return this.http.put(this.uri + '/profile',
      {
        gender: data.gender,
        birthDate: data.birthDate,
        phone: data.phone,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        picture: data.picture,
        address: data.address,
        zipCode: data.zipCode,
        job: data.job,
        country: data.countryId,
        city: data.cityId,
        language: data.languageId,
      }
      ,  this.httpOptions)
  }

  getUserById(id: number) {
    return this.http.get(this.uri + '/users' + '/' + id,  this.httpOptions)

  }
}
