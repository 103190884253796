import { Component, OnInit } from '@angular/core'
import { PostsService } from '../../../services/posts/posts.service'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { TranslateService } from '@ngx-translate/core'
import { DataStorageService } from '../../../services/data-storage.service'

@Component({
  selector: 'app-posts-view',
  templateUrl: './posts-view.component.html',
  styleUrls: ['./posts-view.component.scss']
})
export class PostsViewComponent implements OnInit {

  loading = false
  fitnessData: any[] = []
  posts: any[] = []
  public displayDateTimeFormat = ''
  public displayDateFormat = ''
  public displayTimeFormat = ''
  p = 0
  constructor(

    private postsService: PostsService,
    private router: Router,
    private commonService: CommonServices,
    public translate: TranslateService,
    private dataStorage: DataStorageService,


  ) { }
  ngOnInit() {
    this.displayDateTimeFormat = this.translate.instant('formats.DATETIME')
    this.displayDateFormat = this.translate.instant('formats.DAY')
    this.displayTimeFormat = this.translate.instant('formats.MONTH')
    this.getPosts()
    this.fitnessData = JSON.parse(this.dataStorage.getData('fitness-class-data'))
  }


  getPosts() {
    const data = {
      // categories: '',
      // subCategories: ''
      limit : 0,
      offset : 100
    }
    this.loading = true
    this.postsService.getAll().subscribe(
      (posts: any) => {
        this.posts = posts.results
        console.log('Posts PostCardComponent', this.posts)

        this.loading = false
        for (let i = 0; i < this.posts.length; i++) {
          this.posts[i].file = environment.filesUrl + '/' + this.posts[i].file
          this.posts[i].picture = environment.filesUrl + '/' + this.posts[i].picture
          if (this.posts[i].content !== undefined) {
            this.posts[i].content = this.posts[i].content.replace(
              /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
              ''
            )
          }
        }
      },
      (err) => {
        console.log('Posts', err)
        this.loading = false
        this.commonService.handleResponse(err)
      }
    )
  }

  showPost(post: any) {
    console.log('post', post.id, post)
    this.router.navigate(['/post/' + post.id])
  }
}
