<div class="d-flex justify-content-center" *ngIf="sessions?.length === 0">
  <section class="calendar">
    <p class="no-program mb-5"> <i class="fas fa-clock"></i> &nbsp; {{'programm.NOCALENDAR' | translate}} </p>
    <p-fullCalendar [events]="eventsCalendar" [options]="optionsCalendar"></p-fullCalendar>
  </section>
</div>
<div class="d-flex justify-content-center" *ngIf="sessions?.length> 0">
  <section class="calendar">
    <p-fullCalendar #calendar [options]="options" (click)="eventClicked($event)"></p-fullCalendar>
    <!-- The Modal -->
    <div class="modal" id="successModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
      aria-hidden="true" [style.display]="showModal ? 'block' :
    'none'">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLongTitle">{{filterSession[0]?.name}} </h3>
            <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
          </div>
          <div class="modal-body" *ngIf="showModal">
            <div class='container mt-4'>
              <div class='row'>
                <div class='col'>
                  <div class="container">
                    <div class="center-postion">
                      <img class="image-details" src="{{fileUrl+filterSession[0].mobilePicture}}">
                    </div>
                    <div class="middle">
                      <app-favorite [isFav]="selectedFav" [id]="filterSession[0].id" [entity]="entity">
                      </app-favorite>
                    </div>
                  </div>
                  <div class="card-text d-flex justify-content-center">
                    <h4 class="text-center">
                      {{filterSession[0].fitnessName}}
                    </h4>
                  </div>
                  <br>
                  <div class="text-center">
                    <app-public-reaction [likeAction]="selectedLike" [entity]="entity" [disLikeAction]=false
                      [id]="filterSession[0].id" [name]="filterSession[0].name"></app-public-reaction>
                    <div class="rating-position">
                      <app-public-rating [id]="filterSession[0].id" [entity]="entity" [rate]="ratingvalue"
                        [name]="filterSession[0].name">
                      </app-public-rating>
                    </div>
                  </div>
                </div>
                <div class='col'>
                  <div *ngIf="filterSession[0]?.content !== undefined"
                    [innerHTML]="filterSession[0]?.content.split('<p')[0]" class="session-details">
                  </div>
                  <ul class="benefices">
                    <li class="benefices__item" *ngIf="filterClass[0]?.classDurationIcon !== undefined">
                      <img src="{{fileUrl+filterClass[0]?.classDurationIcon}}" class="benefices__img"
                        alt="pictogramme DURÉE : 50 MINUTES">

                      <span *ngIf="filterClass[0]?.classDurationIcon !== undefined" class="benefices__label">{{
                        'DURATION' | translate }} :
                        {{filterClass[0]?.classDurationName}} {{ 'MINUTES' | translate }}</span>
                    </li>
                    <li class="benefices__item" *ngIf="filterClass[0]?.intensityIcon !== undefined">
                      <img src="{{fileUrl+filterClass[0]?.intensityIcon}}" class="benefices__img"
                        alt="pictogramme INTENSITÉ MOYENNE">
                      <span class="benefices__label">{{filterClass[0]?.intensityName}}</span>
                    </li>
                    <li class="benefices__item" *ngIf="filterClass[0]?.ageRangeIcon !== undefined">
                      <img src="{{fileUrl+filterClass[0]?.ageRangeIcon}}" class="benefices__img"
                        alt="pictogramme À PARTIR DE 15 ANS">
                      <span class="benefices__label">{{filterClass[0]?.ageRangeName}}</span>
                    </li>
                  </ul>
                  <br><br>
                  <div class="container">
                    <div class="row">
                      <div class="col-3">
                        <div class="image"
                          [ngStyle]="{'background-image': coach[0]?.photo !== undefined ? 'url('+fileUrl+coach[0]?.photo+')' : 'url(\'assets/images/e-fit-default-image/coach.png\')'}">
                        </div>
                      </div>
                      <div class="col-6">
                        <p>{{coach[0]?.name}} : <br>
                          {{coach[0]?.description}} </p>
                      </div>
                    </div>
                  </div>
                  <div class="buttons center">
                      <div class="col-12">
                        <button (click)="DeleteReservation(filterSession[0].id)" pButton pRipple type="button"
                          icon="pi pi-times" class="btn-reservation"
                          *ngIf="reservationIdArray.includes(filterSession[0].id)"
                          label="{{ 'DELETEDRESERVATION' | translate }}"></button>
                        <button (click)="reserveSession(filterSession[0].id, filterSession[0])" pButton pRipple
                          type="button" icon="pi pi-check" class="reservation"
                          *ngIf="!this.reservationIdArray.includes(filterSession[0].id)"
                          label="{{ 'RESERVE' | translate }}"></button>

                    </div>
                  </div>
                  <div class="center-postion">
                    <div class="container text-center">
                      <div class="row">
                        <div class="col-6">
                          <button pButton pRipple type="button" class="p-button-raised p-button-warning btn-add"
                            label="{{ 'ADDCALENDARGOOGLE' | translate }}" (click)="navigateAgendaGoogle()"></button>
                        </div>
                        <div class="col-6">
                          <button pButton pRipple type="button" class="p-button-raised p-button-warning btn-add"
                            label="{{ 'DOWNLOAD' | translate }}" (click)="navigateToOutlook()"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="center-postion">
                        <button pButton pRipple type="button"
                          (click)="detailsNavigation(filterSession[0].fitnessName,filterSession[0].name)"
                          label="{{ 'VIEWMORE' | translate }}" class="btn-plus"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <p-confirmDialog *ngIf="showSpecModal" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hide()"></button>
        </div>
      </div>
    </div>
  </section>
</div>
<p-confirmDialog header="" *ngIf="!showSpecModal" [style]="{width: '50vw'}" [baseZIndex]="10000" class="text-center">
</p-confirmDialog>