<main>
    <!--? Hero Start -->
    <div class="slider-areaV">
        <div class="slider-height2 d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap hero-cap2 pt-70">
                            <div class="hero__caption">
                                <div class="sp-container">
                                    <div class="sp-content">
                                        <h2 class="frame-1">{{ 'home.TITLE' | translate}}</h2>
                                        <h2 class="frame-2">{{ 'home.DEFINITION' | translate}}</h2>
                                        <h2 class="frame-3">{{ 'home.OURCOCHS' | translate}}</h2>
                                        <h2 class="frame-4">{{ 'home.GUIDE' | translate}}</h2>
                                        <h2 class="frame-5">
                                            <span>FORK,</span>
                                            <span>CHANGEMENT,</span>
                                            <span>EXPÉRIENCE.</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->
    <!--? Blog Area Start-->
    <section class="blog_area section-padding">
        <div class="container">
          
            <div class="row" >

                <div class="column p-4" *ngFor="let coach of coachs">
                    <div class="card">
                        <div class="content">
                            <div class="front">
                                <img class="profile" width="100%"
                                    src="{{fileUrl+coach?.photo}}"
                                    alt="coach">
                                <h2 style="color: black;">{{coach.name}}</h2>
                            </div>
                            <div class="back from-left">
                                <!-- <h2>{{coach.name}}</h2> -->
                                <div class="middle">
                                    <app-favorite [isFav]="coach.favorite" [id]="coach.id" [entity]="entity"
                                      [storageName]="storageName"></app-favorite>
                                  </div>
        
                              <br>
                              <h3>{{coach.description}}</h3>
                              <div class="reaction-position">
                                <app-public-reaction  *ngIf="likeData.includes(coach.id) " [likeAction]= true [disLikeAction]=false
                                  [id]="coach.id" [entity]="entity" [name]="coach.name"></app-public-reaction>
                                  <app-public-reaction  *ngIf="!likeData.includes(coach.id)" [likeAction]= false [disLikeAction]=false
                                  [id]="coach.id" [entity]="entity" [name]="coach.name"></app-public-reaction>
                              </div>
                              <app-public-rating [id]="coach.id" [entity]="entity" [rate]="coach.ratingTotal"
                                [name]="coach.name"></app-public-rating>
      
                            </div>
                        </div>
                    </div>
                   
                </div>

             


            </div>
         
        </div>

    </section>
    <!-- Blog Area End -->


</main>