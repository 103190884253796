<!-- <app-loader></app-loader>

<div *ngIf="showNavbar" >
  <app-shared></app-shared>
</div>
<div>
  <router-outlet></router-outlet>
</div>
<app-footer-section *ngIf="showNavbar"></app-footer-section> -->
<div *ngIf="showNavbar">
 
  <app-shared></app-shared> 
</div>
<div>
  <div id="preloader-active">
    <div class="preloader d-flex align-items-center justify-content-center">
        <div class="preloader-inner position-relative">
            <div class="preloader-circle"></div>
            <div class="preloader-img pere-text">
                <img src="assets/img/logo/loder.png" alt="">
            </div>
        </div>
    </div>
</div>

  <router-outlet></router-outlet>
</div>

<app-footer-section *ngIf="showFooter" ></app-footer-section> 
<div id="back-top" *ngIf="showNavbar">
  <a title="Go to Top" (click)="scrollToTop()"> <i class="fa fa-arrow-up" style="color: black;"></i></a>


</div>