
<div class="container">
    <div class="row">
      <div class="col-md-8 course-details-content">
        <div class="course-details-card mt--40">
          <div class="course-content">
            <h5 class="mb--20">Les avis</h5>
            <div class="row row--30">
              <div class="col-lg-4">
                <div class="rating-box">
                  <div class="rating-number">{{ avg }}/5</div>
                  <div class="rating"> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> </div>
                  <span>{{ comments.length }} {{ 'REVEIWS' | translate }}</span> </div>
              </div>
              <div class="col-lg-8">
                <div class="review-wrapper">
                    <dl class="reviews-display__gauges-container">
                        <dt class="reviews-display__gauge-label">5 {{ 'STARS' | translate }} </dt>
                        <dd class="reviews-display__gauge-wrapper">
                            <i class="fa fa-star fa-2x rating"></i>
                            <progress *ngIf="avg === 5" min="0" value="5" max="5" class="reviews-display__gauge"></progress>
                            <progress *ngIf="avg !== 5" min="0" value="0" max="5" class="reviews-display__gauge"></progress>
            
                        </dd>
                        <dt class="reviews-display__gauge-label">4 {{ 'STARS' | translate }}</dt>
                        <dd class="reviews-display__gauge-wrapper">
                            <i class="fa fa-star fa-2x rating"></i>
            
                            <progress *ngIf="avg === 4" min="0" value="4" max="5" class="reviews-display__gauge"></progress>
                            <progress *ngIf="avg !== 4" min="0" value="0" max="5" class="reviews-display__gauge"></progress>
                        </dd>
                        <dt class="reviews-display__gauge-label">3 {{ 'STARS' | translate }}</dt>
                        <dd class="reviews-display__gauge-wrapper">
                            <i class="fa fa-star fa-2x rating"></i>
                            <progress *ngIf="avg === 3" min="0" value="3" max="5" class="reviews-display__gauge"></progress>
                            <progress *ngIf="avg !== 3" min="0" value="0" max="5" class="reviews-display__gauge"></progress>
                        </dd>
                        <dt class="reviews-display__gauge-label">2 {{ 'STARS' | translate }}</dt>
                        <dd class="reviews-display__gauge-wrapper">
                            <i class="fa fa-star fa-2x rating"></i>
            
                            <progress *ngIf="avg === 2" min="0" value="2" max="5" class="reviews-display__gauge"></progress>
                            <progress *ngIf="avg !== 2" min="0" value="0" max="5" class="reviews-display__gauge"></progress>
                        </dd>
                        <dt class="reviews-display__gauge-label">1 {{ 'STAR' | translate }}</dt>
                        <dd class="reviews-display__gauge-wrapper">
                            <i class="fa fa-star fa-2x rating"></i>
            
                            <progress *ngIf="avg === 1" min="0" value="1" max="5" class="reviews-display__gauge"></progress>
                            <progress *ngIf="avg !== 1" min="0" value="0" max="5" class="reviews-display__gauge"></progress>
                        </dd>
                    </dl>
             
                </div>
              </div>
            </div>
            <div class="comment-wrapper pt--40">
              <div class="section-title">
                <h5 class="mb--25">Commentaires</h5>
              </div>
                <!--  Comment Box start--->
              <div class="edu-comment" *ngFor="let comment of comments ">
                <div class="thumbnail"> <img src="assets/images/e-fit-default-image/user-default.png" alt="Comment Images"> </div>
                <div class="comment-content">
                  <div class="comment-top">
                    <h6 class="title">{{ comment.user?.firstName }} {{ comment.user?.lastName }}</h6>
                    <div class="rating"> 
                        <ngb-rating [(rate)]="comment.value" readonly="true" max="5" class="rating"></ngb-rating>

                    </div>
                  </div>
                  <span class="subtitle">“ {{ comment.createdAt | date: displayDateTimeFormat }} ”</span>
                  <p>{{ comment.body }}</p>
                </div>
              </div>
                <!-- Comment Box end
                       
                    
                    --->
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>