<header class="header-picture" *ngIf="currentSession === undefined">
  <nav>
    <div class="row"></div>
  </nav>
  <div class="text-box">
    <section class="text">{{ 'NOSESSIONS' | translate }}</section>
  </div>
</header>

<header class="header-picture" *ngIf="currentSession !== undefined">

  <div class="row" *ngIf="secondsToDday > 0">
    <span>{{ 'STARTCLASS' | translate }} {{daysToDday}} {{'DAY' | translate }}
      {{hoursToDday}}h:{{minutesToDday}}m:{{secondsToDday}}s
    </span>
  </div>
  <div class="row" *ngIf="isLive && secondsToDday <= 0">
    <span>{{ 'STARTCLASSSESSION' | translate }} {{ currentSession.fromNow }}
    </span>
  </div>

  <div class="row" *ngIf="currentSession !== null">
    <span>
      {{ currentSession.fitnessClass.name }} {{ 'THE' | translate }}
    {{ currentSession.startTime | date: displayDateTimeFormat }}
    </span>
    <span>
      <h4>{{ 'STARTSTREAM' | translate}}<br/>
        {{ 'SESSION_ID' | translate }} : FitnessSession_{{ currentSession.id }}
      </h4>
    </span>

  </div>

  <div class="row">
    <div class="col card-icon" (click)="help()">
      <i class="fab fa-ubuntu fa-4x text-light mt-3 mr-3"></i>
      <i class="fab fa-windows fa-4x text-light mt-3 mr-3"></i>
      <i class="fab fa-apple fa-4x text-light mt-3"></i><br>
      <br>
      <h6 class="mt-3 text-light mt-3">{{ 'URLDOWNLOADWEB' | translate }}</h6>
    </div>
    <div class="col card-icon" (click)="helpForAndroid()">
      <i class="fab fa-android fa-4x text-light mt-3"></i><br>
      <h6 class="mt-3 text-light">{{ 'URLDOWNLOADANDROID' | translate }}</h6>
    </div>
    <div class="col card-icon" (click)="helpForIos()">
      <i class="fab fa-apple fa-4x text-light mt-3"></i><br>
      <h6 class="mt-3 text-light">{{ 'URLDOWNLOADIOS' | translate }}</h6>
    </div>
  </div>


  <div class="row">
    <p-button type="submit" styleClass="p-button-lg" label="Start live " icon="pi pi-angle-right" iconPos="right"
      (click)="startStreaming()">
    </p-button>
  </div>


</header>

<p-carousel [value]="sessions" [numVisible]="4" [numScroll]="4" [circular]="false"
  [responsiveOptions]="responsiveOptions" *ngIf="currentSession !== undefined">
  <ng-template pTemplate="header">
    <h1 class="direct-text">{{ 'DIRECTTOCOME' | translate }}</h1>
  </ng-template>
  <ng-template let-session pTemplate="item">
    <div class="main-container_tab picture" (click)="showSession(session)"
      style="background-image: url('{{ session.fitnessClass.picture }}'); border-bottom: 4px solid {{ session.fitnessClass.color}}">
      <div class="product-item-content">
        <div class="main-container_tab_datetitle">
          <div class="main-container_tab_date">
            {{ 'THE' | translate }} {{ session.startTime | date: "shortDate" }} {{ 'AT' | translate }}
            {{ session.startTime | date: "shortTime" }}
          </div>
        </div>
        <p class="main-container_tab_author" *ngIf="session.fitnessClass.name != undefined">{{ session.fitnessClass.name
          }}</p>
      </div>
    </div>
  </ng-template>
</p-carousel>


<p-dialog header="{{ 'HELPTITLE' | translate}}" [(visible)]="displayMaximizable" [modal]="true"
  [style]="{width: '50vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
  <h2 *ngIf="isWeb">{{ 'WOWZAFORWEB' | translate }}</h2>
  <h2 *ngIf="isWeb">{{ 'LIENFORWEB' | translate }}
    <a href="http://obsproject.com/download">{{ 'WEB' | translate}} </a>
  </h2>
  <h2 *ngIf="isAndroid">{{ 'WOWZAFORANDROID' | translate }}</h2>
  <h2 *ngIf="isAndroid">{{ 'LIENFORANDROID' | translate }}
    <a href=" https://play.google.com/store/apps/details?id=com.wmspanel.larix_broadcaster&hl=fr&gl=US">{{ 'ANDROID' |
      translate}} </a>
  </h2>
  <h2 *ngIf="isIos">{{ 'WOWZAFORIOS' | translate }}</h2>
  <h2 *ngIf="isIos">{{ 'LIENFORIOS' | translate}}
    <a href="https://apps.apple.com/us/app/larix-broadcaster/id1042474385">{{ 'IOS' | translate}} </a>
  </h2>
  <div class='container mt-4'>
    <p-carousel [value]="helpsForMobile" [numVisible]="3" [numScroll]="3" [circular]="false"
      [responsiveOptions]="responsiveOptions" *ngIf="isAndroid || isIos">
      <ng-template let-help pTemplate="item">
        <div class="help-picture" style="background-image: url('{{ help.picture }}');">
        </div>
      </ng-template>
    </p-carousel>
    <span> {{ 'WITHSCAN' | translate }}</span>
    <p-carousel [value]="helpsForMobiles" [numVisible]="3" [numScroll]="3" [circular]="false"
      [responsiveOptions]="responsiveOptions" *ngIf="isAndroid || isIos">
      <ng-template let-help pTemplate="item">
        <div class="help-picture" style="background-image: url('{{ help.picture }}');">
        </div>
      </ng-template>
    </p-carousel>

    <p-carousel [value]="helpsForWeb" [numVisible]="3" [numScroll]="3" [circular]="false"
      [responsiveOptions]="responsiveOptions" *ngIf="isWeb">
      <ng-template let-help pTemplate="item">
        <div class="help-picture" style="background-image: url('{{ help.picture }}');">
        </div>
      </ng-template>
    </p-carousel>
  </div>
</p-dialog>
<!-- The Modal -->
<div class="modal" id="successModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true" [style.display]="showModal ? 'block' :
'none'" *ngIf="streamingData !== undefined">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLongTitle">{{'AUTOCONFIG' | translate }} </h3>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="item mr-3">{{ 'USERNAME' | translate}} </div>
          <div class="item flex-grow-1 h5">{{streamingData.user.username}}</div>
          <div class="item  ml-auto h5 text-primary"><i class="fa fa-copy"
              (click)="copyText(streamingData.user.username)"></i></div>
        </div>
        <div class="d-flex">
          <div class="item mr-3">{{ 'login.PASSWORD' | translate}} </div>
          <div class="item flex-grow-1 h5">{{streamingData.user.password}}</div>
          <div class="item  ml-auto h5 text-primary"><i class="fa fa-copy"
              (click)="copyText(streamingData.user.password)"></i></div>
        </div>
        <div class="d-flex">
          <div class="item mr-3">{{ 'STREAMNAME' | translate}} </div>
          <div class="item flex-grow-1 h5">{{streamingData.fitnessSession.pushStreamName}}</div>
          <div class="item  ml-auto h5 text-primary"><i class="fa fa-copy"
              (click)="copyText(streamingData.fitnessSession.pushStreamName)"></i></div>
        </div>
        <div class="d-flex">
          <div class="item mr-3">{{ 'PUBLICSTREAMURL' | translate}} </div><br />
          <div class="item flex-grow-1 h5">{{streamingData.fitnessSession.publicStreamUrl}}</div>
          <div class="item  ml-auto h5 text-primary" (click)="copyText(streamingData.fitnessSession.publicStreamUrl)"><i
              class="fa fa-copy"></i></div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="item item-pink">{{ 'SCANQR' | translate }}</div>
        </div>
        <div class="row">
          <ngx-qrcode [elementType]="elementType" [value]="value" cssClass="aclass" errorCorrectionLevel="L">
          </ngx-qrcode>
        </div>
        <div class="d-flex justify-content-center">
          <div class="item item-pink">{{ 'OR' | translate }}</div>
        </div>
        <div class="d-flex justify-content-center">
          <p-button type="submit" styleClass="p-button-lg" label="{{ 'COPYLINK' | translate }}" icon="pi pi-copy"
            iconPos="right" (click)="copyText(value)">
          </p-button>
        </div>
      </div>

    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hide()"></button>
    </div>
  </div>
</div>