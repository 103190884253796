import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class MemberServiceService {
  private uri = `${environment.apiUrl}`

  constructor(private http: HttpClient) { }

  getMemberInfo(){
    return  this.http.get(this.uri + `/public-member-actions`)
   }
   putMemberInfo(data: any): Observable<any> {
    return this.http.put(this.uri + '/public-member-actions', data, httpOptions)
  }

  addMember(data: any): Observable<any> {
    return this.http.post(this.uri + '/members', data, httpOptions)
  }
  }

