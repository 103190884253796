<header *ngIf="!isMobile">
  <!-- Header Start -->
  <div class="header-area header-transparent">
    <div class="main-header header-sticky">
      <div class="container-fluid">
        <div class="menu-wrapper d-flex align-items-center justify-content-between">
          <!-- Logo -->
          <div class="logo" routerLink="/home">
            <img src="assets/img/logo/logo.png" style="height: 75px;" alt="">
          </div>
          <!-- Main-menu -->
          <div class="main-menu f-right d-none d-lg-block">
          
            <nav>
           

              <ul id="navigation" *ngIf="userRole ==='ROLE_MEMBER' && isLoggedIn">

                <li><a routerLink="/home">Accueil</a></li>

          
                <li *ngFor="let sport of sportsList | slice:0:2">
                  <a (click)="navigateUrl(sport.routerLink)" *ngIf="!estActif(sport)">
                    {{ sport.label }}
                  </a>
                  <a [routerLink]="['/' + sport]" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact: true}" *ngIf="estActif(sport)">
                    <span style="color: #eefc00;">{{ sport.label }}</span>

                  </a>
                </li>

                <li><a> Bien-être </a>
                  <ul class="submenu">
                    <li *ngFor="let sport of sportsList  | slice:2:4">
                        <!-- <a (click)="navigateUrl(sport.routerLink)">{{
                            sport.label }}</a> -->

                        <a (click)="navigateUrl(sport.routerLink)" *ngIf="!estActif(sport)">
                          {{ sport.label }}
                              </a>
                          <a [routerLink]="['/' + sport]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                              *ngIf="estActif(sport)" >
                              <span style="color: #eefc00;">{{ sport.label }}</span>

                          </a>
                    </li>

                  </ul>
                </li>
                <li *ngFor="let sport of sportsList | slice:4:7">
                
           
                    <a (click)="navigateUrl(sport.routerLink)" *ngIf="!estActif(sport)">
                      {{ sport.label }}
                          </a>
                      <a [routerLink]="['/' + sport]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
                          *ngIf="estActif(sport)" >
                          <span style="color: #eefc00;">{{ sport.label }}</span>
      
                       </a>
                </li>
                <li><a routerLink="/Challenge">Challenge</a></li>

                <li *ngIf="isLoggedIn"><a routerLink="/account"><i class="fas fa-user-circle" style="
                                font-size: xx-large;"></i></a></li>


              </ul>
            </nav>


          </div>

          <!-- Mobile Menu -->
          <div class="col-12">
            <div class="mobile_menu d-block d-lg-none"></div>
          </div>
        </div>
      </div>
    </div>



  </div>
  <!-- Header End -->





</header>






<nav class="navbar navbar-dark bg-dark" *ngIf="isMobile">
  <a class="navbar-brand" href="/home">
    <div class="logo" routerLink="/home">
      <img src="assets/img/logo/logo.png" style="height: 75px;" alt="">
    </div>
  </a>

  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" style="border: 3px solid;"
    data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav" *ngIf="isLoggedIn  && userRole ==='ROLE_MEMBER'">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/home">Accueil<span class="sr-only">(current)</span></a>
      </li>

      <li class="nav-item" *ngFor="let sport of sportsList | slice:0:2">
        <a class="nav-link" (click)="navigateUrl(sport.routerLink)">{{ sport.label }}</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Bien-être
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" (click)="navigateUrl(sport.routerLink)"
            *ngFor="let sport of sportsList | slice:2:4">{{ sport.label }}</a>

        </div>
      </li>





      <li class="nav-item" *ngFor="let sport of sportsList | slice:4:7">
        <a class="nav-link" (click)="navigateUrl(sport.routerLink)">{{ sport.label }}</a>
      </li>
      <li class="nav-item" ><a class="nav-link" routerLink="/Challenge">Challenge</a>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn"><a class="nav-link" routerLink="/account">Profil</a>
      </li>

    </ul>
  </div>
</nav>