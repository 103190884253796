import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[disableDownload]'
})
export class DisableDownloadDirective {

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: Event) {
    event.preventDefault();
  }

  @HostListener('document:keydown.control.s', ['$event'])
  onCtrlS(event: KeyboardEvent) {
    event.preventDefault();
  }
}
