import { Component, OnInit } from '@angular/core'
import { SharedServiceService } from '../../../services/shared-service.service'
import { Subscription } from 'rxjs'
import { environment } from 'src/environments/environment.prod'
import { DataStorageService } from '../../../services/data-storage.service'
import { VideosService } from '../../../services/videos/videos.service'
import { ActivatedRoute, Router } from '@angular/router'
import { RatesService } from 'src/app/services/rates/rates.service'
import { UserService } from 'src/app/services/user/user.service'
import { User } from 'src/app/interfaces/user.interface'
import { ModalCommentsComponent } from '../../inside-app/modal-comments/modal-comments.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import * as  moment from 'moment'
import { FitnessClass } from '../../../interfaces/fitness-class.interface'
import { CommonServices } from 'src/app/services/common/common-service.service'

@Component({
  selector: 'app-cours-view',
  templateUrl: './cours-view.component.html',
  styleUrls: ['./cours-view.component.scss']
})
export class CoursViewComponent implements OnInit {
  entity = 'FitnessClass'
  coursObject: any
  subscription!: Subscription
  fileUrl = `${environment.filesUrl}`
  filterClass: any
  CoursName: any
  sessionDetails = false
  public defaultParameters: { [param: string]: string | string[] } = { offset: '0', limit: '1000' }
  public videos: any = []
  public loading = false
  public video: any
  responsiveOptions: any
  public comments: any[] = []
  user: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
  }

  constructor(private data: SharedServiceService,
    public dataStorage: DataStorageService,
    public videosService: VideosService,
    private router: Router,
    public ratesService: RatesService,
    public userService: UserService,
    public modalService: NgbModal,
    public commonService: CommonServices


  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
  }

  ngOnInit() {
    this.CoursName = this.data.getsportName()
    const FitnessClassLocalStorage = JSON.parse(this.dataStorage.getData('fitness-class-data'))
    if (this.CoursName.includes('%2B')) {
      this.CoursName = this.CoursName.replace('%2B', '+')
    }
    this.filterClass = FitnessClassLocalStorage.filter((x: any) => x.name === this.CoursName.toUpperCase())
    this.getRating(this.filterClass[0].id, this.entity)
    this.getVideos()
    this.filterClass = this.filterClass[0]
  }

  getCours(id: any) {
    const data = [id, 'per-fitness-class', true]
    return data
  }

  getVideos() {
    // get videos with filter
    const data = {
      fitnessClasses: [this.filterClass[0].id],
      fitnessSessions: [],
      sports: [],
      coaches: [],
      intensities: [],
      classDurations: [],
      fitnessGoals: []
    }

    this.videosService.getCoachVideo(data).subscribe(
      async (videos: any) => {
        this.videos = videos.results
        this.loading = false
        for (let i = 0; i < this.videos.length; i++) {
          if (this.videos[i].name !== undefined) {
            this.videos[i].name = this.videos[i].name
          }
          this.videos[i].file =
            environment.filesUrl +
            this.videos[i].file
          this.videos[i].picture =
            environment.filesUrl +
            this.videos[i].picture
          this.videos[i]['Time'] = this.commonService.convertTime(this.videos[i].duration, undefined)
        }
      },
      (err) => {
        this.loading = false
        this.commonService.handleResponse(err)

      }
    )
  }

  getRating(entity: any, id: any): void {
    this.loading = true
    this.ratesService.getRatebyentity(id, entity).subscribe((response: any) => {
      this.comments = response.list
      if (this.comments.length > 0) {
        for (let i = 0; i < this.comments.length; i++) {
          this.comments[i]['fromNow'] = moment(this.comments[i].createdAt).fromNow()
          this.userService.getUserById(this.comments[i].userId).subscribe((response: any) => {
            if (response.picture !== undefined) {
              response.picture = environment.filesUrl + response.picture
            } else {
              response.picture = '../../../../assets/images/e-fit-default-image/user-default.png'
            }
            this.user = response

            this.comments[i]['user'] = this.user
          }, err => {
          })
          this.loading = false
        }
      }

    },
      err => {
        this.loading = false
      })


  }

  showVideo(video: any) {
    this.video = video
    this.router.navigate(['/video/' + video.name, { id: video.id }])
  }

  openModal() {
    const modalRef = this.modalService.open(ModalCommentsComponent)
    modalRef.componentInstance.data = this.comments
  }
}
