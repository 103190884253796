import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { Like, LikesResult } from '../../interfaces/like.interface'

@Injectable({
  providedIn: 'root'
})
export class LikesService {

  private uri = `${environment.apiUrl}`
  private controller = `/likes`
  private controller_likes = '/public-likable/'
  private controller_entity_like = '/public-likable/get-like'
  private controller_entity_likes = '/public-likable/get-likes'

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getLikeById(id: string) {
    return this.http.get(this.uri + this.controller + '/' + id, this.httpOptions)
  }


  getLike(id: number): Observable<Like | any> {
    return this.http.get(this.uri + `/${id}`)
  }

  getLikes(parameters: { [param: string]: string | string[] }): Observable<LikesResult | any> {
    return this.http.get(this.uri, { params: parameters })
  }
  getLikebyEntity(entity: string) {
    return this.http.get(this.uri + this.controller_entity_likes + '/' + entity, this.httpOptions)
  }
  getLikebyentity(entity: string, foreignKey: number) {
    return this.http.get(this.uri + this.controller_entity_like + '/' + entity + '/' + foreignKey, this.httpOptions)
  }
  setLikebyentity(entity: string, foreignKey: number) {
    return this.http.post(this.uri + this.controller_likes + 'set-like/' + entity + '/' + foreignKey, this.httpOptions)
  }
  unsetLikebyentity(entity: string, foreignKey: number) {
    return this.http.post(this.uri + this.controller_likes + 'unset-like/' + entity + '/' + foreignKey, this.httpOptions)
  }
}
