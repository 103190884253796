

<footer>
  <!--? Footer Start-->
  <div class="footer-area black-bg">
      <div class="container">
         
          <!-- Footer Bottom -->
          <div class="footer-bottom">
              <div class="row d-flex align-items-center" (click)="openCGU()">
                  <div class="col-lg-12">
                      <div class="footer-copy-right text-center">
                            <p class="company-name">
                              
                              {{'footer.copyright.CONDITION' | translate }}
                               </p>
                         

                           <div style="z-index:0;" >
                            <marquee  direction="left"  style="background-color: #1F2833 ; margin: 0%; padding: 0%;" >
                                <p  style="color:goldenrod; border-radius: 5px;"><strong>
                                  {{'footer.copyright.BY' | translate }}
                                </strong></p>
                            </marquee>
                          </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End-->
</footer>




