<div *ngIf="!sessionDetails" >
  <div class="top-slider__slides nova-slider" data-auto-play="1">
    <figure
      class="top-slider__slide nova-slider__slide nova-slider__slide--animation nova-slider__slide--active top-slider__slide--active ml-0">
      <picture class="top-slider__picture">
        <source media="(min-width: 600px)" class="top-slider__source" srcset="{{fileUrl+filterClass.picture}}"
          width="1920" height="700" style="border-bottom: 4px solid {{filterClass.color}}">
        <img src="{{fileUrl+filterClass.mobilePicture}}" alt="SPORT-CARDIO-SLIDER1" width="480" height="600"
          class="top-slider__img" style="width: 100%; border-bottom: 4px solid {{filterClass.color}}">
      </picture>
    </figure>
  </div>
</div>
<div *ngIf="sessionDetails"  style="background-color: white;">
  <header class="header-picture">
    <nav>
      <div class="row">
      </div>
    </nav>
    <h2 class="live-time">{{ 'STARTCLASS' | translate }} 03:13:57
    </h2>
    <div class="hero-text-box">
      <section class="hero">
        <div class="text-container">
          <div class="text-wrapper">
            <span>CARDIO HIIT LE 01/07/21 À 18H00
            </span>
            <p-button type="submit" styleClass="p-button-lg" label="{{ 'REJOIN' | translate }} "
              icon="pi pi-angle-right" iconPos="right"></p-button>
          </div>
        </div>
      </section>
    </div>
  </header>
</div>
<div class="navigation-title" style="background-color: white;">
  <div [innerHTML]="filterClass.content" class="text-center mt-4"></div>
  <div class="center-position">
  <div class="row mb-3" >
    <div class="col text-center">
      <img src="{{fileUrl+filterClass.classDurationIcon}}" class="benefices__img" style="width: 100px;" alt="pictogramme DURÉE : 50 MINUTES">
    </div>
    <div class="col text-center">
      <img src="{{fileUrl+filterClass.intensityIcon}}" class="benefices__img" style="width: 100px;" alt="pictogramme INTENSITÉ MOYENNE">
    </div>
    <div class="col text-center">
      <img src="{{fileUrl+filterClass.ageRangeIcon}}" class="benefices__img" style="width: 100px;"  alt="pictogramme À PARTIR DE 15 ANS">
    </div>
    <!-- <div class="col text-center" (click)="openModal()">
      <i class="fa fa-comments text-dark comment"></i>
    </div> -->
  </div>
  <div class="row">
    <div class="col text text-center">{{ 'DURATION' | translate }} : {{filterClass.classDurationName}} MINUTES</div>
    <div class="col text text-center">{{filterClass.intensityName}}</div>
    <div class="col text text-center">{{filterClass.ageRangeName}}</div>
    <!-- <div class="col text text-center">{{comments.length}} {{ 'COMMENTS' | translate }}</div> -->
  </div>
</div>

  <!-- <h2>
    {{'sport.COURSESCHEDULE' | translate }} {{filterClass.name}} {{'sport.LIVE' | translate }}
  </h2> -->
  <br>

  <!-- <app-calendar-view [dataReceiver]="getCours(filterClass.id)"></app-calendar-view> -->

  <h2 *ngIf="videos.length > 0">
    {{ 'ADDITIONALCOURSES' | translate}} {{filterClass.name}}
  </h2>
  <p-carousel [value]="videos" [numVisible]="4" [numScroll]="4" [circular]="false"
    [responsiveOptions]="responsiveOptions">

    <ng-template let-video pTemplate="item">
      <div *ngIf="video.picture !== undefined " class="main-container_tab" (click)="showVideo(video)"
        style="background-image: url('{{ video.picture }}'); ">
        <div class="duration">
          <i class="fa fa-clock text-light" aria-hidden="true"></i>
          {{ video.Time }}
        </div>
        <div class="product-item-content">

          <p class="main-container_tab_author" *ngIf="video.name !== undefined ">{{ video.name }}</p>
        </div>
      </div>
    </ng-template>
  </p-carousel>