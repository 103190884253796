import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'
import { Favorite, FavoritesResult } from '../../interfaces/favorite.interface'


@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  private uri = `${environment.apiUrl}`
  private controller = `/public-favouritable/get-favorite`
  private commonUrl = '/public-favouritable/'
  private controller_fav = '/public-favouritable/get-entity-favorites'
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getAll(): Observable<any> {
    return this.http.get(this.uri + this.controller, this.httpOptions)
  }

  getFavoritesByEntity(entity: any): Observable<FavoritesResult | any> {
    return this.http.get(this.uri + this.commonUrl + 'get-favorites/' + entity, this.httpOptions)
  }

  getFavoritesByEntityId(id: any, entity: any): Observable<FavoritesResult | any> {
    return this.http.get(this.uri + this.commonUrl + 'get-favorite/' + entity + '/' + id, this.httpOptions)
  }

  setFavoritesByEntityId(id: any, entity: any): Observable<FavoritesResult | any> {
    return this.http.post(this.uri + this.commonUrl + 'set-favorite/' + entity + '/' + id, this.httpOptions)
  }

  unsetFavoritesByEntityId(id: any, entity: any): Observable<FavoritesResult | any> {
    return this.http.post(this.uri + this.commonUrl + 'unset-favorite/' + entity + '/' + id, this.httpOptions)
  }

  getFavoritebyentity(entity: string, foreignKey: number): Observable<Favorite | any> {
    return this.http.get(this.uri + this.controller_fav + '/' + entity + '/' + foreignKey, this.httpOptions)
  }
}
