
  <video src="assets/images/video.mp4" autoplay loop playsinline muted></video>

  <header class="viewport-header">
    <h1>
      <!-- logo -->
      <div class="footer-logo wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
        <a href="index.html"><img src="assets/img/logo/logo2_footer.png" alt=""></a>
    </div>
      
    </h1>
  </header>
  
  <main>
   
  


    <div class="login">
      <div class="form">
        <div class="form-field">
          <label for="login-username"><i class="fa fa-user"></i></label>
          <input id="login-username" type="text" [(ngModel)]="inscription.username"  id="username" name="username" placeholder="{{('USERNAME'| translate)  }}"  required>
          <svg>
            <use href="#svg-check" />
          </svg>
        </div>
     
        <div class="form-field">
          <label for="login-password"><i class="fa fa-lock"></i></label>
          <input id="login-password" type="password" name="password" placeholder="{{'login.PASSWORD' | translate }}" [(ngModel)]="inscription.password"  required>
          <svg>
            <use href="#svg-check" />
          </svg>
        </div>
        <button type="submit" class="button" (click)="login()">
          <div class="arrow-wrapper">
            <span class="arrow"></span>
          </div>
          <p class="button-text">{{'login.LOGIN' | translate }}</p>
        </button>

      </div>
      <div class="finished">
        <svg>
          <use href="#svg-check" />
        </svg>
      </div>
    </div>
    
    
    <!-- //--- ## SVG SYMBOLS ############# -->
    <svg style="display:none;">
      <symbol id="svg-check" viewBox="0 0 130.2 130.2">
        <polyline points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
      </symbol>
    </svg>
  </main>