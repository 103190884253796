import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private uri = `${environment.apiUrl}`

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getReservations(): Observable<any> {
    return this.http.get(this.uri + '/public-reservations/get-reservations', this.httpOptions)
  }

  getReservationByFitnessSessionId(id: number): Observable<any> {
    return this.http.get(this.uri + '/public-reservations/get-reservation' + '/' + id, this.httpOptions)
  }

  setNewReservation(id: any, confirmation: any): Observable<any> {
    return this.http.post(this.uri + '/public-reservations/set-reservation/' + + id + '/' + confirmation, this.httpOptions)
  }

  unsetReservationByEntityId(id: any): Observable<any> {
    return this.http.post(this.uri + '/public-reservations/unset-reservation/' + id, this.httpOptions)
  }

}
