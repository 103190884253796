<header class="header-picture" *ngIf="sessions.length === 0">
  <nav>
    <div class="row"></div>
  </nav>

  <div class="text-box">
    <section class="text">{{ 'NOSESSIONS' | translate }}</section>
  </div>
</header>


<div class="container pt-5" *ngIf="sessions.length > 0">

  <div class="row">
    <div class="col-lg-3" *ngFor="let session of sessions   | slice:0:show; let i=index">

      <div class="card border-0 mb-5 shadow-lg _offer">
        <ngx-skeleton-loader *ngIf="!contentLoaded" appearance="circle" [theme]="{ width: '270px', height: '180px','border-radius': '10px'
      }">
        </ngx-skeleton-loader>
        <div *ngIf="contentLoaded" class="background" (click)="showSession(session)"
          style="background-image: url('{{ session.fitnessClass.picture }}');">

          <!-- <img src="{{session.fitnessClass.picture}}" alt="" *ngIf="banner" class="card-img-top border-bottom"
              height="250px" />-->
        </div>
        <div class="card-body position-relative">

          <div class="card__content text-center" (click)="showSession(session)">
            <div class="row">
              <h4>{{ session.fitnessClass.name }}</h4>
            </div>
            <div class="row text-center">
              <h5> {{ 'THE' | translate }} {{ session.startTime | date: "shortDate" }} {{ 'AT' | translate }}
                {{ session.startTime | date: "shortTime" }}</h5>
            </div>

            <div class="row text-center">
              <h6>{{ session.fromNow }}</h6>
            </div>
          </div>
          <br>

          <div class="card__content text-center">
            <p-button (click)="showSession(session)" icon="pi pi-external-link" label="Plus Détails"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center pt-4 mb-4" *ngIf="sessions.length > 8">
    <p-button (click)="show = sessions.length" icon="pi pi-plus" label="load More">
    </p-button>
  </div>
</div>