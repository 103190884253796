<div class="item active" *ngIf="session !== undefined">
    <div class="sport-image"
        style="background-image: url('{{ session.fitnessClass.picture }}'); border-bottom: 4px solid {{ session.fitnessClass.color}}">
        <div class="carousel-caption">
        </div>
    </div>
</div>


<div class="navigation-title" *ngIf="session !== undefined">
    <h3 text-center>{{ session.fitnessClass.name }}</h3>
    <div [innerHTML]="session.fitnessClass.content" class="session-name">
    </div>

    <div class="row" *ngIf="session !== undefined">
        <div class="col text-center">
            <img src="{{session.fitnessClass.classDuration.icon}}" class="benefices__img"
                alt="pictogramme DURÉE : 50 MINUTES">
        </div>
        <div class="col text-center">
            <img src="{{session.fitnessClass.intensity.icon}}" class="benefices__img"
                alt="pictogramme INTENSITÉ MOYENNE">
        </div>
        <div class="col text-center">
            <img src="{{session.fitnessClass.ageRange.icon}}" class="benefices__img"
                alt="pictogramme À PARTIR DE 15 ANS">
        </div>
        <!--<div class="col text-center">
            <img src="{{session.fitnessClass.sport.icon}}" class="benefices__img" alt="">
        </div>-->
        <div class="col text-center" (click)="showReservations()">
            <i class="fa fa-list display-6"></i>
        </div>
    </div>
    <div class="row" *ngIf="session !== undefined">
        <div class="col text text-center">{{ 'DURATION' | translate }}: {{session.fitnessClass.classDuration.name}} {{'
            MINUTES' | translate }}</div>
        <div class="col text text-center">{{session.fitnessClass.intensity.name}}</div>
        <div class="col text text-center">{{session.fitnessClass.ageRange.name}}</div>
        <!--<div class="col text-center">{{session.fitnessClass.sport.name}}</div>-->
        <div class="col text-center">{{session.countReservations}} {{ 'RESERVATION' | translate }}</div>

    </div>


    <p-carousel [value]="videos" [numVisible]="5" [numScroll]="4" [circular]="false"
        [responsiveOptions]="responsiveOptions" *ngIf="videos.length > 0">

        <ng-template let-video pTemplate="item">
            <div *ngIf="video.picture !== undefined " class="main-container_tab" (click)="showVideo(video)"
                style="background-image: url('{{ video.picture }}'); ">
                <div class="duration">
                    <i class="fa fa-clock text-white" aria-hidden="true"></i>
                    {{ video.Time }}
                </div>
                <div class="product-item-content">

                    <p class="main-container_tab_author" *ngIf="video.name !== undefined ">{{ video.name }}</p>
                </div>
            </div>
        </ng-template>
    </p-carousel>