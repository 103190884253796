import { Component, ViewChild, Input } from '@angular/core'
import { CommonServices } from 'src/app/services/common/common-service.service'
import * as moment from 'moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { SharedServiceService } from '../../../services/shared-service.service'
import { SportService } from '../../../services/sport/sport.service'
import { SessionService } from '../../../services/sessions-member/session.service'
import { Router } from '@angular/router'
import { DataStorageService } from '../../../services/data-storage.service'
import { environment } from 'src/environments/environment.prod'
import { ReservationService } from '../../../services/reservation/reservation.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { LikesService } from '../../../services/likes/likes.service'
import { googleCalendarEventUrl } from 'google-calendar-url'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ColorSessionService } from '../../../services/color-session/color-session.service'
import { RatesService } from '../../../services/rates/rates.service'
declare function agendaDownload(data: any): any
import { TranslateService } from '@ngx-translate/core'
import { SessionsService } from 'src/app/services/sessions-coach/sessions.service'


declare var $: any

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent {

  @Input() dataReceiver: any
  @ViewChild('calendar', { static: true })
  public sessions: any = []
  public loading = false
  public options: any
  public fileUrl = `${environment.filesUrl}`
  public events: any = []
  public entity = 'FitnessSession'
  public showModal = false
  public filterSession: any
  public cours: any
  public filterClass: any
  public coach: any
  public reservationId: any = []
  public reservationIdArray: any = []
  public message: any
  public selectedFav = true
  public selectedLike = false
  public fitnessSessionFav: any
  public listFitnessSessionFav: any = []
  public likeFitnessSession: any = []
  public showSpecModal = false
  public ratingvalue: any
  public likeData: any = []
  public fitnessSessionTitle: any = []
  public optionsCalendar = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
    ],
    locale: 'fr', // the initial locale. of not specified, uses the first one
    themeSystem: 'bootstrap4',
    contentHeight: 800,
    header: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    views: {
      dayGridMonth: {
        buttonText: 'Mois',
      },
      timeGridWeek: {
        buttonText: 'Semaine',
      },
      timeGridDay: {
        buttonText: 'Jour',
      },
      listMonth: {
        buttonText: 'liste',
      },
    },

  }
  public eventsCalendar: any = []

  public myHeading =
    '<h4 >' +
    this.translate.instant('ACCESCOMPTE') +
    '</h4>' +
    '<br>' +
    '<p>' +
    this.translate.instant('APPOINTEMENT') +
    '<a> https://www.clubfitness.tn/live </a>' +
    this.translate.instant('DESCRIPTION') +
    '</p>' +
    '<br>' +
    '<h4>' +
    this.translate.instant('GOODSESSION') +
    '</h4>' +
    '<br>' +
    '<h4>' +
    this.translate.instant('SEEYOUSOON') +
    '</h4>'

  constructor(
    public router: Router,
    public sessionsService: SessionService,
    public commomService: CommonServices,
    public sharedService: SharedServiceService,
    public sportService: SportService,
    public dataStorage: DataStorageService,
    private reservationService: ReservationService,
    public modalService: NgbModal,
    public favoriteService: FavoritesService,
    public commonServices: CommonServices,
    public likeService: LikesService,
    public colorSession: ColorSessionService,
    private fitnessSession: SessionsService,
    public ratingService: RatesService,
    private translate: TranslateService


  ) { }

  ngOnInit(): void {
    this.getCalendarProprety()
  }

  getCalendarProprety() {
    const currentTimestamp = Math.floor(new Date().getTime())
    this.sessionsService
      .getAllSessions(this.dataReceiver[1], this.dataReceiver[0])
      .subscribe((data: any) => {
        this.filterSession = data
        this.reservationService.getReservations().subscribe(
          (response: any) => {
            this.reservationId = response
            for (const reservation in this.reservationId) {
              this.reservationIdArray.push(
                this.reservationId[reservation].fitnessSession.id
              )
            }

            if (this.dataReceiver[1] === null) {
              const futurSessions = this.dataReceiver[0].filter(
                (session: any) => Date.parse(session.startTime) > currentTimestamp
              )
              this.sessions = futurSessions
              this.sessions.sort(
                (a: any, b: any) =>
                  new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
              )
              this.events = this.sessions
              console.log('sessions', this.sessions)
              for (let i = 0; i < futurSessions.length; i++) {
                if (this.reservationIdArray.includes(futurSessions[i].id)) {
                  this.sessions[i]['reserve'] = true
                }
                else {
                  this.sessions[i]['reserve'] = false
                }

                futurSessions[i].picture =
                  environment.filesUrl +
                  futurSessions[i].picture
                if (futurSessions[i].content !== undefined) {
                  futurSessions[i].content = futurSessions[i].content.replace(
                    /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
                    ''
                  )
                }
                this.sessions[i] = futurSessions[i]
                this.sessions[i]['fromNow'] = moment(
                  futurSessions[i].startTime
                ).fromNow()
                this.sessions[i]['start'] = futurSessions[i].startTime
                this.sessions[i]['time'] = moment(
                  futurSessions[i].startTime
                ).format('LT')
                this.sessions[i]['backgroundColor'] = futurSessions[i].color
                this.sessions[i]['title'] = futurSessions[i].name
                this.sessions[i]['borderColor'] = futurSessions[i].color
                this.sessions[i]['className'] = 'orange'
                this.sessions[i]['imageUrl'] = futurSessions[i].picture
                this.sessions[i]['description'] = futurSessions[i].content
              }
            }
            else {
              const futurSessions = data.filter(
                (session: any) => Date.parse(session.startTime) > currentTimestamp
              )
              this.sessions = futurSessions
              console.log('sessions', this.sessions.length)

              this.sessions.sort(
                (a: any, b: any) =>
                  new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
              )
              this.events = this.sessions
              for (let i = 0; i < futurSessions.length; i++) {
                futurSessions[i].picture =
                  environment.filesUrl +
                  futurSessions[i].picture
                if (futurSessions[i].content !== undefined) {
                  futurSessions[i].content = futurSessions[i].content.replace(
                    /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
                    ''
                  )
                }
                if (this.reservationIdArray.includes(futurSessions[i].id)) {
                  this.sessions[i]['reserve'] = true
                }
                else {
                  this.sessions[i]['reserve'] = false
                }
                this.sessions[i] = futurSessions[i]
                this.sessions[i]['fromNow'] = moment(
                  futurSessions[i].startTime
                ).fromNow()
                this.sessions[i]['start'] = futurSessions[i].startTime
                this.sessions[i]['time'] = moment(
                  futurSessions[i].startTime
                ).format('LT')
                this.sessions[i]['backgroundColor'] = futurSessions[i].color
                this.sessions[i]['title'] = futurSessions[i].name
                this.sessions[i]['borderColor'] = futurSessions[i].color
                this.sessions[i]['className'] = 'orange'
                this.sessions[i]['imageUrl'] = futurSessions[i].picture
                this.sessions[i]['description'] = futurSessions[i].content
                this.sessions[i]['textColor'] = this.colorSession.getTextColorHex(this.sessions[i].color)
              }
            }
            this.loading = false
            this.options = {
              plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ],
              locale: 'fr', // the initial locale. of not specified, uses the first one
              themeSystem: 'bootstrap4',
              contentHeight: 800,
              header: {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
              },
              views: {
                dayGridMonth: {
                  buttonText: 'Mois',
                },
                timeGridWeek: {
                  buttonText: 'Semaine',
                },
                timeGridDay: {
                  buttonText: 'Jour',
                },
                listMonth: {
                  buttonText: 'liste',
                },
              },
              events: this.sessions,
              eventRender(event: any) {
                if (event.event._def.extendedProps.imageUrl) {
                  if (event.event._def.extendedProps.reserve) {
                    $(event.el)
                      .find('div.fc-content')
                      .prepend(
                        $('<i class="fa fa-check-circle" style="color:#7CFC00;font-size:15px;margin-right:10px"></i>').html(''))
                  } else {
                    $(event.el)
                      .find('div.fc-content')
                      .prepend(
                        $('').html(''))
                  }

                }
                const tooltip = $('<span class="fc-event"></span>').html(
                  '<h3>' +
                  event.event.title +
                  '</h3>' +
                  '<br />' +
                  '<p><b>Start:</b> ' +
                  event.event.start +
                  '<br />' +
                  '<p><b>End:</b> ' +
                  event.event.start +
                  '</p>'
                )
                $(event.el).tooltip({ title: tooltip })
              },

            }
          },
          (error: any) => {
          })
      })
  }

  hide() {
    this.showModal = false
    $('#successModal').modal('hide')
  }
  eventClicked(event: any) {
    this.selectedLike = false

    this.ratingService.getRatesByEntityId(this.entity, this.filterSession[0].id).subscribe(
      (data: any) => {
        this.ratingvalue = Number(data.value)
      },
      (error: any) => {
        this.ratingvalue = 0
      })
    this.fitnessSessionTitle = []
    $('.tooltip').remove()
    this.eventPath(event)
    const FitnessClassLocalStorage = JSON.parse(
      this.dataStorage.getData('fitness-class-data')
    )
    this.sessionsService.getAllSessions(this.dataReceiver[1], this.dataReceiver[0]).subscribe(
      (data: any) => {
        let sessionData = ''
        if (event.target.nextElementSibling !== null) {
          if (Array.prototype.slice.call(event.srcElement.children).length === 0) {
            sessionData = event.srcElement.nextElementSibling.innerHTML
          } else {
            sessionData = event.target.nextElementSibling.innerHTML
          }
        }
        else {
          if (Array.prototype.slice.call(event.srcElement.children).length > 0) {
            if (event.srcElement.childNodes.length > 1) {
              sessionData = Array.prototype.slice.call(event.srcElement.childNodes)[Array.prototype.slice.call(event.srcElement.childNodes).length - 1].innerText

            } else {
              sessionData = event.srcElement.childNodes[0].lastChild.innerText
            }
          }
          else {
            sessionData = event.srcElement.innerText
          }
        }
        this.filterSession = data
        if (sessionData[0] === 'Semaine' || sessionData[0] === 'Jour' || sessionData[0] === 'list' || sessionData[0] === 'Mois') {
          this.showModal = false
        } else if (sessionData[0] === '') {
          this.showModal = false
        }
        else {
          this.filterSession = data.filter(
            (x: any) => x.name === sessionData
          )
          this.favoriteService
            .getFavoritesByEntityId(this.filterSession[0]['id'], this.entity)
            .subscribe(
              (response: any) => {
                if (response.value === 0) {
                  this.selectedFav = false
                }
                else {
                  this.selectedFav = true

                }
              },
              (error: any) => {
                this.selectedFav = false
              }
            )

          this.likeService.getLikebyentity
            (this.entity, this.filterSession[0]['id']).subscribe(
              (likeResponse: any) => {
                if (likeResponse.value === 0) {
                  this.selectedLike = false
                }
                else {
                  this.selectedLike = true

                }
              },
              (error: any) => {
                this.selectedLike = false
              }
            )
          if (this.filterSession.length !== 0) {
            this.filterClass = FitnessClassLocalStorage.filter(
              (x: any) => x.id === this.filterSession[0].fitnessClassId
            )
            const coachId = this.filterSession[0].coachId
            const coachLocalStorage = JSON.parse(
              this.dataStorage.getData('coach-data')
            )
            this.coach = coachLocalStorage.filter((x: any) => x.id === coachId)
            this.getReservation()
          }
        }

      },
      (error: any) => {
        this.showModal = false
      }
    )
  }

  getReservation() {
    this.reservationService.getReservations().subscribe(
      (data: any) => {
        this.reservationId = data
        for (const reservation in this.reservationId) {
          this.reservationIdArray.push(
            this.reservationId[reservation].fitnessSession.id
          )
        }
        this.showModal = true

      },
      (error: any) => {
      }
    )
  }

  reserveSession(id: any, reservation: any) {
    this.reservationService.setNewReservation(id, 1).subscribe(
      (data: any) => {

        this.showModal = false
        this.refreshEvent();
        Swal.fire(
          this.translate.instant('TANKYOU'),
          this.translate.instant('RESERVATIONCREATED'),
          'success'
        )
      },
      (error: any) => {
        console.log('error', error)
        Swal.fire(
          this.translate.instant('SORRY'),
          this.translate.instant('ERROR'),
          'error')
      }
    )
  }

  DeleteReservation(id: any) {
    this.reservationService.unsetReservationByEntityId(id).subscribe(
      (data: any) => {
        this.showModal = false
        this.refreshEvent();
        Swal.fire(
          this.translate.instant('TANKYOU'),
          this.translate.instant('RESERVATIONDELETED'),
          'success'
        )
      },
      (error: any) => {
        Swal.fire(
          this.translate.instant('SORRY'),
          this.translate.instant('ERROR'),
          'error')
      }
    )
  }

  refreshEvent() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  eventPath(evt: any) {
    const path = (evt.composedPath && evt.composedPath()) || evt.path,
      target = evt.target

    if (path != null) {
      // Safari doesn't include Window, but it should.
      return (path.indexOf(window) < 0) ? path.concat(window) : path
    }

    if (target === window) {
      return [window]
    }
  }

  detailsNavigation(name: any, sessionName: any) {
    if (this.dataReceiver[2] === true) {
      this.router.navigate([
        'cours/' +
        name.replace(' ', '-').toLowerCase() +
        '/' +
        sessionName.replace(' ', '-'),
      ])
    } else {
      this.router.navigate(['cours/' + name.replace(' ', '-').toLowerCase()])
    }
  }

  addMinutes(date: Date, minutes: any) {
    return new Date(date.getTime() + minutes * 60000)
  }

  navigateAgendaGoogle() {

    const url = googleCalendarEventUrl({
      start: moment(this.filterSession[0].startTime).format('YYYYMMDDTHHmmss'),
      end: moment(this.addMinutes(new Date(this.filterSession[0].startTime), this.filterClass[0].classDurationName.split('\"')[0])).format('YYYYMMDDTHHmmss'),
      title: this.filterSession[0].name,
      details: this.filterSession[0].content + this.myHeading,
      location: 'Tunis',
    })
    window.open(url)
  }

  navigateToOutlook() {

    const data = {
      start: moment(this.filterSession[0].startTime).format('DD/MM/YYYY'),
      end: moment(this.addMinutes(new Date(this.filterSession[0].startTime), this.filterClass[0].classDurationName.split('\"')[0])).format('YYYYMMDDTHHmmss'),
      title: this.filterSession[0].name,
      details: this.filterSession[0].content + this.myHeading,
      location: 'Tunis',
    }
    agendaDownload(data)
  }

}

