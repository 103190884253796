import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HomeViewComponent } from './views/inside-app/home-view/home-view.component'
import { SharedComponent } from './views/shared/shared.component'
import { FooterSectionComponent } from './views/shared/footer-section/footer-section.component'
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { PrimeNgImplementation } from './modules/primeNg-Implementation.module'
import { LiveChannelViewComponent } from './views/inside-app/live-channel-view/live-channel-view.component'
import { SportViewComponent } from './views/inside-app/sport-view/sport-view.component'
import { ProgrammeViewComponent } from './views/inside-app/programme-view/programme-view.component'
import { AbonnementViewComponent } from './views/inside-app/abonnement-view/abonnement-view.component'
import { CoachViewComponent } from './views/inside-app/coach-view/coach-view.component'
import { RouteReuseStrategy, RouterModule } from '@angular/router'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { CalendarViewComponent } from './views/inside-app/calendar-view/calendar-view.component'
import { ResetPasswordViewComponent } from './views/authentification/reset-password-view/reset-password-view.component'
import { TermsAndConditionsComponent } from './views/inside-app/terms-and-conditions/terms-and-conditions.component'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { NgxCalendarModule } from 'ss-ngx-calendar'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { SchedulerModule } from 'angular-calendar-scheduler'
import { AppService } from './services/app.service'
import { LoginViewComponent } from './views/authentification/login-view/login-view.component'
import { registerLocaleData } from '@angular/common'
import localeIt from '@angular/common/locales/it'
import { SportListComponent } from './views/inside-app/sport-list/sport-list.component'
import { AccountInfoViewComponent } from './views/inside-app/account-info-view/account-info-view.component'
import { PastSessionsViewComponent } from './views/inside-app/past-sessions-view/past-sessions-view.component'
import { FuturSessionsViewComponent } from './views/inside-app/futur-sessions-view/futur-sessions-view.component'
import { LoaderComponent } from './views/loader/loader.component'
import { JwtInterceptor } from './interceptors/jwt.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { CurrentSessionsViewComponent } from './views/inside-app/current-sessions-view/current-sessions-view.component'
import { CarouselModule } from 'primeng/carousel'
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { FitnessGoalsViewComponent } from './views/inside-app/fitness-goals-view/fitness-goals-view.component'
import { CountdownModule } from 'ngx-countdown'
import { LoaderInterceptor } from './interceptors/loader.interceptor'
import { FullCalendarModule } from 'primeng/fullcalendar'
import { DialogModule } from 'primeng/dialog'
import { VideosViewComponent } from './views/inside-app/videos-view/videos-view.component'
import { VideoViewComponent } from './views/inside-app/video-view/video-view.component'
import { GalleriaModule } from 'primeng/galleria'
import { InscriptionViewComponent } from './views/inside-app/inscription-view/inscription-view.component'
import { CoursViewComponent } from './views/inside-app/cours-view/cours-view.component'
import { BadgeModule } from 'primeng/badge'
import { ContactViewComponent } from './views/inside-app/contact-view/contact-view.component'
import { ReservationViewComponent } from './views/inside-app/reservation-view/reservation-view.component'
import { NotFoundViewComponent } from './views/inside-app/not-found-view/not-found-view.component'
import { OnlineCoursViewComponent } from './views/inside-app/online-cours-view/online-cours-view.component'
import { FilterViewComponent } from './views/inside-app/filter-view/filter-view.component'
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'
import { SessionComponent } from './views/inside-app/session/session.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FavoriteViewComponent } from './views/inside-app/favorite-view/favorite-view.component'
import { ModalRatingComponent } from './views/inside-app/modal-rating/modal-rating.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalModule } from 'ngx-bootstrap/modal'
import { AFQViewComponent } from './views/inside-app/afq-view/afq-view.component'
import { FitnessSportViewComponent } from './views/inside-app/fitness-sport-view/fitness-sport-view.component'
import { ModalCommentsComponent } from './views/inside-app/modal-comments/modal-comments.component'
import { FavoriteComponent } from './views/inside-app/common-view/favorite/favorite.component'
import { PublicReactionComponent } from './views/inside-app/common-view/public-reaction/public-reaction.component'
import { PublicRatingComponent } from './views/inside-app/common-view/public-rating/public-rating.component'
import { ReviewViewComponent } from './views/inside-app/common-view/review-view/review-view.component'
import { TransalteDropDownViewComponent } from './views/inside-app/transalte-drop-down-view/transalte-drop-down-view.component'
import { TranslateModalViewComponent } from './views/inside-app/translate-modal-view/translate-modal-view.component'
import { AuthImageCommonComponent } from './views/authentification/auth-image-common/auth-image-common.component'
import { RegisterViewComponent } from './views/authentification/create-account-view/register-view/register-view.component'
import { CreatePasswordViewComponent } from './views/authentification/create-password-view/create-password-view.component';
import { LegalConceptViewComponent } from './views/legal-concept-view/legal-concept-view.component'
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ClipboardModule } from 'ngx-clipboard';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PostsViewComponent } from './views/inside-app/posts-view/posts-view.component';
import { PostViewComponent } from './views/inside-app/post-view/post-view.component';
// import { CustomRouteReuseStrategy } from './services/CustomRouteReuseStrategy';
import { VerifpinComponent } from './views/authentification/login-view/verifpin/verifpin.component'
import { CodeInputModule } from 'angular-code-input';
import { DisableDownloadDirective } from './directives/DisableDownloadDirective'
import { CoachsViewComponent } from './views/inside-app/coachs-view/coachs-view.component';
import { ChallengeViewComponent } from './views/inside-app/challenge-view/challenge-view.component'
registerLocaleData(localeIt)
export function createTranslateLoader(http: HttpClient) {
  const baseElement = document.getElementsByTagName('base')[0];
  const href = baseElement.getAttribute('href');
  return new TranslateHttpLoader(http, href + 'assets/i18n/', '.json')
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxIntlTelInputModule,
    PrimeNgImplementation,
    RouterModule,
    HttpClientModule,
    SlickCarouselModule,
    CarouselModule,
    FullCalendarModule,
    DialogModule,
    NgxCalendarModule,
    CountdownModule,
    GalleriaModule,
    BadgeModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgbModule,
    ClipboardModule,
    NgxQRCodeModule,
    NgxSkeletonLoaderModule.forRoot(),
    ModalModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CodeInputModule.forRoot({
      codeLength: 4,
      isCharsCode: false,
    }),
    SchedulerModule.forRoot({ locale: 'fr', headerDateFormat: 'daysRange', logEnabled: true }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })

  ],
  declarations: [
    AppComponent,
    HomeViewComponent,
    SharedComponent,
    FooterSectionComponent,
    LiveChannelViewComponent,
    SportViewComponent,
    ProgrammeViewComponent,
    AbonnementViewComponent,
    CoachViewComponent,
    CalendarViewComponent,
    ResetPasswordViewComponent,
    TermsAndConditionsComponent,
    LoginViewComponent,
    AccountInfoViewComponent,
    SportListComponent,
    PastSessionsViewComponent,
    FuturSessionsViewComponent,
    LoaderComponent,
    DisableDownloadDirective,
    CurrentSessionsViewComponent,
    FitnessGoalsViewComponent,
    VideosViewComponent,
    VideoViewComponent,
    ChallengeViewComponent,
    CoachsViewComponent,
    SessionComponent,
    InscriptionViewComponent,
    CoursViewComponent,
    ContactViewComponent,
    ReservationViewComponent,
    NotFoundViewComponent,
    OnlineCoursViewComponent,
    FilterViewComponent,
    ModalRatingComponent,
    FavoriteViewComponent,
    AFQViewComponent,
    FitnessSportViewComponent,
    ModalCommentsComponent,
    FavoriteComponent,
    PublicReactionComponent,
    PublicRatingComponent,
    ReviewViewComponent,
    TransalteDropDownViewComponent,
    TranslateModalViewComponent,
    AuthImageCommonComponent,
    RegisterViewComponent,
    CreatePasswordViewComponent,
    LegalConceptViewComponent,
    PostsViewComponent,
    PostViewComponent,
    VerifpinComponent
    ],

  providers: [AppService, NgbActiveModal,
    // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],

})

export class AppModule { }
