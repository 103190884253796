import { Component, OnInit } from '@angular/core'
import { CoachService } from 'src/app/services/coach/coach.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { Router } from '@angular/router'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { LikesService } from '../../../services/likes/likes.service'
import { RatesService } from '../../../services/rates/rates.service'
import { ViewsService } from '../../../services/views/views.service'
import { environment } from '../../../../environments/environment'
import { DataStorageService } from '../../../services/data-storage.service'
import { SharedServiceService } from '../../../services/shared-service.service'


@Component({
  selector: 'app-coachs-view',
  templateUrl: './coachs-view.component.html',
  styleUrls: ['./coachs-view.component.scss']
})
export class CoachsViewComponent implements OnInit {
  public profile: any
  public coachs: any = []
  public loading = false
  public video: any
  public displayMaximizable = false
  public show = 8
  public favorite: any = 0
  public like: any = 0
  public rate: any = 0
  public view: any = 0
  public comments: any = 0
  public contentLoaded = false
  public coachDate: any = []
  public fileUrl = `${environment.filesUrl}`
  filterCoach: any
  entity = 'Coach'
  storageName = 'coach-data'
  likeData: any = []

  constructor(
    private coachService: CoachService,
    private commonService: CommonServices,
    public favoritesService: FavoritesService,
    private likesService: LikesService,
    public viewsService: ViewsService,
    public ratesService: RatesService,
    private router: Router,
    public dataStorage: DataStorageService,
    public sharedService: SharedServiceService,


  ) { }

  ngOnInit(): void {
    console.log('ngOnInit', 'CoachsViewComponent')
    this.coachs = JSON.parse(this.dataStorage.getData('coach-data'))
    this.coachDate  = [
      {
        "id": 17,
        "name": "Jean-Michel",
        "description": "Coach Yoga & Cycling",
        "photo": "/uploads/coaches/9_photo.jpg",
        "ratingTotal": 2,
        "rate": 0,
        "favorite": false
      },
      // Ajoutez d'autres objets si nécessaire
    ];
  
    console.log('this.coachs', this.coachs, this.coachDate)
    // this.getLike()


  }


  // getLike(){
  //   this.likeData = this.sharedService.getLikeService(this.entity)
  // }



}
