import { Component, OnInit } from '@angular/core'
import { SharedServiceService } from '../../../services/shared-service.service'
import { DataStorageService } from '../../../services/data-storage.service'
import { environment } from '../../../../environments/environment.prod'

@Component({
  selector: 'app-coach-view',
  templateUrl: './coach-view.component.html',
  styleUrls: ['./coach-view.component.scss']
})
export class CoachViewComponent implements OnInit {
  coachData: any
  fileUrl = `${environment.filesUrl}`
  entity = 'Coach'
  constructor(
    private sharedService: SharedServiceService,
    private dataStorage: DataStorageService,
  ) {
   }

  ngOnInit(): void {
    this.getCoachProfile()
  }
  getCoachProfile() {
    const sportName = this.sharedService.getsportName().split('&')
    const coachLocalStorage = JSON.parse(this.dataStorage.getData('coach-data'))
    this.coachData = coachLocalStorage.filter((x: any) => x.id === Number(sportName[sportName.length - 1]))
  }

}
