<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
      <app-auth-image-common></app-auth-image-common>
      </div>
      <div class="col-sm-5 login-section-wrapper">
        <div class="login-wrapper my-auto">
          <div class="row no-gutters">
            <div class="card-body">
              <div class="login-brand mb-4"></div>
              <p class="login-card-description">{{'resetpassword.RESETPASSWORD' | translate}}</p>
              <p class="card-paragraphe">{{'resetpassword.ENTEREMAIL' | translate}}
              </p>
              <br>
              <form>
              <div class="p-fluid p-grid">
                <div class="p-field p-col-12">
                  <label>{{'login.PHONE' | translate }}:</label>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-phone"></i>
                    </span>
                    <span class="p-float-label">
                      <input type="text" id="username" pInputText [(ngModel)]="phone" [required]="true"
                        placeholder="{{'login.PHONE' | translate }}" autocomplete="username"
                        required>
                    </span>
                  </div>
                </div>
              </div>
              <div class="p-grid p-nogutter p-justify-center">
                <p-button type="submit" styleClass="p-button-lg" data-toggle="modal" data-target="#passwordRequestModal" label="{{'resetpassword.SUBMIT' | translate}}"
                  icon="pi pi-angle-right" iconPos="right"></p-button>
              </div>
            </form>
              <div class="or"> {{'footer.copyright.OU' | translate}} </div>
              <br>
              <div class="text-center">
                  <a routerLink="/auth/member/login" pButton pRipple type="button"  style="margin-top:20px" class="p-button-outlined p-button-secondary">{{'login.BACKTOLOGIN' | translate}}</a>
              </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-login-view></app-footer-login-view>
</main>
<app-create-password-view [resetPassword]=true></app-create-password-view>
