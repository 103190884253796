<app-loader *ngIf="loading === true"></app-loader>

<div class="slider-areaV">
  <div class="slider-height2 d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="hero-cap hero-cap2 pt-70">
            <div class="hero__caption">
              <div class="sp-container">
                <div class="sp-content">
                  <h2 class="frame-1">{{ 'home.TITLE' | translate}}</h2>
                  <h2 class="frame-2">{{ 'home.DEFINITION' | translate}}</h2>
                  <h2 class="frame-3">{{ 'home.OURCOCHS' | translate}}</h2>
                  <h2 class="frame-4">{{ 'home.GUIDE' | translate}}</h2>
                  <h2 class="frame-5">
                    <span>FORK,</span>
                    <span>CHANGEMENT,</span>
                    <span>EXPÉRIENCE.</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="z-index:0;" *ngIf="state !== 'ACTIVE'">

  <marquee direction="left" style="background-color: #1F2833 ; margin: 0%; padding: 0%;">
      <p style="color:goldenrod; border-radius: 5px;"><strong>Abonnement 0.450DT/ jour avec 3 jours
              GRATUITS pour toute nouvelle souscription</strong></p>
  </marquee>
</div>

<h2 style="color: white;
  padding: 22px;text-align: center;">
  NOS COURS {{sportName}}
</h2>



<div class="container pt-5" *ngIf="videosFiltreted.length > 0 && sportName != 'Challenge'">


  <ul class="cards" *ngIf="videosFiltreted.length > 2">
    <li class="cards__item" *ngFor="let video of videosFiltreted  | slice:0:show; let i=index">
      <div class="card">
        <div class="card__image card__image--fence" (click)="showVideo(video)"
          style="background-image: url('{{ video.picture }}'); "></div>
        <div class="card__content">
          <div class="card__title">{{ video.name }}</div>
          <p class="card__text">{{video.description}} </p>
          <button class="btn btn--block card__btn" (click)="showVideo(video)">Regarder la vidéo</button>
        </div>
      </div>
    </li>

  </ul>

  <ul class="cardss" *ngIf="videosFiltreted.length === 2">
    <li class="cards__item" *ngFor="let video of videosFiltreted">
      <div class="card">
        <div class="card__image card__image--fence" (click)="showVideo(video)"
          style="background-image: url('{{ video.picture }}'); "></div>
        <div class="card__content">
          <div class="card__title">{{ video.name }}</div>
          <p class="card__text">{{video.description}} </p>
          <button class="btn btn--block card__btn" (click)="showVideo(video)">Regarder la vidéo</button>
        </div>
      </div>
    </li>

  </ul>

  <!-- <ul class="cards" >
        <li class="cards__item" *ngFor="let video of videosFiltreted  | slice:0:show; let i=index">
            <div class="card">
                <div class="card__image card__image--fence" (click)="showVideo(video)"
                    style="background-image: url('{{ video.picture }}'); "></div>
                <div class="card__content">
                    <div class="card__title">{{ video.name }}</div>
                    <p class="card__text">{{video.description}} </p>
                    <button class="btn btn--block card__btn" (click)="showVideo(video)">Regarder la vidéo</button>
                </div>
            </div>
        </li>
  
    </ul> -->
  <div class="text-center pt-4 mb-4" *ngIf="videosFiltreted.length > 8">
    <p-button (click)="show = videosFiltreted.length" icon="pi pi-plus" label="Voir aussi">
    </p-button>
  </div>

</div>

<div class="container pt-5" *ngIf="videosFiltreted.length === 0 && sportName != 'Challenge'">

  <ul class="cards" >
    <li class="cards__item" *ngFor="let video of videos | slice:0:show; let i=index">
      <div class="card">
        <div class="card__image card__image--fence" (click)="showVideo(video)"
          style="background-image: url('{{ video.picture }}'); "></div>
        <div class="card__content">
          <div class="card__title">{{ video.name }}</div>
          <p class="card__text">{{video.description}} </p>
          <button class="btn btn--block card__btn" (click)="showVideo(video)">Regarder la vidéo</button>
        </div>
      </div>
    </li>

  </ul>
  <div class="text-center pt-4 mb-4" *ngIf="videos.length > 8">
    <p-button (click)="show = videos.length" icon="pi pi-plus" label="Voir aussi">
    </p-button>
  </div>
</div>





<div class="container pt-5" *ngIf="sportName === 'Challenge'">
  <div class="back-arrow" (click)="goBack()" *ngIf="isslide" >⬅️</div> 

  <h1 style="color: white;">30-DAY <br> WORKOUT CHALLENGE </h1>

  <div class="row p-5">
    <p>
      Tu n’as pas l’envie ni le temps de te rendre à la salle de sport ? Qu’à cela ne tienne, CLUB FITNESS te montre des
      exercices parfaits à faire à la maison, pour te maintenir fit ! Qui a dit que le télétravail t’empêcherait d’avoir
      une activité physique ?
    </p>
    <p>
      Et avant de commencer ton entraînement à la maison, pense à bien t’échauffer en commençant par 10 min
      d’échauffement. Pour te donner un coup de boost, rien de mieux que nos Energy Aminos, un combo de BCAA idéal avant
      l’entraînement !
    </p>
  </div>
 
  <div *ngIf="!isslide" class="slide">
    <div class="slidil" >
      <div class="door" id="div1">
        <h4 class="door1">
          1
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div1', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div2">
        <h4 class="door1">
          2
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div2', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div3">
        <h4 class="door1">
          3
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div3', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div4">
        <h4 class="door1">
          4
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div4', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div5">
        <h4 class="door1">
          5
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div5', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div6">
        <h4 class="door1">
          6
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div6', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div7">
        <h4 class="door1">
          7
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div7', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div8">
        <h4 class="door1">
          8
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div8', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div9">
        <h4 class="door1">
          9
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div9', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div10">
        <h4 class="door1">
          10
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div10', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div11">
        <h4 class="door1">
          11
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div11', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div12">
        <h4 class="door1">
          12
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div12', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div13">
        <h4 class="door1">
          13
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div13', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div14">
        <h4 class="door1">
          14
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div14', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div15">
        <h4 class="door1">
          15
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div15', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div16">
        <h4 class="door1">
          16
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div16', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div17">
        <h4 class="door1">
          17
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div17', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div18">
        <h4 class="door1">
          18
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div18', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div19">
        <h4 class="door1">
          19
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div19', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div20">
        <h4 class="door1">
          20
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div20', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div21">
        <h4 class="door1">
          21
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div21', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div22">
        <h4 class="door1">
          22
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div22', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div23">
        <h4 class="door1">
          23
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div23', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div24">
        <h4 class="door1">
          24
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div24', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div25">
        <h4 class="door1">
          25
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div25', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div26">
        <h4 class="door1">
          26
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div26', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div27">
        <h4 class="door1">
          27
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div27', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div28">
        <h4 class="door1">
          28
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div28', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div29">
        <h4 class="door1">
          29
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div29', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
    <div class="slidil" >
      <div class="door" id="div30">
        <h4 class="door1">
          30
          <div class="row mb-4">
            <div class="col text-center">
              <a class="btnn" (click)="openslider('div30', 'description')">Start</a>
            </div>
          </div>
        </h4>
      </div>
    </div>
    
  </div>

  <div class="step-container" *ngIf="isslide" style="height: 100rem;">
    <input type="checkbox" id="step-1" name="toggler" checked />
    <div class="step-dot one">Ready</div>
    <div class="step-content">
      <label for="step-2">Suivant</label>
      <div class="text">
        <img [src]="imageSource1" *ngIf="imageSource1" alt="SPORT-CARDIO-SLIDER1" width="680" height="400"
          class="top-slider__img" style="width: 100%;">
        <p style="color: black;" *ngIf="description1">{{ description1 }}
       
        </p>
      </div>
    </div>
    <input type="checkbox" id="step-2" name="toggler" />
    <div class="step-dot two">Set</div>
    <div class="step-content ">
      <label for="step-3">Next</label>
      <img [src]="imageSource2" *ngIf="imageSource2" alt="SPORT-CARDIO-SLIDER1" width="680" height="400"
        class="top-slider__img" style="width: 100%;">
      <p style="color: black;padding: 1rem;" *ngIf="description2">{{ description2 }}

      </p>
    </div>
    <input type="checkbox" id="step-3" name="toggler" />
    <div class="step-dot three">Go!</div>
    <div class="step-content">
      <label for="step-4" (click)="setIsTrue()">Next</label>

      <img [src]="imageSource3" *ngIf="imageSource3" alt="SPORT-CARDIO-SLIDER1" width="680" height="400"
        class="top-slider__img" style="width: 100%;">
    </div>
    <p style="color: black;" *ngIf="description3">{{ description3 }} </p>

  </div>





</div>
