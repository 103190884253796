<div class="wrapper">
  <div class="profile-card js-profile-card">
    <div class="profile-card__img">
      <img src="{{fileUrl+
        coachData[0].photo}}" alt="profile card">
    </div>
    <div class="profile-card__cnt js-profile-cnt">
      <div class="profile-card__name">{{coachData[0].name}}</div>
      <div class="profile-card__txt"> {{coachData[0].description}}</div>
      <div class="profile-card-loc">
        <span class="profile-card-loc__txt">
          <app-public-reaction [likeAction]=true [disLikeAction]=false [id]="3" [entity]="entity"
            [name]="coachData[0].name"></app-public-reaction>
          <app-public-rating [id]="coachData[0].id" [entity]="entity" [rate]="coachData[0].ratingTotal"
            [name]="coachData[0].name"></app-public-rating>
        </span>
      </div>
      <div class="profile-card-inf">
        <div class="profile-card-inf__item">
        </div>
        <div class="profile-card-inf__item">
          <div class="profile-card-inf__title">650</div>
          <div class="profile-card-inf__txt">Participants
          </div>
        </div>
        <div class="profile-card-inf__item">
          <div class="profile-card-inf__title">123</div>
          <div class="profile-card-inf__txt">Avis</div>
        </div>
        <div class="profile-card-inf__item">
        </div>
      </div>
    </div>
    <div class="center">
      <app-filter-view [showFav]=true [idCoach]="coachData[0].id" style="    background-color: white;"></app-filter-view>
    </div>
  </div>
</div>