import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { SessionsService } from '../../../services/sessions-coach/sessions.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import * as  moment from 'moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { FullCalendarModule } from 'primeng/fullcalendar'
import { environment } from '../../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router'
import { ColorSessionService } from '../../../services/color-session/color-session.service'

import 'moment/locale/fr'

declare var $: any



@Component({
  selector: 'app-futur-sessions-view',
  templateUrl: './futur-sessions-view.component.html',
  styleUrls: ['./futur-sessions-view.component.scss'],
})
export class FuturSessionsViewComponent implements OnInit {
  public sessions: any = []
  public picture = null
  public loading = false
  @Input() data: any
  // events: any = [];

  options: any
  // @ViewChild('modal') modal: ModalComponent;
  @ViewChild('calendar', { static: true })
  calendar: any = FullCalendarModule
  events: any = []
  showModal = false
  name: any
  date: any
  public displayMaximizable = false
  public cours: any
  filterSession: any
  session: any
  eventss = [
    // Votre tableau de données ici
  ];

  // Calcul des jours du calendrier (à adapter selon vos besoins)
  calendarDays: Date[] = [];
  // calendarDays: { date: Date, events: any[] }[] = [];

  constructor(
    public sessionsService: SessionsService,
    public commomService: CommonServices,
    private router: Router,
    public colorSession: ColorSessionService,

  ) { }

  ngOnInit(): void {
    this.loading = true
    this.sessionsService.getAll().subscribe(
      (data: any) => {
        const currentTimestamp = Math.floor(new Date().getTime())
        const futurSessions = data.filter(
          (session: any) => Date.parse(session.startTime) > currentTimestamp
        )
        this.sessions = futurSessions
        this.data = this.sessions
        this.sessions.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
        this.events = this.sessions
        this.eventss = this.sessions
        this.populateCalendarDays();

        for (let i = 0; i < futurSessions.length; i++) {
          futurSessions[i].fitnessClass.picture =
            environment.filesUrl +
            '/' +
            futurSessions[i].fitnessClass.picture
          if (futurSessions[i].fitnessClass.content !== undefined) {
            futurSessions[i].fitnessClass.content = futurSessions[
              i
            ].fitnessClass.content.replaceAll(
              /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
              ''
            )
          }
          moment.locale('fr')
          this.sessions[i] = futurSessions[i]
          this.sessions[i]['fromNow'] = moment(futurSessions[i].startTime).fromNow()
          this.sessions[i]['start'] = futurSessions[i].startTime
          this.sessions[i]['time'] = moment(futurSessions[i].startTime).format('LT')
          this.sessions[i]['backgroundColor'] = futurSessions[i].fitnessClass.color
          this.sessions[i]['title'] = futurSessions[i].fitnessClass.name
          this.sessions[i]['borderColor'] = futurSessions[i].fitnessClass.color
          this.sessions[i]['className'] = 'orange'
          this.sessions[i]['imageUrl'] = futurSessions[i].fitnessClass.picture
          this.sessions[i]['description'] = futurSessions[i].fitnessClass.content
          this.sessions[i]['textColor'] = this.colorSession.getTextColorHex(futurSessions[i].fitnessClass.color)

        }

        this.loading = false
        this.options = {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
          locale: 'fr', // the initial locale. of not specified, uses the first one
          header: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
          },
          views: {
            dayGridMonth: {
              buttonText: 'Mois'
            },
            timeGridWeek: {
              buttonText: 'Semaine'
            },
            timeGridDay: {
              buttonText: 'Jour'
            },
            listMonth: {
              buttonText: 'liste'
            }
          },
          events: this.sessions,

          eventRender(event: any) {
            this.cours = event.event._def.extendedProps

            const tooltip = $('<span class="fc-event"></span>').html('<h3>' + event.event.title + '</h3>' + '<br />' +
              '<p><b>Start:</b> ' + event.event.start + '<br />' +
              '<p><b>End:</b> ' + event.event.start + '</p>')
            $(event.el).tooltip({ title: tooltip })
          },
        }
      },
      (err) => {
        this.loading = false
        this.commomService.handleResponse(err)
      }
    )
  }

  eventClicked(event: any) {
    $('.tooltip').remove()
    this.eventPath(event)
    this.sessionsService.getAll().subscribe(
      (data: any) => {
        this.filterSession = data
        const sessionData = event.target.innerText // event.path[1].innerText;
        this.filterSession = this.filterSession.filter((session: any) => {
          if (sessionData.includes(session.fitnessClass.name)) {
            this.session = session
            console.log('event', event, this.session)
            this.router.navigate(['/session/' + this.session.id])

          }

        })

      })
  }

  eventPath(evt:any) {
    const path = (evt.composedPath && evt.composedPath()) || evt.path,
      target = evt.target
    if (path !== null) {
      // Safari doesn't include Window, but it should.
      return (path.indexOf(window) < 0) ? path.concat(window) : path
    }

    if (target === window) {
      return [window]
    }

  }

  


  populateCalendarDays(): void {
    // Extrayez les dates uniques des événements
    const uniqueDates = this.getUniqueDates();

    // Ajoutez ces dates à calendarDays
    this.calendarDays = [...uniqueDates];
    console.log('this.calendarDays', this.calendarDays)
  }

  getUniqueDates(): Date[] {
    const uniqueDates: Date[] = [];

    this.eventss.forEach((event:any) => {
      console.log('this.calendarDays event', event)

      const eventDate = new Date(event.startTime);

      console.log('this.calendarDays eventDate', eventDate)

      if (!uniqueDates.some(date => this.isSameDay(date, eventDate))) {
        uniqueDates.push(eventDate);
      }
    });

    // Vous pouvez trier les dates si nécessaire
    uniqueDates.sort((a, b) => a.getTime() - b.getTime());
    console.log('this.calendarDays uniqueDates', uniqueDates)

    return uniqueDates;
  }

  getEventsByDay(day: Date): any[] {
    return this.eventss.filter((event:any) => this.isSameDay(new Date(event.startTime), day));
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }


  // populateCalendarDays(): void {
  //   const currentDate = new Date();
  //   const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  //   const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
  //   // Remplissez calendarDays avec les jours entre firstDayOfMonth et lastDayOfMonth et leurs événements associés
  //   this.calendarDays = this.getDaysBetween(firstDayOfMonth, lastDayOfMonth).map(day => ({
  //     date: day,
  //     events: this.getEventsByDay(day)
  //   }));
  //   console.log('this.calendarDays', this.calendarDays)
  // }
  
  getDaysBetween(startDate: Date, endDate: Date): Date[] {
    const days: Date[] = [];
    const currentDay = new Date(startDate);
  
    while (currentDay <= endDate) {
      days.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }
  
    return days;
  }
}
