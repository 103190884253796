import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core'
import { ModalDismissReasons, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { RatesService } from '../../../services/rates/rates.service'
import { CommonServices } from '../../../services/common/common-service.service'
import { Message, MessageService } from 'primeng/api'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { SweetAlertOptions } from 'sweetalert2' //  This is Interface. js files don't store interfaces
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-rating',
  templateUrl: './modal-rating.component.html',
  styleUrls: ['./modal-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ModalRatingComponent implements OnInit {
  closeResult: any
  // add reference of the template
  @ViewChild('content') content: any
  currentRate = 0
  @Input() data: any
  message: Message[] = []
  isLoggedIn = false
  RatingFailed = false
  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    public ratingService: RatesService,
    public commonServices: CommonServices,
    private translate: TranslateService

  ) { }

  ngOnInit(): void {

  }

  open() {
    // and use the reference from the component itself
    this.modal.open(this.content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
    })
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }

  setRating(value: any) {
    if (isNaN(value.rate)) {
      this.RatingFailed = true
      this.message = [
        {
          severity: 'warn',
          detail: this.translate.instant('ENTERTOTALRATINGS'),
        },
      ]
      return
    }

    const data = {
      subject: value.body,
      body: value.body
    }

    this.ratingService.setRatingByEntityId(value.id, value.entity, value.rate, data).subscribe((data: any) => {
      this.RatingFailed = false
      Swal.fire(this.translate.instant('TANKYOU'), this.translate.instant('RATINGCREATED'), 'success')

      this.modal.dismissAll()
    },
      err => {
        this.commonServices.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'), 'Something went wrong!', 'error')
      })

  }
}
