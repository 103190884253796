import { Injectable } from '@angular/core'
import { OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TokenStorageService } from '../token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class StaticDataService implements OnInit {
  router = ''
  public appsNav: any = []
  public items: any = [];
  public userRole: any;
  constructor(private translate: TranslateService, public route: Router,
    private tokenStorage: TokenStorageService) {
      console.log('this.tokenStorage.getUser()', this.tokenStorage.getUser().roles)

    if (this.tokenStorage.getUser().roles != undefined ) {
      this.userRole = this.tokenStorage.getUser().roles[1] || 'ROLE_MEMBER';
      console.log('this.userRole', this.userRole)
    }

  }

  ngOnInit(): void {
    console.log('this.items', this.items)
  }

  translateItems(): Promise<any[]> {
    return new Promise((resolve) => {
      const items = [
        {
          label: 'shared.HOME',
          routerLink: '/',
        },
        {
          label: 'SPORTS',
          visible: this.userRole === 'ROLE_MEMBER',
          items: [

          ]
        },
        {
          label: 'CLASSESLIVE',
          routerLink: '/live',
          visible: this.userRole === 'ROLE_MEMBER',

        },
        {
          // label: 'LIVECLASSES',
          label: 'VIDEOS',
          routerLink: '/online-cours',
          visible: this.userRole === 'ROLE_MEMBER',


        },
        {
          // label: 'LIVECLASSES',
          label: 'COACHS',
          routerLink: '/coachs',
          visible: this.userRole === 'ROLE_MEMBER',


        },
        {
          label: 'PROGRAMM',
          routerLink: '/program',
          visible: this.userRole === 'ROLE_MEMBER',

        },
        {
          label: 'INFO',
          routerLink: '/posts',
          visible: this.userRole === 'ROLE_MEMBER',
        },

        {
          label: 'FUTURSESSION',
          routerLink: '/futur-sessions',
          visible: this.userRole === 'ROLE_COACH',
        },
        {
          label: 'LIVESESSION',
          routerLink: '/current-sessions',
          visible: this.userRole === 'ROLE_COACH',
        },
        {
          label: 'footer.MYVIDEOS',
          routerLink: '/videos',
          visible: this.userRole === 'ROLE_COACH',
        },
      ];

      const translatedItems: any = [];
      for (const item of items) {
        this.translate.get(item.label).subscribe((translation: string) => {
          item.label = translation;
          translatedItems.push(item);
          if (translatedItems.length === items.length) {
            resolve(translatedItems);
          }
        });
      }
    });
  }
}
