import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { ModalDismissReasons, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { RatesService } from '../../../services/rates/rates.service'
import { CommonServices } from '../../../services/common/common-service.service'
@Component({
  selector: 'app-modal-comments',
  templateUrl: './modal-comments.component.html',
  styleUrls: ['./modal-comments.component.scss']
})
export class ModalCommentsComponent implements OnInit {
  @ViewChild('content') content: any
  @Input() data
  closeResult: any
  comments = []
  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    public ratingService: RatesService,
    public commonServices: CommonServices,
  ) { }

  ngOnInit(): void {
    this.comments = this.data
  }

  open() {
    // and use the reference from the component itself
    this.modal.open(this.content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
    })
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }
}
