import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'



@Injectable({
  providedIn: 'root'
})
export class SportService {
  private uri = `${environment.apiUrl}`

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }
  getSportList() {
    return this.http.get(this.uri + `/public-lists/sports`,  this.httpOptions)
  }
  postSportList(data: any): Observable<any> {
    return this.http.put(this.uri + `/public-member-actions/sports`, data,  this.httpOptions)
  }
  getGoalsList() {
    return this.http.get(this.uri + `/public-lists/fitness-goals`,  this.httpOptions)
  }
  postGoalsList(data: any): Observable<any> {
    return this.http.put(this.uri + `/public-member-actions/fitness-goals`, data, this.httpOptions)
  }


}
