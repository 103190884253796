import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { DataStorageService } from '../../../services/data-storage.service'
import { VideosService } from '../../../services/videos/videos.service'
import { Router } from '@angular/router'
import * as  moment from 'moment'
import { CommonServices } from '../../../services/common/common-service.service'
import { environment } from 'src/environments/environment'
import { SharedServiceService } from '../../../services/shared-service.service'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-filter-view',
  templateUrl: './filter-view.component.html',
  styleUrls: ['./filter-view.component.scss']
})

export class FilterViewComponent implements OnInit {
  @Input() data: any
  @Output() linkClick = new EventEmitter<string>()
  @Input() public videos: any = []
  @Input() showFav = false
  @Input() idCoach = 0
  public entity = 'Video'
  public intensitys: any = []
  public goals: any = []
  public durations: any = []
  public coachDate: any = []
  public selectedDuration: any
  public selectedIntensity: any
  public selectedGoal: any
  public selectedCoach: any
  public selectedCours: any
  public selectedFav = false
  public profile: any
  public loading = false
  public video: any
  public displayMaximizable = false
  public CoachList: any = []
  public CoursList: any = []
  public GoalList: any = []
  public IntensityList: any = []
  public DurationList: any = []
  public parameters: { [param: string]: string | string[] } = { offset: '0', limit: '1000' }
  public fileUrl = `${environment.filesUrl}`
  public url = ''
  public show = 8
  public p = 0
  public dataRecived: any = []
  public isGoal = false
  public isCoach = false
  public isFitness = false
  public isDuration = false
  public isIntensity = false
  public currentDialog: any = null
  public favVideo: any = []
  public likeData: any = []
  public favId: any = []
  public contentLoaded = false
  public displayDateTimeFormat = ''
  public displayDateFormat = ''
  public displayTimeFormat = ''
  constructor(
    private dataStorage: DataStorageService,
    private videosService: VideosService,
    private router: Router,
    private commonServices: CommonServices,
    private sharedService: SharedServiceService,
    private favoriteService: FavoritesService,
    public translate: TranslateService,

  ) {
  }

  ngOnInit(): void {
    this.displayDateTimeFormat = this.translate.instant('formats.DATETIME')
    this.displayDateFormat = this.translate.instant('formats.DAY')
    this.displayTimeFormat = this.translate.instant('formats.MONTH')
    this.getFavVideo()
    // this.getLikeVideo()
    this.getDataFromLocalStorage()
    this.TestVideosDataIntoViews()
  }

  getDataFromLocalStorage() {
    this.coachDate = JSON.parse(this.dataStorage.getData('coach-data'))
    this.intensitys = JSON.parse(this.dataStorage.getData('intensity-data'))
    this.goals = JSON.parse(this.dataStorage.getData('goals-data'))
    this.durations = JSON.parse(this.dataStorage.getData('duration-data'))
  }

  TestVideosDataIntoViews() {
    if (!this.showFav) {
      const data = {
        fitnessClasses: [],
        fitnessSessions: [],
        sports: [],
        coaches: [],
        intensities: [],
        classDurations: [],
        fitnessGoals: [],
        orderBy: [{ field: moment().format('L'), direction: 'DESC' }],
        limit: 10
      }
      this.getVideos(data)
    }
    if (this.idCoach !== 0) {
      console.log('this.idCoach', this.idCoach)
      const filterCoachVideoById = {
        coaches: [this.idCoach]
      }
      this.videosService.getCoachVideo(filterCoachVideoById).subscribe(
        async (videos: any) => {
          this.videos = videos.results
        })
    }
    else {
      this.favVideo = JSON.parse(this.dataStorage.getData('video-fav'))[1]
      this.videos = []
      this.url = environment.filesUrl
      this.videos = this.favVideo
    }
  }

  // getLikeVideo() {
  //   this.likeData = this.sharedService.getLikeService(this.entity)
  // }
  getFavVideo() {
    this.favoriteService
      .getFavoritesByEntity(this.entity)
      .subscribe(
        (response: any) => {
          for (const i in response) {
            this.favId.push(response[i].foreignKey)
          }
          console.log('response fav', this.favId)
        },
        (error: any) => {
          console.log('response fav', error)

        }
      )
  }

  getSelectedValue() {
    const data = {
      fitnessClasses: this.CoursList,
      fitnessSessions: [],
      sports: [],
      coaches: this.CoachList,
      intensities: this.IntensityList,
      classDurations: this.DurationList,
      fitnessGoals: this.GoalList
    }
    this.getVideos(data)
  }

  getVideos(data: any) {
    this.loading = true
    console.log('filter video', data)
    this.videosService.getCoachVideo(data).subscribe(
      async (videos: any) => {
        this.videos = videos.results
        this.loading = false
        for (let i = 0; i < this.videos.length; i++) {
          this.videos[i].file =
            environment.filesUrl +
            this.videos[i].file

          this.videos[i]['Time'] = this.commonServices.convertTime(this.videos[i].duration, undefined)
        }
        setTimeout(() => {
          this.contentLoaded = true;
        }, 4000);
      },
      (err) => {
        
        this.loading = false
      }
    )
  }

  showVideo(video: any) {
    this.video = video
    console.log('video', video)
    this.router.navigate(['/video/' + video.id])
  }

  checkUncheckAllGoals() {
    for (let i = 0; i < this.goals.length; i++) {
      this.goals[i].isSelected = this.isGoal
    }
    this.getCheckedItemList()
  }

  isAllSelected() {
    this.isGoal = this.goals.every(function (item: any) {
      return item.isSelected === true
    })
    this.getCheckedItemList()
  }

  getCheckedItemList() {
    this.GoalList = []
    this.selectedGoal = []
    for (let i = 0; i < this.goals.length; i++) {
      if (this.goals[i].isSelected) {
        this.selectedGoal.push(this.goals[i])
      }
    }
    this.selectedGoal.forEach((goal: any) => {
      this.GoalList.push(goal.id)
    })
  }

  // coachs

  checkUncheckAllCoachs() {
    for (let i = 0; i < this.coachDate.length; i++) {
      this.coachDate[i].isSelected = this.isCoach
    }
    this.getCheckedItemListCoachs()
  }

  isAllSelectedCocahs() {
    this.isCoach = this.coachDate.every(function (item: any) {
      return item.isSelected === true
    })
    this.getCheckedItemListCoachs()
  }

  getCheckedItemListCoachs() {
    this.CoachList = []
    this.selectedCoach = []
    for (let i = 0; i < this.coachDate.length; i++) {
      if (this.coachDate[i].isSelected) {
        this.selectedCoach.push(this.coachDate[i])
      }
    }
    this.selectedCoach.forEach((goal: any) => {
      this.CoachList.push(goal.id)
    })
  }

  // fitness

  checkUncheckAllFitness() {
    for (let i = 0; i < this.data[0].length; i++) {
      this.data[0][i].isSelected = this.isFitness
    }
    this.getCheckedItemListFitness()
  }

  isAllSelectedFitness() {
    this.isFitness = this.data[0].every(function (item: any) {
      return item.isSelected === true
    })
    this.getCheckedItemListFitness()
  }

  getCheckedItemListFitness() {
    this.CoursList = []
    this.selectedCours = []
    for (let i = 0; i < this.data[0].length; i++) {
      if (this.data[0][i].isSelected) {
        this.selectedCours.push(this.data[0][i])
      }
    }
    this.selectedCours.forEach((cours: any) => {
      this.CoursList.push(cours.id)
    })
  }

  // duration

  checkUncheckAllDurations() {
    for (let i = 0; i < this.durations.length; i++) {
      this.durations[i].isSelected = this.isDuration
    }
    this.getCheckedItemListDurations()
  }

  isAllSelectedDurations() {
    this.isDuration = this.durations.every(function (item: any) {
      return item.isSelected === true
    })
    this.getCheckedItemListDurations()
  }

  getCheckedItemListDurations() {
    this.DurationList = []
    this.selectedDuration = []
    for (let i = 0; i < this.durations.length; i++) {
      if (this.durations[i].isSelected) {
        this.selectedDuration.push(this.durations[i])
      }
    }
    this.selectedDuration.forEach((duration: any) => {
      this.DurationList.push(duration.id)
    })
  }

  // intensity

  checkUncheckAllIntensitys() {
    for (let i = 0; i < this.intensitys.length; i++) {
      this.intensitys[i].isSelected = this.isIntensity
    }
    this.getCheckedItemListIntensitys()
  }

  isAllSelectedIntensitys() {
    this.isIntensity = this.intensitys.every(function (item: any) {
      return item.isSelected === true
    })
    this.getCheckedItemListIntensitys()
  }

  getCheckedItemListIntensitys() {
    this.IntensityList = []
    this.selectedIntensity = []
    for (let i = 0; i < this.intensitys.length; i++) {
      if (this.intensitys[i].isSelected) {
        this.selectedIntensity.push(this.intensitys[i])
      }
    }
    this.selectedIntensity.forEach((intensity: any) => {
      this.IntensityList.push(intensity.id)
    })
  }

  //
  ResetSearch() {
    this.CoursList = []
    this.CoachList = []
    this.IntensityList = []
    this.DurationList = []
    this.GoalList = []
    this.isGoal = false
    this.isCoach = false
    this.isDuration = false
    this.isFitness = false
    this.isIntensity = false
    for (let i = 0; i < this.goals.length; i++) {
      this.goals[i].isSelected = false
    }
    for (let i = 0; i < this.coachDate.length; i++) {
      this.coachDate[i].isSelected = false
    }
    for (let i = 0; i < this.data[0].length; i++) {
      this.data[0][i].isSelected = false
    }
    for (let i = 0; i < this.durations.length; i++) {
      this.durations[i].isSelected = false
    }
    for (let i = 0; i < this.intensitys.length; i++) {
      this.intensitys[i].isSelected = false
    }
  }

}
