import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ColorSessionService {

  constructor() { }

  hexToRgb(hex: string): any {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b
    })
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null
  }

  brightness(r: number, g: number, b: number): number {
    return (r * 299 + g * 587 + b * 114) / 1000
  }

  brighterThan(r: number, g: number, b: number, x: number): boolean {
    return this.brightness(r, g, b) > x
  }

  getTextColorHex(hex: string): string {
    const rgb = this.hexToRgb(hex)
    return this.getTextColor(rgb.r, rgb.g, rgb.b)
  }

  getTextColor(r: number, g: number, b: number): string {
    if (this.brighterThan(r, g, b, 180)) {
      return 'rgb(0, 0, 0)'
    } else {
      return 'rgb(255, 255, 255)'
    }
  }

  getHexTextColor(color: string): string {
    if (color === '' || color === null) {
      return '#000000'
    }
    const rgb = this.hexToRgb(color)
    if (rgb === null) {
      return '#000000'
    }
    if (this.brighterThan(rgb.r, rgb.g, rgb.b, 180)) {
      return '#000000'
    } else {
      return '#ffffff'
    }
  }
}
