import { Component, OnInit } from '@angular/core'
import { DataStorageService } from 'src/app/services/data-storage.service'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent implements OnInit {

  public userRole: any = null
  public sportsList: any = []

  constructor(
    private tokenStorage: TokenStorageService,
    private dataStorage: DataStorageService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    console.log('ngOnInit', 'FooterSectionComponent' );
   
  }

  openCGU() {
    this.router.navigate(['/terms-and-conditions']);
  }


}
