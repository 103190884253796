import { Injectable } from '@angular/core'

const TOKEN_KEY = 'auth-token'
const USER_KEY = 'auth-user'
const DATA_KEY = 'credentials'
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  currentUser: any
  constructor() { }

  signOut(): void {
    window.localStorage.clear()
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY)
    window.localStorage.setItem(TOKEN_KEY, token)
  }

  public getToken(): string {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token !== null) {
      return token
    }
    return ''
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY)
    window.localStorage.setItem(USER_KEY, JSON.stringify(user))
  }

  public saveCredentials(data: any): void {
    window.localStorage.removeItem(DATA_KEY)
    window.localStorage.setItem(DATA_KEY, JSON.stringify(data))
  }
  public getUser(): any {
    let json = localStorage.getItem(USER_KEY)
    if (json === null) {
      json = '{}'
    }
    this.currentUser = JSON.parse(json)
    return this.currentUser
  }
}
