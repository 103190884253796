import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StepsModule } from 'primeng/steps'
import { TabViewModule } from 'primeng/tabview'
import { ButtonModule } from 'primeng/button'
import { CardModule } from 'primeng/card'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { InputMaskModule } from 'primeng/inputmask'
import { CheckboxModule } from 'primeng/checkbox'
import { ToastModule } from 'primeng/toast'
import { CalendarModule } from 'primeng/calendar'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message'
import { PasswordModule } from 'primeng/password'
import { DividerModule } from 'primeng/divider'
import { TabMenuModule } from 'primeng/tabmenu'
import { RatingModule } from 'primeng/rating'
import { PanelModule } from 'primeng/panel'
import { MenuModule } from 'primeng/menu'
import { RadioButtonModule } from 'primeng/radiobutton'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { RippleModule } from 'primeng/ripple'
import { CarouselModule } from 'primeng/carousel'
import { AccordionModule } from 'primeng/accordion'
import { SkeletonModule } from 'primeng/skeleton'
import { DataViewModule } from 'primeng/dataview'
import { DialogModule } from 'primeng/dialog'
import { BadgeModule } from 'primeng/badge'
import { ScrollTopModule } from 'primeng/scrolltop'
import { ScrollPanelModule } from 'primeng/scrollpanel'
import { FullCalendarModule } from 'primeng/fullcalendar'
import { NgxPaginationModule } from 'ngx-pagination'
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenubarModule } from 'primeng/menubar';


@NgModule({
	imports: [
		CommonModule,
		StepsModule,
		TabViewModule,
		ButtonModule,
		CardModule,
		InputTextModule,
		DropdownModule,
		InputMaskModule,
		CheckboxModule,
		ToastModule,
		FormsModule,
		CalendarModule,
		// NgxIntlTelInputModule,
		ReactiveFormsModule,
		MessagesModule,
		MessageModule,
		PasswordModule,
		DividerModule,
		TabMenuModule,
		RatingModule,
		PanelModule,
		MenuModule,
		RadioButtonModule,
		ConfirmDialogModule,
		RippleModule,
		CarouselModule,
		AccordionModule,
		SkeletonModule,
		DataViewModule,
		DialogModule,
		BadgeModule,
		ScrollTopModule,
		ScrollPanelModule,
		FullCalendarModule,
		NgxPaginationModule,
		ConfirmPopupModule,
		ContextMenuModule,
		MenubarModule
	],

	exports: [
		CommonModule,
		StepsModule,
		TabViewModule,
		ButtonModule,
		CardModule,
		InputTextModule,
		DropdownModule,
		InputMaskModule,
		CheckboxModule,
		ToastModule,
		FormsModule,
		CalendarModule,
		// NgxIntlTelInputModule,
		ReactiveFormsModule,
		MessagesModule,
		PasswordModule,
		DividerModule,
		TabMenuModule,
		RatingModule,
		PanelModule,
		MenuModule,
		RadioButtonModule,
		ConfirmDialogModule,
		CarouselModule,
		AccordionModule,
		SkeletonModule,
		DataViewModule,
		DialogModule,
		BadgeModule,
		ScrollTopModule,
		ScrollPanelModule,
		FullCalendarModule,
		NgxPaginationModule,
		ConfirmPopupModule,
		ContextMenuModule,
		MenubarModule
	],
	declarations: [
	],
	providers: [ConfirmationService, MessageService], schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrimeNgImplementation { }
