import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
}

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  private uri = `${environment.apiUrl}`
  private path = '/public-coach-actions'
  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }
  getCoachs() {
    return this.http.get(this.uri + `/public-lists/coaches`, this.httpOptions)
  }

  getCoachInfo() {
    console.log('token',  this.httpOptions)
    return this.http.get(this.uri + this.path , this.httpOptions)
  }

  putCoachInfo(data: any): Observable<any> {
    return this.http.put(
      this.uri + this.path,
      data,
      this.httpOptions
    )
  }

}
