import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
  private controller = '/shows'
  private uri = `${environment.apiUrl}`

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getAll(): Observable<any> {
    return this.http.get(this.uri + this.controller, this.httpOptions)
  }
}
