import { Component, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MenuItem, Message, ConfirmationService } from 'primeng/api'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { MemberServiceService } from 'src/app/services/member/member-service.service'
import { UserService } from 'src/app/services/user/user.service'
import { User } from '../../../interfaces/user.interface'
import { TokenStorageService } from 'src/app/services/token-storage.service'
import { CoachService } from 'src/app/services/coach/coach.service'
import { environment } from '../../../../environments/environment.prod'
import { DataStorageService } from '../../../services/data-storage.service'
import { Coach } from '../../../interfaces/coach.interface'
import { FavoritesService } from '../../../services/favorites/favorites.service'
import { SessionService } from '../../../services/sessions-member/session.service'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ReservationService } from '../../../services/reservation/reservation.service'
import { TranslateService } from '@ngx-translate/core'
import { VideosService } from '../../../services/videos/videos.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FitnessClassService } from 'src/app/services/fitness-class/fitness-class.service'

@Component({
  selector: 'app-abonnement-view',
  templateUrl: './abonnement-view.component.html',
  styleUrls: ['./abonnement-view.component.scss']
})
export class AbonnementViewComponent implements OnInit {

  public getFitnessSessionFav: any = []
  public getVideoFav: any = []
  public itemsCoach!: MenuItem[]
  public option = []
  public userClaims: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
    job: '',
    zipCode: '',
    address: '',
  }
  public message: Message[] = []
  public accountUpdated = false
  public selectedCountry: any
  public selectedCity: any
  public selectedLanguage: any
  public selectedGender: any = null
  public countries: any[] = []
  public cities: any[] = []
  public languages: any[] = []
  public user: User = {
    cityId: 0,
    countryId: 0,
    email: '',
    firstName: '',
    gender: '',
    languageId: 0,
    lastName: '',
    phone: '',
    picture: '',
    username: '',
    job: '',
    zipCode: '',
    address: '',
  }
  public fileToUpload!: File
  public images: any = null
  public appUrl = environment.filesUrl
  public genders = ['Male', 'Female']
  public imageMessage: Message[] = []
  public filename: any
  public userRole: any = null
  public coach: any
  public profile: any = null
  public coachFav: any
  public fileUrl = `${environment.filesUrl}`
  public p = 0
  public coachs: Coach[] = []
  public coachsId: any = []
  public filterCoach: any
  public fitnessClass: any = []
  public fitnessData: any
  public fitnessSessionReservation: any = []
  public entity = 'Coach'
  public info = this.translate.instant('profile.navBar.INFO')
  public planning = this.translate.instant('profile.navBar.PLANING')
  public cours = this.translate.instant('profile.navBar.COURS')
  public sport = this.translate.instant('profile.navBar.SPORT')
  public objectif = this.translate.instant('profile.navBar.OBJETCIF')
  public coachMenu = this.translate.instant('profile.navBar.COACH')
  public reservation = this.translate.instant('profile.navBar.RESERVATION')
  public videos: any = []
  public loading = false
  public video: any
  filterClass: any
  public fitnessClassData: any = []
  public favaDta: any = []
  public favArray: any = []
  responsiveOptions: any

  constructor(
    private confirmationService: ConfirmationService,
    private profileService: UserService,
    private commomService: CommonServices,
    private memberInfo: MemberServiceService,
    private tokenStorage: TokenStorageService,
    private coachService: CoachService,
    private dataStorage: DataStorageService,
    private favoriteService: FavoritesService,
    private fitnessSession: SessionService,
    private reservationServcie: ReservationService,
    private translate: TranslateService,
    public videosService: VideosService,
    private router: Router,
    private fitnessClassservice: FitnessClassService,

  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
  }
  ngOnInit(): void {

    this.user = this.tokenStorage.getUser()
    console.log('user', this.user)
    this.coachService.getCoachInfo().subscribe(
      (data: any) => {
        console.log('data', data)
        this.profile = data
        if (data.photo != null) {
          this.images = this.appUrl + data.photo
        }
        const datavideo = {
          coaches: [this.profile.id]
        }
        this.videosService.getCoachVideo(datavideo).subscribe(
          async (videos: any) => {
            this.videos = videos.results
            console.log('videos', this.videos)

            // this.loading = false
            for (let i = 0; i < this.videos.length; i++) {
              this.videos[i].file = environment.filesUrl + '/' + this.videos[i].file
              this.videos[i].picture = environment.filesUrl + '/' + this.videos[i].picture
              this.videos[i].duration = this.commomService.convertTime(this.videos[i].duration, undefined)
              if (this.videos[i].totalBookmarks === undefined) {
                this.videos[i]['totalBookmarks'] = 0
              }
              if (this.videos[i].totalComments === undefined) {
                this.videos[i]['totalComments'] = 0
              }
              if (this.videos[i].totalBookmarks === undefined) {
                this.videos[i]['totalBookmarks'] = 0
              }
              if (this.videos[i].totalDislikes === undefined) {
                this.videos[i]['totalDislikes'] = 0
              }
              if (this.videos[i].totalDownloads === undefined) {
                this.videos[i]['totalDownloads'] = 0
              }
              if (this.videos[i].totalHits === undefined) {
                this.videos[i]['totalHits'] = 0
              }
              if (this.videos[i].totalLikes === undefined) {
                this.videos[i]['totalLikes'] = 0
              }
              if (this.videos[i].totalPreviewed === undefined) {
                this.videos[i]['totalPreviewed'] = 0
              }
       
            }
           
          },
          (err) => {
            // this.loading = false
          }
        )
      },
      (err) => {
        console.log('err', err)

        // this.commomService.handleResponse(err) Sa7booch_Fit2047
      }
    )
  }



  showVideo(video: any) {
    this.video = video
    console.log('video', video)
    this.router.navigate(['/video/' + video.id])
  }


}
