import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private uri = `${environment.apiUrl}/languages`

  constructor(
    private http: HttpClient,
  ) {

  }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getLanguage() {
    return this.http.get(this.uri, this.httpOptions)
  }

}
