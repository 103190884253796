<div class="container">
  <p-toast></p-toast>
</div>
<section class="cards">
  <div *ngFor="let goal of goalsData ">
    <div class="card">
      <div class="card__content">
        <button (click)="onChange(goal.id)"
          [ngClass]="goal.isFavorite === true ? 'button_sport' : 'button_sport_scale'">
          <div [ngClass]="goal.isFavorite === true ? 'card__image' : 'card__image_scale'"
            style="background-image: url({{fileUrl+goal.icon}});">
          </div><br>
          {{goal.name}}<br>{{goal.description}}
        </button>
      </div>
    </div>
  </div>
</section>
<div class="text-center">
  <button type="submit"  class="button"  (click)="updateSports()">MODIFIER MES objectifs</button>


</div>