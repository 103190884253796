import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-programme-view',
  templateUrl: './programme-view.component.html',
  styleUrls: ['./programme-view.component.scss']
})
export class ProgrammeViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('ngOnInit', 'ProgrammeViewComponent')
  }

  getSport(id: any) {
    const data = [id, 'all', false]
    return data
  }
}
