import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-afq-view',
  templateUrl: './afq-view.component.html',
  styleUrls: ['./afq-view.component.scss']
})
export class AFQViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
