<div class="container">
  <p-toast></p-toast>
</div>
<section class="cards">
<div *ngFor="let sport of sportsData ">
    <div class="card" (click)="onChange(sport.id)" >
      <div [ngClass]="sport.isFavorite === true ? 'card__image' : 'card__image_scale'" style="background-image: url({{fileUrl+sport.picture}});">
      </div>
      <div class="card__content">
      <div class="card__title"></div>
      <button (click)="onChange(sport.id)" [ngClass]="sport.isFavorite === true ? 'button_sport' : 'button_sport_scale'">{{sport.name}}</button>
    </div>
  </div>
</div>
  </section>
  <div class="text-center">
    <p-button type="submit" styleClass="p-button-lg" (click)="updateSports()" label="MODIFIER MES SPORT"
    icon="pi pi-angle-right" iconPos="right"></p-button>
  </div>


