import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  private uri = `${environment.apiUrl}`
  private controller = '/public-post/get-post'
  public controller_serach = '/public-post/search-posts'
  public controller_get = '/posts?offset=0&limit=100'
  // public controller_get = '/public-post/posts'

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }


  getAll() {
    return this.http.get(this.uri + this.controller_get, this.httpOptions)
  }

  getPostById(id: number) {
    return this.http.get(this.uri + this.controller + `/${id}`)
  }

  getPosts(data: any) {
    return this.http.post(this.uri + this.controller_serach, data, this.httpOptions)
  }


  getPost(id: number) {
    return this.http.get(this.uri + '/posts' + `/${id}`);
  }

}
