<div class="modal-body">
    <div class="text-right cross"> <i class="fa fa-times mr-2" (click)="activeModal.dismiss('Cross click')"></i> </div>
    <div class="card-body text-center"> <img src="assets/giphy.gif" height="100" width="100">
        <div class="comment-box text-center">
            <h4>{{ 'review.REVIEW' | translate }} {{ data.entity }} {{ data.name }} ?</h4>
            <h6 class="mt-5">{{ 'NOTE' | translate }} :</h6>
            <div class="rating">
                <ngb-rating [(rate)]="currentRate" [max]="5" [(ngModel)]="data.rate" required>
                    <ng-template let-fill="fill" let-index="index">
                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                    </ng-template>
                </ngb-rating>

            </div>
            <h6 class="text-left">{{ 'REVIEW' | translate }} :</h6>

            <div class="comment-area">
                <!--<input type="text" class="form-control" size="20" placeholder="Your Comment" [(ngModel)]="data.subject"
                    required />
                <br>
               <textarea class="form-control" placeholder="Your Review" rows="4" [(ngModel)]="data.body"
                    required></textarea>-->
                <textarea class="form-control" placeholder="{{ 'review.ADDCOMMENT' | translate }}" rows="4"
                    [(ngModel)]="data.body" required></textarea>
            </div>
            <div class="row">

                <div class="col">
                    <div class="text-center mt-4"> <button class="btn btn-outline-primary px-5"
                            (click)="activeModal.dismiss('Cross click')">{{
                            'review.CANCELREVIEW' | translate }}</button> </div>
                </div>
                <div class="col">
                    <div class="text-center mt-4"> <button class="btn btn-primary send px-5"
                            (click)="setRating(data)">{{
                            'review.SAVEREVIEW' | translate }}</button> </div>
                </div>
            </div>

            <div class="message-style">
                <div *ngIf="RatingFailed">
                    <p-messages [(value)]="message" [enableService]="false"></p-messages>
                </div>
            </div>
        </div>
    </div>
</div>