import { Injectable } from '@angular/core'
import { Coach } from '../interfaces/coach.interface'

const SPORT_KEY = 'sport-data'
const COACH_KEY = 'coach-data'
const FITNESS_CLASS_KEY = 'fitness-class-data'
const fITNESS_SESSION_KEY = 'fitness-session-data'
const IdSport = 'sport-id-data'
const DATA_KEY = 'credentials'

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  constructor() { }

  public saveData(data: any, DATA_KEY: any): void {
    window.localStorage.removeItem(DATA_KEY)
    window.localStorage.setItem(DATA_KEY, JSON.stringify(data))
  }
  public getData(DATA_KEY: any): string {
    const data = localStorage.getItem(DATA_KEY)
    if (data !== null) {
      return data
    }
    return ''
  }

  public saveFitnessClass(fitnessClass: string): void {
    window.localStorage.removeItem(FITNESS_CLASS_KEY)
    window.localStorage.setItem(FITNESS_CLASS_KEY, JSON.stringify(fitnessClass))
  }

  public getFitnessClass(): string {
    const fitnessClass = localStorage.getItem(FITNESS_CLASS_KEY)
    if (fitnessClass !== null) {
      return fitnessClass
    }
    return ''
  }

  public saveFitnessSession(fitnessSession: string): void {
    window.localStorage.removeItem(fITNESS_SESSION_KEY)
    window.localStorage.setItem(fITNESS_SESSION_KEY, JSON.stringify(fitnessSession))
  }

  public getFitnessSession(): string {
    const fitnessSession = localStorage.getItem(fITNESS_SESSION_KEY)
    if (fitnessSession !== null) {
      return fitnessSession
    }
    return ''
  }

  public saveCoach(coach: any): void {
    window.localStorage.removeItem(COACH_KEY)
    window.localStorage.setItem(COACH_KEY, JSON.stringify(coach))
  }

  public getCoach(): any {
    const coach = localStorage.getItem(COACH_KEY)
    if (coach !== null) {
      return coach
    }
    return ''
  }

  public saveIdSport(id: any): void {
    window.localStorage.removeItem(IdSport)
    window.localStorage.setItem(IdSport, id)
  }

  public getIdSport(): string {
    const id = localStorage.getItem(IdSport)
    if (id !== null) {
      return id
    }
    return ''
  }

  public getUserData(): any {
    const user = localStorage.getItem(DATA_KEY)
    if (user !== null) {
      return user
    }
    return ''
  }

}
