<div class="hero" style="background-image: url('https://assets.freeletics.com/images/landing-page/hero-video.jpg')">
  <h1>{{ 'programm.DISCOVERHEALTHYTRAINING' | translate }}<br> {{'programm.NEWPROGRAMM' | translate }}
    <br><br>
    <p style="font-size:20px;"> {{ 'programm.TEST' | translate }}
    </p>
  </h1>
</div>

<div class="row">
  <app-filter-view [data]="getData(fitnessData)" style="    background-color: white;"></app-filter-view>




</div>