<div class="modal-body">
    <div class="text-right cross"> <i class="fa fa-times mr-2" (click)="activeModal.dismiss('Cross click')"></i> </div>
    <div class="row justify-content-evenly">
        <h4>{{ comments.length }} {{ 'review.COMMENTS' | translate }}</h4>

        <ul class="list" *ngFor="let comment of comments">
            <li>
                <div class="list-imag" *ngIf="comment.user.picture != undefined">
                    <img class="img-user" src="{{ comment.user.picture }}" alt="" />
                </div>
                <div class="list-comments">
                    <ul>
                        <li>{{ comment.user.firstName }} {{ comment.user.lastName }}</li> &nbsp;
                        <li class="text-comment">{{ comment.fromNow }} </li>
                        <li>
                            <ngb-rating [(rate)]="comment.value" readonly="true" max="5" class="star"></ngb-rating>
                        </li>
                    </ul>
                    <!--<p>{{ comment.body }}</p>-->
                    <p>{{ comment.body }}</p>
                </div>
            </li>
        </ul>
        <div class="text" *ngIf="comments.length > 8">
            <p-button (click)="show = comments.length" icon="pi pi-plus" label="load More">
            </p-button>
        </div>
    </div>
</div>