import { Component, OnInit } from '@angular/core'
import { PrimeNGConfig, MessageService } from 'primeng/api'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { MemberServiceService } from 'src/app/services/member/member-service.service'
import { SportService } from 'src/app/services/sport/sport.service'
import { environment } from '../../../../environments/environment.prod'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-fitness-goals-view',
  templateUrl: './fitness-goals-view.component.html',
  styleUrls: ['./fitness-goals-view.component.scss']
})
export class FitnessGoalsViewComponent implements OnInit {
  goals: any
  fileUrl = `${environment.filesUrl}`
  members: any[] = []
  goalsData: any = []
  goalsIds: any = []
  constructor(private commomService: CommonServices, private messageService: MessageService,
    private sportService: SportService, private memberInfo: MemberServiceService,     private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.memberInfo.getMemberInfo().subscribe((members: any) => {
      this.sportService.getGoalsList().subscribe((data: any) => {
        this.goals = data
        this.members = members.fitnessGoals
        for (const member of this.members) {
          this.goalsIds.push(member.id)
        }
        for (const goal of this.goals) {
          if (this.goalsIds.includes(goal.id)) {
            this.goalsData.push({
              id: goal.id,
              name: goal.name,
              icon: goal.icon,
              description: goal.description,
              isFavorite: true
            })
          }
          else {
            this.goalsData.push({
              id: goal.id,
              name: goal.name,
              icon: goal.icon,
              description: goal.description,
              isFavorite: false
            })
          }
        }
      },
        err => {
          this.commomService.handleResponse(err)
        })
    },
      err => {
        this.commomService.handleResponse(err)
      })
  }

  onChange(id: number) {
    for (const goal of this.goalsData) {
      if (goal.id === id) {
        goal.isFavorite = !goal.isFavorite
        if (goal.isFavorite) {
          this.goalsIds.push(id)
        }
        else {
          const index = this.goalsIds.indexOf(id)
          this.goalsIds.splice(index, 1)
        }
      }
    }
  }

  updateSports() {
    this.sportService.postGoalsList(this.goalsIds).subscribe((data: any) => {
      Swal.fire(this.translate.instant('TANKYOU'),
      this.translate.instant('Votre liste d’objectifs a été modifiée.'), 'success')
    },
      err => {
        this.commomService.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'),
        this.translate.instant('profile.PROFILENOTUPDATED'), 'error')
      })
  }
}

