import { Component, OnInit } from '@angular/core'
import { LoaderService } from '../../services/loader.service'

@Component({
  selector: 'app-loader',
  template: `
    <div id="preloader-active" *ngIf="show">
    <div class="preloader d-flex align-items-center justify-content-center">
        <div class="preloader-inner position-relative">
            <div class="preloader-circle"></div>
            <div class="preloader-img pere-text">
                <img src="assets/img/logo/loder.png" alt="">
            </div>
        </div>
    </div>
</div>`,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  show = false
  constructor(private _loaderService: LoaderService) { }

  ngOnInit(): void {
    this._loaderService.loadState.subscribe(res => {
      this.show = res
    })
  }
}
