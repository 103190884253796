import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('auth-user')) {
       setTimeout(() => {
        // this.router.navigate([this.router.url]);
      }, 150)
      return true
    }
    setTimeout(() => {
      // this.router.navigate(['/auth/member/login'])
      this.router.navigate(['/home'])


 }, 150)

    return false
  }

}
