<div class="main-background" *ngIf="currentSession === undefined">
  <div class="header-background">
    <header
      style="background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),url({{../../../../../../assets/images/e-fit-login/image-side.jpg);">
      <h3 class="live-time">
      </h3>
      <h2 class="live-time">
        <div class="ledContainer">
          <i class="fas fa-clock"></i><span class="liveText">&nbsp; {{"STREAMNOTFOUND" | translate}}</span>
        </div>
      </h2>
    </header>
  </div>
</div>
<div class="main-background" *ngIf="currentSession !== undefined">
  <div class="header-background">
    <header
      style="background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),url({{currentSession?.picture}});height: 500px;"
      *ngIf="!liveStart">
      <h3 class="live-time" *ngIf="!isLive">{{ 'STARTCLASS' | translate }}
        <span *ngIf="daysToDday != 0">{{daysToDday}} {{ 'DAY' |
          translate }}</span> {{hoursToDday}}h:
        {{minutesToDday}}m:{{secondsToDday}}s
      </h3>
      <h3 class="live-time" *ngIf="isLive">
        <div class="ledContainer">
          <i class="recordLed live"></i><span class="liveText">&nbsp; {{ 'sport.LIVE' | translate }} {{
            currentSession.fromNow }}</span>
        </div>
      </h3>
      <div class="session-margin" *ngIf="currentSession !== undefined">
        <h1 text-center>
          {{ currentSession?.name }}
          {{ currentSession?.startTime | date: displayDateTimeFormat}}
        </h1>
      </div>
    </header>
    <div class="row" *ngIf="liveStart">
      <h3 class="live-title">
        <div class="ledContainer">
          <i class="recordLed live"></i><span class="liveText">&nbsp;
            {{ currentSession?.name }}</span>
        </div>
      </h3>
    </div>
    <p-button type="submit" styleClass="p-button-lg" label="{{ 'REJOIN' | translate }}  " class="btn-top"
      icon="pi pi-angle-right" iconPos="right" *ngIf="isLive && !liveStart || !isLive "
      (click)="goLive(isLive,currentSession)"></p-button>
    <video id="video" [ngClass]="liveStart ? 'video-show' : 'video-dislay'" style="width: 100%; height: 500px"
      controls controlsList="nodownload"></video>
    <div class="card" *ngIf="liveStart">
      <main class="card__user">
        <div *ngIf="coachdata[0].photo !== undefined">
          <img src="{{fileUrl+coachdata[0].photo}}" alt="" class="card__user-image">
        </div>
        <div *ngIf="coachdata[0].photo === undefined">
          <img src="assets/images/e-fit-default-image/coach.png" alt="" class="card__user-image">
        </div>
        <div class="card__user-info">
          <h2 class="card__user-info__name">{{ coachdata[0].name }}</h2>
          <h3 class="card__user-info__stats">{{ coachdata[0].description }}</h3>
          <app-public-rating [id]="coachdata[0].id" [entity]="entity" [rate]="coachdata[0].ratingTotal"
            [name]="coachdata[0].name"></app-public-rating>
          <h3 class="card__user-info__desc">{{ currentSession.description }}</h3>
        </div>
        <div class="card__user-actions">
          <button class="card__user-actions-follow" (click)="coachProfileNav(coachdata[0].name,coachdata[0].id)">S'AVOIR
            PLUS</button>
        </div>
      </main>
      <div class="center-review">
        <app-review-view [id]="currentSession.id" [entity]="entity"></app-review-view>
      </div>
    </div>
    <p-carousel [value]="sessions" [numVisible]="4" [numScroll]="4" [circular]="true" [autoplayInterval]="3000"
      [responsiveOptions]="responsiveOptions">
      <ng-template pTemplate="header">
        <h2 class="direct-text">DIRECTS À VENIR</h2>
      </ng-template>
      <ng-template let-session pTemplate="item">
        <div class="main-container_tab"
          style="background-image: url('{{ session.picture }}'); border-bottom: 4px solid {{ session.color}}">
          <div class="product-item-content">
            <div class="main-container_tab_datetitle">
              <div class="main-container_tab_date">
                {{ 'THE' | translate }} {{ session.startTime | date: "shortDate" }} {{ 'AT' | translate }}
                {{ session.startTime | date: "shortTime" }}
              </div>
            </div>
          </div>
        </div>
        <p>{{session.name}}</p>
      </ng-template>
    </p-carousel>
  </div>