import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private uri = `${environment.apiUrl}`
  private uril = `${environment.filesUrl}`

  constructor(private http: HttpClient) { }

  login(credentials: any): Observable<any> {
    return this.http.post(this.uri + `/login`, credentials, httpOptions)
  }

  register(data: any): Observable<any> {

    return this.http.post(this.uri + `/bulk`, data, httpOptions)
  }

  resetPaasword(data: any): Observable<any> {
    return this.http.post(this.uri + `/forgot-password`, data, httpOptions)
  }

  logout(): void {
    // window.localStorage.clear()
    localStorage.removeItem('auth-token')
    localStorage.removeItem('hasLoggedIn')
  }

  confirmOpenId(code: any) {
    // return this.http.post(
    //   'https://payment.eklectic.tn/API/oauth/token?grant_type=authorization_code&redirect_uri=&client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&client_secret=0d04cb1c6a0c41202bfcc8789a0392b5&' + 
    //   'code=' + code ,
    //   code
    // );
    return this.http.post(this.uri + `/forgot-password`, code, httpOptions)
  
  }

  createNewAccessUser(data: any): Observable<any>  {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.uri + `/register`, data, httpOptions)

  }
  createNewMember(data: any): Observable<any>  {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.uri + `/members`, data, httpOptions)

  }
}
