import { Injectable , EventEmitter, Output} from '@angular/core'
import { Router } from '@angular/router'
import { FavoritesService } from './favorites/favorites.service'
import { CommonServices } from './common/common-service.service'
import { SportService } from './sport/sport.service'
import { BehaviorSubject } from 'rxjs'
import { DataStorageService } from './data-storage.service'
import { LikesService } from './likes/likes.service'
import { RatesService } from 'src/app/services/rates/rates.service'

@Injectable({
  providedIn: 'root'
})

export class SharedServiceService {
  @Output() fire: EventEmitter<any> = new EventEmitter()
  authchange = false
  object: any = []
  data: any
  idSport: any
  urlValue: any
  sportName = ''
  fitnessSession: any
  public sports: any = []
  filterCoach: any
  private messageSource = new BehaviorSubject('default message')
  currentMessage = this.messageSource.asObservable()
  public likeData: any

  constructor(private route: Router,
    public favoriteService: FavoritesService,
    public commonServices: CommonServices,
    public sportService: SportService,
    public dataStarage: DataStorageService,
    public likeService: LikesService,
    public ratingService: RatesService
  ) {
  }
  ngOnInit(): void {
    this.getSportId()

  }

  sendObject(object: any) {
    this.messageSource.next(object)
  }
  change() {
    if (this.route.url == 'auth/register') {
      return true
    } else {
      return false
    }
  }

  getEmittedValue() {
    return this.fire
  }

  // getLikeService(entity: any){
  //   let data: any = []
  //   this.likeService.getLikebyEntity(entity).subscribe(
  //     (likeResponse: any) => {
  //       console.log('shared data ', likeResponse)

  //       const fitnessSessionLike = likeResponse
  //       for (const i in fitnessSessionLike) {
  //         data.push(fitnessSessionLike[i].foreignKey)
  //       }
  //     },
  //     (error: any) => {
  //      data = []
  //     }
  //   )
  //   console.log('shared data ', data)
  //   return data
  // }

  getsportName(): any {
    let url = this.route.url
    const sportName = url.split('/')
    const re = /-/gi
    url = sportName[2]
    this.sportName = url.replace(re, ' ')
    console.log('sportName', this.sportName)
    return this.sportName
  }

  getSportId(): any {
    this.getsportName()
    this.sportService.getSportList().subscribe((data: any) => {
      console.log('sportName', this.sportName, data)

      for (const i in data) {
        console.log('sportName', this.sportName, data[i].name)

        if (data[i].name === this.sportName) {
          this.sports = data[i]
          break
        }
      }
    })
    return this.sports
  }

}

