<div class="main-background" *ngIf="sessions.length === 0">
  <div class="header-background">
    <header
      style="background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),url({{../../../../../../assets/images/e-fit-video/noVideos.png);">
      <h3 class="live-time">
      </h3>
      <h2 class="live-time">
        <div class="ledContainer">
          <i class="fas fa-video"></i><span class="liveText">&nbsp; {{"NOSESSIONS" | translate}}</span>
        </div>
      </h2>
    </header>
  </div>
</div>
<div class="container" *ngIf="sessions.length > 0">
  <div>
  </div>
  <section class="calendar">
    <p-fullCalendar [options]="options" (click)="eventClicked($event)"></p-fullCalendar>



    <div class="calendar">
  <div class="week" *ngFor="let day of calendarDays">
    <div class="day" *ngFor="let event of getEventsByDay(day)">
      <div class="event">
        <div class="event-details">
          <h3>{{ event.fitnessClass.name }}</h3>
          <p>{{ event.startTime | date: 'hh:mm a' }}</p>
          <!-- Ajoutez d'autres détails de l'événement ici -->
        </div>
      </div>
    </div>
  </div>
</div>

    
<div class="calendar">
  <div class="week" *ngFor="let day of calendarDays">
    <div class="day">{{day}}
      <p>{{ day.date | date: 'dd/MM/yyyy' }}</p>
      <div class="event" *ngFor="let event of day.events">
        <div class="event-details">
          <h3>{{ event.fitnessClass.name }}</h3>
          <p>{{ event.startTime | date: 'hh:mm a' }}</p>
          <!-- Ajoutez d'autres détails de l'événement ici -->
        </div>
      </div>
    </div>
  </div>
</div>

  </section>
</div>