<!-- <div class="main-background" *ngIf="videos.length === 0">
    <div class="header-background">
        <header
            style="background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),url({{../../../../../../assets/images/e-fit-video/noVideos.png);">
            <h3 class="live-time">
            </h3>
            <h2 class="live-time">
                <div class="ledContainer">
                    <i class="fas fa-video"></i><span class="liveText">&nbsp; {{"VIDEONOTFOUND" | translate}}</span>
                </div>
            </h2>
        </header>
    </div>
</div> -->

<div class="hero" style="background-image: url('assets/img/gallery/section_bg01.png'); height: 50rem;">

</div>
<div class="container pt-5" *ngIf="videos.length > 0">



    <ul class="cards">
        <li class="cards__item" *ngFor="let video of videos  | slice:0:show; let i=index">
            <div class="card">
                <div class="card__image card__image--fence" (click)="showVideo(video)"
                    style="background-image: url('{{ video.picture }}'); "></div>
                <div class="card__content">
                    <div class="card__title">{{ video.name }}</div>
                    <p class="card__text">This is the shorthand for flex-grow, flex-shrink and flex-basis combined. The
                        second and third parameters (flex-shrink and flex-basis) are optional. Default is 0 1 auto. </p>
                    <button class="btn btn--block card__btn" (click)="showVideo(video)">Regarder la vidéo</button>
                </div>
            </div>
        </li>

    </ul>
    <div class="text-center pt-4 mb-4" *ngIf="videos.length > 8">
        <p-button (click)="show = videos.length" icon="pi pi-plus" label="load More">
        </p-button>
    </div>

</div>