<!--? slider Area Start-->
<div class="slider-area position-relative">
    <div class="slider-active">
        <!-- Single Slider -->
        <div class="single-slider slider-height d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-9 col-lg-9 col-md-10">
                        <div class="hero__caption">
                            <span data-animation="fadeInLeft" data-delay="0.1s">Club Fitness</span>
                            <h1 data-animation="fadeInLeft" data-delay="0.4s" style="text-align: left;">VOTRE SALLE DE
                                FITNESS EN LIGNE</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div style="z-index:0;" *ngIf="!userSer.isLoggedIn()"> -->
<div style="z-index:0;" *ngIf="!isLogin">

    <marquee direction="left" style="background-color: #1F2833 ; margin: 0%; padding: 0%;">
        <p style="color:goldenrod; border-radius: 5px;"><strong>Abonnement 0.450DT/ jour avec 3 jours
                GRATUITS pour toute nouvelle souscription</strong></p>
    </marquee>
</div>


<div class="wrapper">
    <div class="section-tittle text-center mb-55 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
        <h2>NOS SPORTS</h2>
    </div>
    <div class="gallery">
        <div class="gallery__item gallery__item--1">
            <a class="gallery__link" routerLink="/sport/Cardio">
                <img src="assets/images/sports/cardio.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Cardio Training</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--2">
            <a class="gallery__link" routerLink="/sport/Musculation">
                <img src="assets/images/sports/musculation.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Musculation & Cross Training</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--3">
            <a class="gallery__link" routerLink="/sport/Gym-&-Pilates">
                <img src="assets/images/sports/gym.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Gym & Pilates</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--4">
            <a class="gallery__link" routerLink="/sport/Boxe">
                <img src="assets/images/sports/boxe.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Boxe</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--5">
            <a class="gallery__link" routerLink="/sport/Danse">
                <img src="assets/images/sports/danse.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Danse</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--6">
            <a class="gallery__link" routerLink="/Challenge">
                <img src="assets/images/sports/challenge.jpeg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Challenge</span>
                </div>
            </a>
        </div>
        <div class="gallery__item gallery__item--7">
            <a class="gallery__link" routerLink="/sport/Kids">
                <img src="assets/images/sports/kids.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Kids</span>
                </div>
            </a>
        </div>
         <div class="gallery__item gallery__item--8">
            <a class="gallery__link" routerLink="/sport/Yoga">
                <img src="assets/images/sports/yoga.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Yoga</span>
                </div>
            </a>
        </div> 
        <div class="gallery__item gallery__item--9">
            <a class="gallery__link" routerLink="/sport/Cardio">
                <img src="assets/images/sports/cardio.jpg" class="gallery__image" />
                <div class="gallery__overlay">
                    <span>Cardio Training</span>
                </div>
            </a>
        </div>
    </div>

</div>
<div class="wrapper" *ngIf="isLogin">
    <div class="section-tittle text-center mb-55 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
        <h2>NOS COACHS</h2>
    </div>
    <div class="carousel-wrapper">
        <div class="corousel images-carousel">
            <div class="corousel__item" *ngFor="let coach of coachs">
                <img src="{{fileUrl+coach.photo}}" alt="" class="image">
                <h5 style="text-align: center;
            color: white;
            padding: 10px;">{{coach.name}}</h5>
            </div>

        </div>

    </div>
</div>



<!-- Gallery Area End -->

<!-- Courses area End -->
<!--? About Area-2 Start -->
<section class="about-area2 fix pb-padding pt-50 pb-80">
    <div class="support-wrapper align-items-center">
        <div class="right-content2">
            <!-- img -->
            <div class="right-img wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                <img src="assets/img/gallery/about.jpg" alt="">
            </div>
        </div>
        <div class="left-content2">
            <!-- section tittle -->
            <div class="section-tittle2 mb-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                <div class="front-text">
                    <h2 class="">Club FITNESS By TT</h2>
                    <p>Club Fitness est un service dédié à la diffusion de contenus de fitness en proposant des séances
                        d'entraînement avec des entraîneurs spécialisés !

                    <p class="mb-40"> Le service Club Fitness est ouvert à toute personne physique majeure. La société
                        éditrice du service Club Fitness se réserve le droit de procéder à toute vérification utile et
                        de demander l’autorisation écrite d’un représentant légal du mineur. A défaut de justifier d’une
                        telle autorisation, les commandes du mineur ne pourront pas être honorées.</p>

                    <!-- <a href="courses.html" class="border-btn">My Courses</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About Area End -->
<!--? Blog Area Start -->

<!-- Blog Area End -->
<!--? video_start -->
