import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-create-password-view',
  templateUrl: './create-password-view.component.html',
  styleUrls: ['./create-password-view.component.scss']
})
export class CreatePasswordViewComponent implements OnInit {
  public checked = false
  @Input() resetPassword = false

  constructor() { }

  ngOnInit(): void {
  }

}
