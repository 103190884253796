<div class="item active" *ngIf="post !== undefined">
    <div class="sport-image"
        style="background-image: url('{{ post.picture }}');">
        <div class="carousel-caption">
        </div>
    </div>
</div>
<h2>
    {{ post?.title }}
</h2>

<div class="navigation-title" *ngIf="post !== undefined">
    <!-- <h3 text-center>{{ post.name }}</h3> -->
    <div [innerHTML]="post.content" class="post" >
    </div>


</div>