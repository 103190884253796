import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { AuthService } from '../services/auth/auth.service'
import { Router } from '@angular/router'
import { TokenStorageService } from '../services/token-storage.service'
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService,
    public router: Router,
    public tokenStorage: TokenStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
      const userData =  this.tokenStorage.getUser()
      const token = userData.token
        if (token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            })
        }
        return next.handle(request).pipe(
            map((event) => {
                return event
            }),
            catchError(
                (
                    httpErrorResponse: HttpErrorResponse,
                    _: Observable<any>
                ) => {
                    if ( httpErrorResponse.status === 403) {
                        let userRole = this.tokenStorage.getUser().roles[1];
                        console.log('this.userRole', userRole)
                        if( userRole === 'ROLE_MEMBER') {
                              this.router.navigate(['/home'])
                              localStorage.clear();
                              window.location.reload()
                        
                        } else if (userRole === 'ROLE_COACH'){
                    //   this.router.navigate(['#/auth/member/login'])
                              this.router.navigate(['/auth/coach/login'])
                              localStorage.clear();
                              window.location.reload()
                        
                        }
                    }
                    return throwError(httpErrorResponse)
                }
            )
        )
    }
}
