import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-auth-image-common',
  templateUrl: './auth-image-common.component.html',
  styleUrls: ['./auth-image-common.component.scss']
})
export class AuthImageCommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
