import { Component, OnInit, Input } from '@angular/core'
import { DataStorageService } from 'src/app/services/data-storage.service'
import { FavoritesService } from '../../../../services/favorites/favorites.service'
import { CommonServices } from '../../../../services/common/common-service.service'
import { TranslateService } from '@ngx-translate/core'
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  @Input() isFav: any
  @Input() id: any
  @Input() entity: any
  @Input() storageName: any
  favData: any
  selectedFav = false
  filterData: any

  constructor(
    public dataStorage: DataStorageService,
    public favoriteService: FavoritesService,
    public commonServices: CommonServices,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.selectedFav = this.isFav
  }

  toggleSelected() {
    // toggle to change fav value
    this.selectedFav = !this.selectedFav
    // checking if storage name is sended or not to modify fav value on the local storage
    if (this.storageName !== undefined) {
      // get local storage data depend of storageName
      this.favData = JSON.parse(this.dataStorage.getData(this.storageName))
      this.filterData = this.favData.filter((x: any) => x.id === this.id)
      // checking if local storage value is favorite or not
      if (!this.filterData[0].favorite) {
        // if it isn't fav then add the favorite in the serveur and modify it on the local storage
        this.addFavorite()
        this.filterData[0].favorite = true
        localStorage.setItem(this.storageName, JSON.stringify(this.favData))
      }
      else {
        // then removing fav
        this.removeFavorite()
        this.filterData[0].favorite = false
        localStorage.setItem(this.storageName, JSON.stringify(this.favData))
      }
    }
    // the case where the storage name is undifined where we don't need to modify the value of fav only on the serveur
    else if ((this.storageName === undefined) && (this.selectedFav)) {
      this.addFavorite()
    }
    else {
      this.removeFavorite()
    }
  }
  addFavorite() {
    // send to the serveur the id and the entity that confirm to the specific component to add  fav action
    this.favoriteService.setFavoritesByEntityId(this.id, this.entity).subscribe((data: any) => {
      Swal.fire(this.translate.instant('TANKYOU'), this.translate.instant('FAVORITECREATED'), 'success')

    },
      err => {
        this.commonServices.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'), this.translate.instant('FAVORITENOTCREATED'), 'error')

      })
  }
  removeFavorite() {
    // send to the serveur the id and the entity that confirm to the specific component to delete  fav action
    this.favoriteService.unsetFavoritesByEntityId(this.id, this.entity).subscribe((data: any) => {
      Swal.fire(this.translate.instant('TANKYOU'), this.translate.instant('FAVORITEDELETED'), 'success')
    },
      err => {
        this.commonServices.handleResponse(err)
        Swal.fire(this.translate.instant('SORRY'), this.translate.instant('FAVORITENOTDELETED'), 'error')

      })
  }
}
