
  <div class="modal fade" id="passwordRequestModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'register.CONFIRMPASSWORD' | translate}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{"register.PHONESENT" | translate}}</p>
        <div class="p-field p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-lock"></i>
            </span>
            <span class="p-float-label">
              <p-password id="password" [toggleMask]="true" [required]="true" placeholder="{{'login.PASSWORD' | translate }}"></p-password>
            </span>
          </div>
        </div>
        <div class="p-field-checkbox" *ngIf="!resetPassword">
          <p-checkbox name="group1" value="New York" [(ngModel)]="checked" inputId="ny"></p-checkbox>
          <label for="ny">{{'register.TERMS' | translate}}</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal>Primary">{{
          'CANCEL' | translate }}</button>
        <button type="button" class="btn btn-primary">{{
          'register.REGISTEROK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
