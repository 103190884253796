import { Component, OnInit } from '@angular/core'
import { SessionsService } from '../../../services/sessions-coach/sessions.service'
import { CommonServices } from 'src/app/services/common/common-service.service'
import { Router, ActivatedRoute } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { VideosService } from '../../../services/videos/videos.service'
import * as  moment from 'moment'

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  public loading = false
  public session: any
  public videos: any = []
  public video: any
  public responsiveOptions: any
  public sessionId: any
  constructor(
    private commonServices: CommonServices,
    private router: Router,
    private route: ActivatedRoute,
    private sessionsService: SessionsService,
    private videosService: VideosService,
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
  }

  ngOnInit(): void {
    this.loading = true
    this.route.params.subscribe((params: any) => {
      console.log('id session', params, params['session-id'])

      if (typeof params['session-id'] !== 'undefined') {
        this.loading = false
        this.getSession(parseInt(params['session-id'], 10))
      }
    })

  }

  getSession(id: number): void {
    this.loading = true
    this.sessionsService.getSessionById(id).subscribe((response: any) => {
      if (response !== null) {
        this.session = response
        this.session.fitnessClass.picture = environment.filesUrl + this.session.fitnessClass.picture
        this.session.fitnessClass.ageRange.icon = environment.filesUrl + this.session.fitnessClass.ageRange.icon
        this.session.fitnessClass.intensity.icon = environment.filesUrl + this.session.fitnessClass.intensity.icon
        this.session.fitnessClass.classDuration.icon = environment.filesUrl + this.session.fitnessClass.classDuration.icon
        this.session.fitnessClass.sport.icon = environment.filesUrl + this.session.fitnessClass.sport.icon

        if (this.session.fitnessClass.content !== undefined) {
          this.session.fitnessClass.content = this.session.fitnessClass.content.replaceAll(
            /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
            ''
          )
        }
        this.sessionId = this.session.fitnessClass.id
        this.getVideos()
        this.loading = false
      }
    })
  }

  getVideos() {
    const data = {
      fitnessClasses: [this.sessionId],
      fitnessSessions: [],
      sports: [],
      coaches: [],
      intensities: [],
      classDurations: [],
      fitnessGoals: [],
      orderBy: [{ field: moment().format('L'), direction: 'DESC' }],
      limit: 10
    }
    this.videosService.getCoachVideo(data).subscribe(

      async (videos: any) => {
        this.videos = videos.results
        this.loading = false
        for (let i = 0; i < this.videos.length; i++) {
          if (this.videos[i].name !== undefined) {
            this.videos[i].name = this.videos[i].name
          }

          this.videos[i].file =
            environment.filesUrl +
            this.videos[i].file
          this.videos[i].picture =
            environment.filesUrl +
            this.videos[i].picture
          this.videos[i]['Time'] = this.commonServices.convertTime(this.videos[i].duration, undefined)
        }
      },
      (err) => {
        this.loading = false
      }
    )
  }

  showVideo(video: any) {
    this.video = video
    this.router.navigate(['/video/' + video.id])
  }

  showReservations() {
    this.router.navigateByUrl('/reservation')
  }
}
