import { Injectable, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import Swal from 'sweetalert2'
import { TokenStorageService } from '../../services/token-storage.service'

@Injectable({
  providedIn: 'root'

})
export class CommonServices {
  public err_message: any
  public translate: TranslatePipe = null

  public languageChanged$: EventEmitter<string>
  private language: string = null

  constructor(
    private router: Router,
    private translateApp: TranslateService,
    public tokenStorage: TokenStorageService
  ) {
    this.languageChanged$ = new EventEmitter()

  }

  public getLanguage(): string {
    return this.language
  }

  public changeLanguage(language: string): void {
    this.language = language
    this.languageChanged$.emit(language)
  }

  public checkIsMember() {
    let currentURL = window.location.href;
    return currentURL.includes('member');
  }

  showMessage(severity: any, detail: any) {
    return [
      {
        severity: severity,
        detail: detail,
      },
    ]
  }

  showAnimatedModal(title: any, text: any) {
    Swal.fire({
      title: '',
      text: text,
      confirmButtonColor: '#0082c3',
      width: '30em',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
    })
  }

  handleResponse(response_code: any): any {
    {
      console.log('response_code', response_code.status)

      if (response_code.status === 500) {
        return this.translateApp.instant('error.CHECKSERVERUR')
      }
      else if (response_code.status === 404) {
        return this.translateApp.instant('error.CHECKSERVERUR')
      }
      else if (response_code.status === 403) {
        let userRole = this.tokenStorage.getUser().roles[1];
        console.log('this.userRole', userRole)
        if (userRole === 'ROLE_MEMBER') {
          this.router.navigate(['/home'])
          localStorage.clear();
       
          window.location.reload()
        } else if (userRole === 'ROLE_COACH') {
          this.router.navigate(['/auth/coach/login'])
          localStorage.clear();
     
          window.location.reload()
        }

        this.router.navigateByUrl('/auth/member/login')

      }
      else if ( (response_code.status === 401)) {
        let userRole = this.tokenStorage.getUser().roles[1];
        window.location.href = 'https://payment.eklectic.tn/API/oauth/user/authorize?client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&response_type=code&scope=FITNESSBYTT&redirect_uri=https://www.clubfitness.tn/#/home';

        localStorage.clear();
       
        return this.translateApp.instant('ACCESS_UNAUTHORIZED_ERROR')

      } else if (response_code.status === 400 ) {
        return this.translateApp.instant('error.CHECKDATA')

      }
      else if (response_code.status === 409) {
        return this.translateApp.instant('error.NOTCONNECTED')
      }
      else {
        return this.translateApp.instant('error.NOTCONNECTED')

      }
    }
  }

  handleError(error: any) {
    {

      let message = ''
      if (error.status === 401) {
        message = this.translate.transform('ACCESS_UNAUTHORIZED_ERROR')
        this.router.navigateByUrl('/auth/member/login')
      } else if (error.status === 500) {
        message = this.translate.transform('SERVER_ERROR')
      } else if (error.status === 404) {
        message = this.translate.transform('CHECK_SERVER')
      } else if (error.status === 403) {
        let userRole = this.tokenStorage.getUser().roles[1] || 'ROLE_MEMBER';
        console.log('this.userRole', userRole)
        if (userRole === 'ROLE_MEMBER') {
          window.location.href = 'https://payment.eklectic.tn/API/oauth/user/authorize?client_id=0a2e5ee1-88f6-11ec-9feb-fa163e3dd8b3&response_type=code&scope=FITNESSBYTT&redirect_uri=https://www.clubfitness.tn/#/home';

          localStorage.clear();
          // window.location.reload()
        } else if (userRole === 'ROLE_COACH') {
          this.router.navigate(['/auth/coach/login'])

          localStorage.clear();
          window.location.reload()
        }

      } else if (error.status === 400) {
        message = this.translate.transform('CHECK_DATA')
      } else if (error.status === 409) {
        message = this.translate.transform('CHECK_MAIL')
      } else {
        message = this.translate.transform('CHECK_INTERNET_HISTORY')
        if (typeof error.code !== 'undefined') {
          message = +' (' + error.code + ')'
        }
      }
    }
  }

  convertTime(input: any, separator: any) {
    const pad = function (input: any) { return input < 10 ? '0' + input : input }
    return [
      pad(Math.floor(input / 3600)),
      pad(Math.floor(input % 3600 / 60)),
      pad(Math.floor(input % 60)),
    ].join(typeof separator !== 'undefined' ? separator : ':')
  }

}
