import { Component, OnInit } from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'app-reset-password-view',
  templateUrl: './reset-password-view.component.html',
  styleUrls: ['./reset-password-view.component.scss']
})
export class ResetPasswordViewComponent implements OnInit {
  public isResetFailed = false
  public phone: any

  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {


  }
  reset(): void {
    this.isResetFailed = true
  }

}
