<div class="contact-form-container">
  <h1>{{ 'footer.CONTACT' | translate }}</h1>
  <form>
    <div class="p-field p-col-12">
      <label>{{'login.PHONE' | translate }}:</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <span class="p-float-label">
          <input type="text" id="username" pInputText [required]="true" placeholder="{{ 'USERNAME' | translate }}"
            autocomplete="username" required>
        </span>
      </div>
    </div>
    <div class="p-field p-col-12">
      <label>{{'EMAILADRESS' | translate }}:</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <span class="p-float-label">
          <input type="text" id="username" pInputText [required]="true" placeholder="{{ 'EMAILADRESS' | translate }}"
            autocomplete="username" required>
        </span>
      </div>
    </div>
    <div class="p-field p-col-12">
      <label>{{'MESSAGE' | translate }}:</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-comment"></i>
        </span>
        <span class="p-float-label">
          <input type="text" id="username" pInputText [required]="true" placeholder="{{ 'MESSAGE' | translate }}"
            autocomplete="MESSAGE" required>
        </span>
      </div>
    </div>
    <div class="p-grid p-nogutter p-justify-center">
      <p-button type="submit" styleClass="p-button-lg" label="{{'SUBMIT' | translate }}" icon="pi pi-angle-right"
        iconPos="right"></p-button>
    </div>
  </form>
</div>