<div class="text-align: center">
  <span class="_priceSection position-relative">
    <button class="favourite-button" (click)="toggleSelectedLike()">
      <i *ngIf="!selectedLike" class="far fa-thumbs-up fa-2x reaction"></i>
      <i *ngIf="selectedLike" class="fa fa-thumbs-up fa-2x reaction"></i>
    </button>
  </span>
  <span class="_priceSection position-relative">
    <button class="favourite-button" (click)="toggleSelectedUnlike()">
      <i *ngIf="!selectedDisLike" class="far fa-thumbs-down fa-2x reaction"></i>
      <i *ngIf="selectedDisLike" class="fa fa-thumbs-down fa-2x reaction"></i>
    </button>
  </span>
  <span class="_priceSection position-relative">
    <button class="favourite-button" (click)="toggleSelectedComment()">
      <i class="far fa-comments fa-2x reaction"></i>
    </button>
  </span>
</div>
