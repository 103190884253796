import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { RatingsResult } from '../../interfaces/rating.interface'

@Injectable({
  providedIn: 'root'
})
export class RatesService {

  private uri = `${environment.apiUrl}`
  private controller_rates = '/public-ratable/get-ratings/'
  private controller_rates_entity = '/public-ratable/get-entity-ratings'
  private controller_rate = '/public-ratable/get-rating/'

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
    }),
  }

  getRatebyentity(entity: string, foreignKey: number) {
    return this.http.get(this.uri + this.controller_rates_entity + '/' + entity + '/' + foreignKey, this.httpOptions)
  }
  getRatesbyentity(entity: string) {
    return this.http.get(this.uri + this.controller_rates + entity, this.httpOptions)
  }
  getRatesByEntity(entity: string, foreignKey: number) {
    return this.http.get(this.uri + this.controller_rates_entity + '/' + entity, this.httpOptions)
  }
  getRatesByEntityId(entity: any, foreignKey: number) {
    return this.http.get(this.uri + this.controller_rate + entity + '/' + foreignKey, this.httpOptions)
  }

  setRatingByEntityId(id: any, entity: any, value: any, data: any): Observable<RatingsResult | any> {
    return this.http.post(this.uri + '/public-ratable/set-rating/' + entity + '/' + id + '/' + value, data, this.httpOptions)
  }
  unsetRatingByEntityId(id: any, entity: any): Observable<RatingsResult | any> {
    return this.http.post(this.uri + '/public-ratable/unset-rating/' + entity + '/' + id, this.httpOptions)
  }

  getRatesByEntityforeignKey(entity: any, foreignKey: number) {
    return this.http.get(this.uri + this.controller_rates_entity + '/' + entity + '/' + foreignKey, this.httpOptions)
  }

}
