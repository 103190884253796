import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../src/environments/environment'
import { Video, VideosResult } from '../../interfaces/video.interface'


@Injectable({
    providedIn: 'root'
})
export class VideosService {

    private uri = `${environment.apiUrl}`
    private controller = `/public-video/get-video`
    public controller_serach = '/public-video/search'

    constructor(private http: HttpClient) { }
    httpOptions = {
        headers: new HttpHeaders({
            Authorization: 'Bearer ' + localStorage.getItem('auth-token'),
        }),
    }

    getAll(): Observable<any> {
        return this.http.get(this.uri + this.controller, this.httpOptions)
    }

    getVideoById(id: number) {
        return this.http.get(this.uri + this.controller + '/' + id, this.httpOptions)
    }

    getCoachVideo(data: any) {
        return this.http.post(this.uri + this.controller_serach, data, this.httpOptions)
    }

    getVideo(id: number) {
        return this.http.get(this.uri + '/get-video' + `/${id}`)
    }

}
