<div class="main-container">

  <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
  <div class="profile">
    <div class="profile-avatar">
      <div class="link-style">
        <div>
          <div *ngIf="images !== null">
            <img [src]="images" class="image-style">
          </div>
          <div class="image-upload">
            <label for="file-input">
              <i class="pi pi-camera" id="edit-picture"></i>
            </label>
            <input type="file" id="file-input" #picture accept="image/*"
              (change)="handleFileInput($event.target.files)">
          </div>
        </div>
      </div>
      <div class="profile-name"><br>{{userClaims.username}}</div>
    </div>
    <img src="../../../../assets/images//e-fit-default-image/account-cover.jpg" alt="" class="profile-cover">
  </div>
  <div class="row pt-5">
    


    <div class="container mt-5">
      <div class="row">

        <!-- Nav tabs -->
        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs justify-content-center" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#home" role="tab">
                  Mes Informations
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
                  Mes cours
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#sports" role="tab">
                  Mes Sports
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#messages" role="tab">
                   Mes Objectifs
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#settings" role="tab">
                   BMI Calculator
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab"  >
                  <a (click)="logout()"><i class="fas fa-sign-out-alt" style="
                    font-size: x-large;"></i>Déconnexion</a>
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <!-- Tab panes -->
            <div class="tab-content text-center">
              <div class="tab-pane active" id="home" role="tabpanel">
                <div class="timeline-right">
                  <div class="status box">
                    <form #fitnessForm="ngForm" (submit)="onUpdatePerFitnessClub(fitnessForm)" autocomplete="off">
                      <!-- <div class="intro-title">
                      <br>
                   {{"profile.MESINFORMATIONS" | translate}}
                    </div> -->
                      <!-- <p> {{"profile.ACCOUNTDISC" | translate}} <a
                        href="">
                        {{"profile.SAVOIRPLUS" | translate}}</a></p> -->
                      <div *ngIf="accountUpdated">
                        <p-messages [(value)]="message" [enableService]="false"></p-messages>
                      </div>
                      <p-panel header="{{'profile.UPDATEFITNESSCLUB' | translate}}" styleClass="preferencesPanel"
                        [toggleable]="true">
                        <div class="p-fluid p-formgrid p-grid">

                          <div class="p-field p-col-12 p-md-6" *ngIf="userRole === 'ROLE_MEMBER' && profile != null">
                            <div class="form-group">
                              <label> {{"profile.POIDS" | translate}} :</label>
                              <span class="p-float-label p-input-icon-left p-input-icon-right">
                                <i class="fas fa-weight"></i>
                                <i>Kg</i>
                                <input id="weight" type="number" required pInputText class="form-control" name="weight"
                                  [(ngModel)]="profile.weight" placeholder="{{'profile.POIDS' | translate}}">
                              </span>
                            </div>
                          </div>
                          <div class="p-field p-col-12 p-md-6" *ngIf="userRole === 'ROLE_MEMBER' && profile != null">
                            <div class="form-group">
                              <label>{{"profile.HAUTEUR" | translate}} :</label>
                              <span class="p-float-label p-input-icon-left p-input-icon-right">
                                <i class="fas fa-arrows-alt-v"></i>
                                <i>Cm</i>
                                <input id="height" type="number" required pInputText class="form-control" name="height"
                                  [(ngModel)]="profile.height" placeholder="{{'profile.HAUTEUR' | translate}}">
                              </span>
                            </div>
                          </div>
                          <div class="p-field p-col-12 p-md-6">
                            <label> {{"profile.SEXE" | translate}} :</label>
                            <div class="form-group">
                              <div class="p-field-radiobutton">
                                <div *ngFor="let gender of genders" class="p-field-checkbox">
                                  <p-radioButton [inputId]="gender" name="gender" [value]="gender"
                                    [(ngModel)]="userClaims.gender">
                                  </p-radioButton>
                                  <label [for]="gender" class="label">{{gender}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="p-field p-col-12 p-md-6">
                            <label> {{"profile.BIRTH" | translate}} :</label>
                            <div class="form-group">
                              <p-calendar id="birthDate" [showIcon]="true" name="birthDate"
                                [(ngModel)]="userClaims.birthDate" inputId="icon"
                                placeholder="{{'profile.BIRTH' | translate}}"></p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="p-field p-col-12" *ngIf="profile != null">
                          <div class="form-group">
                            <label>{{"profile.DESCRIPTION" | translate}} :</label>
                            <textarea id="description" autoResize="autoResize" class="form-control" pInputTextarea
                              name="description" [(ngModel)]="profile.description"
                              placeholder="{{'profile.DESCRIPTION' | translate}}" required></textarea>
                          </div>
                        </div>
                      </p-panel>
                      <p-panel header="{{'profile.UPDATEINFOPERS' | translate}} " [toggleable]="true" toggler="header"
                        [collapsed]="true">
                        <br>
                        <div class="stepsdemo-content">
                          <p-card>
                            <div class="p-fluid p-formgrid p-grid">
                              <div class="p-field p-col-12 p-md-6">
                                <div class="form-group">
                                  <label> {{"profile.NOM" | translate}} :</label>
                                  <span class="p-float-label p-input-icon-left">
                                    <i class="fa fa-user"></i>
                                    <input id="firstName" type="text" required pInputText class="form-control"
                                      name="firstName" [(ngModel)]="userClaims.firstName"
                                      placeholder="{{'profile.NOM' | translate}}">
                                  </span>
                                </div>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <div class="form-group">
                                  <label> {{"profile.PRENOM" | translate}} :</label>
                                  <span class="p-float-label p-input-icon-left">
                                    <i class="fa fa-user"></i>
                                    <input id="lastName" type="text" required pInputText class="form-control"
                                      name="lastName" [(ngModel)]="userClaims.lastName"
                                      placeholder="{{'profile.PRENOM' | translate}}">
                                  </span>
                                </div>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <div class="form-group">
                                  <label> {{"profile.EMAIL" | translate}} :</label>
                                  <span class="p-float-label p-input-icon-left">
                                    <i class="fa fa-envelope"></i>
                                    <input id="email" type="text" required pInputText required class="form-control"
                                      name="email" [(ngModel)]="userClaims.email"
                                      placeholder="{{'profile.EMAIL' | translate}}">
                                  </span>
                                </div>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <div class="form-group">
                                  <label>{{"profile.PHONE" | translate}} :</label>
                                  <span class="p-float-label p-input-icon-left">
                                    <i class="fa fa-phone"></i>
                                    <input id="phone" type="text" required pInputText class="form-control" name="phone"
                                      [(ngModel)]="userClaims.phone" placeholder="{{'profile.PHONE' | translate}}">
                                  </span>
                                </div>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.PAYS" | translate}} :</label>
                                <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" name="country"
                                  optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                                  placeholder="{{'profile.PAYS' | translate}} " dropDownIcon="pi pi-user">
                                  <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                      <div><img src="{{appUrl+country.picture}}" class="country-select">{{country.name}}
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-dropdown>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.VILLE" | translate}} :</label>
                                <p-dropdown [options]="cities" [(ngModel)]="selectedCity" name="city" optionLabel="name"
                                  optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                                  placeholder="{{'profile.VILLE' | translate}}">
                                  <ng-template let-country pTemplate="item">
                                    <div class="country-item">
                                      <div>{{country.name}}</div>
                                    </div>
                                  </ng-template>
                                </p-dropdown>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.ADDRESS" | translate}} :</label>
                                <span class="p-float-label p-input-icon-left">
                                  <i class="fas fa-map-marker-alt"></i>
                                  <input id="address" type="text" pInputText class="form-control" name="address"
                                    [(ngModel)]="userClaims.address" placeholder="{{'profile.ADDRESS' | translate}}">
                                </span>
                              </div>
                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.CODEPOSTAL" | translate}} :</label>
                                <span class="p-float-label p-input-icon-left">
                                  <i class="fa fa-map-pin"></i>
                                  <input id="zipCode" type="text" pInputText class="form-control" name="zipCode"
                                    [(ngModel)]="userClaims.zipCode"
                                    placeholder="{{'profile.CODEPOSTAL' | translate}} ">
                                </span>
                              </div>

                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.JOB" | translate}} :</label>
                                <span class="p-float-label p-input-icon-left">
                                  <i class="fa fa-briefcase"></i>
                                  <input id="job" type="text" pInputText class="form-control" name="job"
                                    [(ngModel)]="userClaims.job" placeholder="{{'profile.JOB' | translate}}">
                                </span>
                              </div>

                              <div class="p-field p-col-12 p-md-6">
                                <label> {{"profile.LANGUAGE" | translate}} :</label>
                                <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" name="language"
                                  optionLabel="name" optionValue="id" [showClear]="true"
                                  placeholder="{{'profile.LANGUAGE' | translate}}">
                                  <ng-template let-language pTemplate="item">
                                    <div class="country-item">
                                      <div>{{language.name}}</div>
                                    </div>
                                  </ng-template>
                                </p-dropdown>
                              </div>

                              <div class="p-field p-col-12 p-md-6">
                                <div class="form-group">
                                  <input id="picture" type="hidden" required pInputText class="form-control"
                                    name="picture" [(ngModel)]="userClaims.picture" placeholder="Numéro Téléphone">
                                </div>
                              </div>
                            </div>

                          </p-card>
                        </div>
                      </p-panel>

                      <div class="p-grid p-nogutter p-justify-end">
                        <p-button type="submit" label="Sauvgarder" styleClass="p-button-lg"></p-button>
                      </div>
                      <br>
                    </form>

                    <p-toast></p-toast>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="profile" role="tabpanel">
                <div class="sport-margin">
                  <div class="intro-title">
                    <br>
                    {{"profile.COURS.COURS" | translate}}
                  </div>
                  <p> {{"profile.COURS.COURSDESC" | translate}}</p>
                  <p-divider></p-divider>
                  <app-filter-view [showFav]=true [data]="sendData(fitnessData)"
                    style="    background-color: white;"></app-filter-view>
                </div>
              </div>

              <div class="tab-pane" id="sports" role="tabpanel">
                <div class="sport-margin">
                  <div class="intro-title">
                    <br>
                    {{"profile.SPORT.SPORT" | translate}}
                  </div>
                  <p>{{"profile.SPORT.SPORTDESC" | translate}} </p>
                  <p-divider></p-divider>
                </div>
                <app-sport-list></app-sport-list>
              </div>

              <div class="tab-pane" id="messages" role="tabpanel">
                <div class="sport-margin">
                  <div class="intro-title">
                    <br>
                    {{"profile.OBJECTIF.OBJECTIF" | translate}}
                  </div>
                  <p> {{"profile.OBJECTIF.OBJECTIFDESC" | translate}} </p>
                  <p-divider></p-divider>
                </div>
                <app-fitness-goals-view></app-fitness-goals-view>
              </div>

              <div class="tab-pane" id="settings" role="tabpanel">
                <div class="sport-margin">
                  <div class="conteiner">
                    <h1 style="color: white;padding: 2rem;">Calcul de l'Indice de Masse Corporelle (IMC)</h1>
                    <div class="gender">
                      <div class="man" tabindex="0">
  
                        Homme
                      </div>
                      <div class="woman" tabindex="0">Femme</div>
                    </div>
  
                    <form action="" class="form">
                      <label for="height" style="color: white;">Hauteur (cm)</label>
                      <input class="inputBmi" type="number" id="height" value=0 name="height"
                        [(ngModel)]="userBmi.height">
                      <br>
                      <label for="weight" style="color: white;">Poids (kg)</label>
                      <input class="inputBmi" type="number" id="weight" value=0 name="weight"
                        [(ngModel)]="userBmi.weight">
                    </form>
                    <button id="button" (click)="calculateBMI()">Calculer</button>
  
  
                    <div class="c-bmi__result">Votre IMC est : <output name="r"> {{result}} </output></div>
                    <div class="c-bmi__groups" readonly style="    padding: 2rem;">
                      <input style=" display: none;" type="radio" id="bmi-g0" name="g" [(ngModel)]="meaning"
                        value="Insuffisance pondérale" />
  
                      <label for="bmi-g0">Insuffisance pondérale</label>
  
                      <div class="c-bmi__group-text">L'OMS considère un IMC inférieur à 18,5 comme une insuffisance
                        pondérale, ce qui peut indiquer une malnutrition, un trouble alimentaire ou d'autres problèmes
                        de santé.</div>
  
                      <input type="radio" id="bmi-g1" name="g" [(ngModel)]="meaning" value="Normal" />
                      <label for="bmi-g1">Normal</label>
                      <div class="c-bmi__group-text">Un IMC entre 18,5 et 25 est considéré comme normal et sain.</div>
  
                      <input type="radio" id="bmi-g2" name="g" [(ngModel)]="meaning" value="Surpoids" />
                      <label for="bmi-g2">Surpoids</label>
                      <div class="c-bmi__group-text">Les personnes qui entrent dans cette catégorie peuvent être
                        exposées au risque de développer l'obésité. Auparavant, cela était classé comme 'surpoids'.
                      </div>
  
                      <input type="radio" id="bmi-g3" name="g" [(ngModel)]="meaning" value="Obèse" />
                      <label for="bmi-g3">Obèse I</label>
                      <div class="c-bmi__group-text">Les personnes ayant un IMC égal ou supérieur à 30 peuvent présenter
                        de l'obésité, définie comme une accumulation anormale ou excessive de graisse pouvant nuire à la
                        santé.</div>
  
                      <input type="radio" id="bmi-g4" name="g" [(ngModel)]="meaning" value="Obèse1" />
                      <label for="bmi-g4">Obèse II</label>
                      <div class="c-bmi__group-text">Les personnes ayant un IMC égal ou supérieur à 30 peuvent présenter
                        de l'obésité, définie comme une accumulation anormale ou excessive de graisse pouvant nuire à la
                        santé.</div>
  
                      <input type="radio" id="bmi-g5" name="g" [(ngModel)]="meaning" value="Obèse2" />
                      <label for="bmi-g5">Obèse III</label>
                      <div class="c-bmi__group-text"> Les personnes ayant un IMC égal ou supérieur à 30 peuvent
                        présenter de l'obésité, définie comme une accumulation anormale ou excessive de graisse pouvant
                        nuire à la santé.</div>
                    </div>
  
                  </div>
  
  
                </div>
  
              </div>
            </div>
            
          </div>
        </div>
      </div>


    </div>
   
  </div>